<template>
  <div class="cm-border-b cm-pb-4 cm-mb-4">
    <v-tabs v-model="tab">
      <v-tab value="copy" data-cy="cypress-tab-product-copy"
        >Product Copy Tool</v-tab
      >
      <v-tab value="removal" data-cy="cypress-tab-product-removal"
        >Product Removal Tool</v-tab
      >
    </v-tabs>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { useCopyProductsToMenuGroupsStore } from "@/stores/CopyProductsToMenuGroups/navigation.ts";
export default {
  setup() {
    const tab = ref("copy-products");
    const copyProductsToMenuGroupsStore = useCopyProductsToMenuGroupsStore();

    watch(tab, () => {
      copyProductsToMenuGroupsStore.view = tab.value;
    });

    return {
      tab,
    };
  },
};
</script>
