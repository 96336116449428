<template>
  <div class="pa-3">
    <v-row v-if="details">
      <v-col cols="12" md="6">
        <div class="cm-text-xl font-weight-bold text-text-darker mb-3">
          Store Info
        </div>
        <v-card class="pa-3" elevation="3" :loading="loading">
          <v-list>
            <edit-text-field
              v-if="venueType"
              is-list-type
              class="text-capitalize"
              :list-items="venueTypes"
              text-field-name="Venue Type"
              :edit-model="venueType"
              :rules="rules.venueTypes"
              @edit-model-value="venueType = $event"
              @update-model-value="
                basicStoreInfo.updateBusinessBasicsInfo(id, item)
              "
            >
            </edit-text-field>
            <edit-text-field
              v-if="businessName"
              text-field-name="Business Name"
              :edit-model="businessName"
              :rules="rules.businessName"
              @edit-model-value="businessName = $event"
              @update-model-value="
                basicStoreInfo.updateBusinessBasicsInfo(id, item)
              "
            >
            </edit-text-field>
            <edit-text-field
              v-if="businessPhone"
              text-field-name="Business Phone"
              :edit-model="businessPhone"
              :rules="rules.businessPhone"
              @edit-model-value="businessPhone = $event"
              @update-model-value="
                basicStoreInfo.updateBusinessBasicsInfo(id, item)
              "
            >
            </edit-text-field>
            <edit-text-field
              v-if="address1TrimmedValue"
              text-field-name="Address Line 1"
              :edit-model="address1TrimmedValue"
              :rules="rules.addressLine1"
              @edit-model-value="address1TrimmedValue = $event"
              @update-model-value="
                basicStoreInfo.updateBusinessBasicsInfo(id, item)
              "
            >
            </edit-text-field>
            <edit-text-field
              v-if="address2"
              text-field-name="Address Line 2"
              :rules="rules.addressLine2"
              :edit-model="address2"
              @edit-model-value="address2 = $event"
              @update-model-value="
                basicStoreInfo.updateBusinessBasicsInfo(id, item)
              "
            >
            </edit-text-field>
            <edit-text-field
              v-if="postcode"
              text-field-name="Postcode"
              :edit-model="postcode"
              :rules="rules.postcode"
              @edit-model-value="postcode = $event"
            >
            </edit-text-field>
            <edit-text-field
              v-if="county"
              text-field-name="County"
              :rules="rules.county"
              :edit-model="county"
              @edit-model-value="county = $event"
              @update-model-value="
                basicStoreInfo.updateBusinessBasicsInfo(id, item)
              "
            >
            </edit-text-field>
            <edit-text-field
              v-if="city"
              text-field-name="Town / City"
              :edit-model="city"
              :rules="rules.city"
              @edit-model-value="city = $event"
              @update-model-value="
                basicStoreInfo.updateBusinessBasicsInfo(id, item)
              "
            >
            </edit-text-field>
            <edit-text-field
              v-if="country"
              is-list-type
              :list-items="businessCountries"
              text-field-name="Country"
              :edit-model="country"
              :rules="rules.country"
              @edit-model-value="country = $event"
              @update-model-value="
                basicStoreInfo.updateBusinessBasicsInfo(id, item)
              "
            >
            </edit-text-field>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-divider v-if="details" class="my-5"></v-divider>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useBasicStoreInfo } from "@/stores/RetailersDashboard/basicStoreInfo.ts";
import RetailersDashboardEditTextField from "@/components/retailersDashboard/forms/RetailersDashboardEditTextField.vue";

import {
  RULE_SET_NAMES,
  RULE_SET_ADDRESS_LINE,
  RULE_SET_POSTCODE,
  RULE_SET_TOWN,
  RULE_SET_COUNTRY,
  RULE_MIN_COUNT_VALUE,
} from "@/constants/rules.js";
import { isValidPhoneNumber } from "libphonenumber-js";
export default {
  components: {
    EditTextField: RetailersDashboardEditTextField,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: String || Number,
      required: true,
    },
  },
  setup(props) {
    const basicStoreInfo = useBasicStoreInfo();
    const venueTypes = ref(["Restaurant", "Bar", "Takeaway"]);
    const details = ref({});
    const loading = ref(true);
    const businessCountries = ref([
      "United Kingdom",
      "Armenia",
      "Australia",
      "Australia",
      "Barbados",
      "Brasil",
      "Canada",
      "Cayman Islands",
      "Colombia",
      "Costa Rica",
      "Cyprus",
      "France",
      "Hungary",
      "Iceland",
      "India",
      "Israel",
      "New Zealand",
      "Norway",
      "Republic of Ireland",
      "Republic of Panama",
      "South Africa",
      "South Korea",
      "Spain",
      "St Lucia",
      "Sweden",
      "Switzerland",
      "Thailand",
      "Trinidad and Tobago",
      "Turkey",
      "United States",
    ]);

    const businessPhone = computed({
      get() {
        return details?.value?.contact?.phone
          ? details.value.contact.phone.replace(/\s{2,}/g, " ")
          : "";
      },
      set(value) {
        details.value.contact.phone = value;
      },
    });

    const businessName = computed({
      get() {
        return details?.value?.name
          ? details.value.name.replace(/\s{2,}/g, " ")
          : "";
      },
      set(value) {
        details.value.name = value;
      },
    });

    const venueType = computed({
      get() {
        return details?.value?.venueType;
      },
      set(value) {
        details.value.venueType = value;
      },
    });

    const address1TrimmedValue = computed({
      get() {
        return details?.value?.address?.address1
          ? details.value.address.address1.replace(/\s{2,}/g, " ")
          : "";
      },
      set(value) {
        details.value.address.address1 = value;
      },
    });

    const address2 = computed({
      get() {
        return details?.value?.address?.address2
          ? details.value.address.address2.replace(/\s{2,}/g, " ")
          : "";
      },
      set(value) {
        details.value.address.address2 = value;
      },
    });

    const county = computed({
      get() {
        return details?.value?.address?.county
          ? details.value.address.county.replace(/\s{2,}/g, " ")
          : "";
      },
      set(value) {
        details.value.address.county = value;
      },
    });

    const city = computed({
      get() {
        return details?.value?.address?.city
          ? details.value.address.city.replace(/\s{2,}/g, " ")
          : "";
      },
      set(value) {
        details.value.address.city = value;
      },
    });

    const country = computed({
      get() {
        return details?.value?.address?.country
          ? details.value.address.country.replace(/\s{2,}/g, " ")
          : "";
      },
      set(value) {
        details.value.address.country = value;
      },
    });

    const postcode = computed({
      get() {
        return details?.value?.address?.postcode
          ? details.value.address.postcode.replace(/\s{2,}/g, " ")
          : "";
      },
      set(value) {
        details.value.address.postcode = value;
      },
    });

    const isValidDomain = (domain) => {
      const domainPattern =
        /^(?=.{1,253}\.)(?=.{4,253}$)(?!.*\.\..*)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/;
      return domainPattern.test(domain);
    };

    const rules = ref({
      venueTypes: [(v) => !!v || "Venue type is required"],
      businessName: RULE_SET_NAMES,
      businessPhone: [(v) => validatePhone(v) || "Invalid phone number"],
      addressLine1: RULE_SET_ADDRESS_LINE,
      addressLine2: [],
      postcode: RULE_SET_POSTCODE,
      county: [],
      city: RULE_SET_TOWN,
      country: RULE_SET_COUNTRY,
      tableCount: RULE_MIN_COUNT_VALUE,
      domainName: [
        (v) => !!v || "Domain name is required",
        (v) => isValidDomain(v) || "Invalid domain name",
      ],
    });

    const validatePhone = (businessPhone) => {
      const isValid = isValidPhoneNumber(businessPhone);
      return isValid;
    };

    onMounted(async () => {
      setTimeout(async () => {
        details.value = await props?.item?.details;
        loading.value = false;
      }, 600);
    });
    return {
      details,
      rules,
      businessPhone,
      businessName,
      venueTypes,
      venueType,
      address1TrimmedValue,
      address2,
      county,
      city,
      postcode,
      basicStoreInfo,
      country,
      businessCountries,
      loading,
    };
  },
};
</script>
