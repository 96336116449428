<template>
  <autocomplete-input
    v-model="model"
    v-model:search="search"
    :loading="loading"
    density="compact"
    hide-no-data
    clearable
    :items="items"
    @update:modelValue="itemChange"
    return-object
    v-bind="$attrs"
  >
  </autocomplete-input>
</template>
<script>
import { ref, watch, nextTick } from "vue";
import debounce from "lodash.debounce";
import { useToast } from "vue-toastification";
import AutocompleteInput from "@/components/ui/AutocompleteInput.vue";
import api from "@/utils/api.js";
export default {
  name: "ProductAutocompleteInput",
  components: {
    AutocompleteInput,
  },
  props: {
    menuGroupId: {
      type: Number,
    },
  },
  setup(props, { attrs, emit }) {
    const items = ref([]);
    const loading = ref(false);
    const search = ref(null);
    const model = ref(null);
    const toast = useToast();

    watch(search, (val) => {
      val && val !== attrs.modelValue && searchProducts(val);
    });

    const searchProducts = debounce(async (term) => {
      try {
        loading.value = true;
        const { products } = await api.couponManager.products.search({
          term,
          menuGroupId: props.menuGroupId,
        });
        items.value = products;
        loading.value = false;
      } catch (e) {
        loading.value = false;
        toast.error(e, {
          timeout: 2000,
        });
      }
    }, 400);

    const itemChange = (product) => {
      emit("onProductAdded", product);
      nextTick(() => {
        model.value = null;
        search.value = null;
      });
    };

    return {
      model,
      items,
      loading,
      search,
      itemChange,
    };
  },
};
</script>
