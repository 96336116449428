<template>
  <div class="cm-flex cm-gap-x-4">
    <confirm-dialog
      :title="title"
      :content="content"
      v-slot="scope"
      :onConfirm="onConfirm"
    >
      <v-btn
        :disabled="isDisabled"
        @click="scope.showDialog"
        :color="buttonColor"
        class="cm-text-white"
        v-bind="$attrs"
        data-cy="cypress-move-products-button"
        >{{ buttonLabel }}</v-btn
      >
    </confirm-dialog>
  </div>
</template>
<script>
import { computed } from "vue";
import ConfirmDialog from "@/components/copyProductsToMenuGroups/dialogs/ConfirmDialog.vue";
export default {
  props: {
    onConfirm: {
      type: Function,
    },
    isRemovalMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    ConfirmDialog,
  },
  setup(props) {
    const title = computed(() => {
      if (props.isRemovalMode) {
        return "Delete Selected";
      }
      return "Copy Selected";
    });

    const content = computed(() => {
      if (props.isRemovalMode) {
        return "Are you sure you want to delete the selected products from these menu groups?";
      }
      return "Are you sure you want to copy the selected products to these menu groups?";
    });

    const buttonLabel = computed(() => {
      if (props.isRemovalMode) {
        return "Delete Selected";
      }
      return "Copy Selected";
    });

    const buttonColor = computed(() => {
      return props.isRemovalMode ? "#E92318" : "#14569E";
    });

    return {
      title,
      content,
      buttonLabel,
      buttonColor,
    };
  },
};
</script>
