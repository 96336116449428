<template>
  <div data-cy="cypress-dashboard-view">
    <v-form v-model="formValid" ref="formRef">
      <source-menu-group
        v-model="form.sourceMenuGroup"
        :rules="rules.required"
        data-cy="cypress-source-menu-group-field"
      />
      <selected-products
        :menuGroupId="sourceMenuGroupId"
        v-model="form.products"
        :rules="rules.productRequired"
      />

      <div class="cm-mb-6">
        <div class="cm-text-base cm-font-bold mb-6">
          Your selected menu and category names to be copied
        </div>
        <menu-group-search
          v-if="!isRemovalMode"
          label="Search Destination Menu Group"
          placeholder="Search for another menu group"
          auto-clear-after-select
          :rules="rules.menuGroupRequired"
          @onSelected="handleAddDestinationMenuGroup"
          data-cy="cypress-destination-menu-group-field"
        />
        <div>
          <menu-groups-table
            class="cm-mt-6 cm-border"
            v-model="form.destinationMenuGroups"
            v-model:selected="selectedMenuGroupItems"
            :category-rules="rules.required"
            :isRemovalMode="isRemovalMode"
            data-cy="cypress-menu-groups-table"
          />

          <action-buttons
            class="cm-mt-6"
            :onConfirm="handleMoveProducts"
            :isRemovalMode="isRemovalMode"
            :disabled="!formValid"
          />
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ref, watch, computed } from "vue";
import { useToast } from "vue-toastification";
import SourceMenuGroup from "@/components/copyProductsToMenuGroups/SourceMenuGroup.vue";
import SelectedProducts from "@/components/copyProductsToMenuGroups/SelectedProducts.vue";
import MenuGroupSearch from "@/components/copyProductsToMenuGroups/forms/MenuGroupSearch.vue";
import ActionButtons from "@/components/copyProductsToMenuGroups/ActionButtons.vue";
import MenuGroupsTable from "@/components/copyProductsToMenuGroups/tables/MenuGroupsTable.vue";
import { RULE_REQUIRED } from "@/constants/rules";
import api from "@/utils/api.js";

export default {
  props: {
    isRemovalMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    SourceMenuGroup,
    SelectedProducts,
    MenuGroupSearch,
    ActionButtons,
    MenuGroupsTable,
  },
  setup(props) {
    const toast = useToast();
    const formValid = ref(false);
    const formRef = ref(null);
    const selectedMenuGroupItems = ref([]);

    const form = ref({
      sourceMenuGroup: null,
      products: [],
      destinationMenuGroups: [],
    });

    const rules = ref({
      required: [RULE_REQUIRED],
      productRequired: [
        () =>
          form.value?.products?.length > 0 || "At least 1 product is required",
      ],
      menuGroupRequired: [
        () =>
          form.value?.destinationMenuGroups?.length > 0 ||
          "At least 1 menu group is required",
      ],
    });

    const hasSelectedMenuGroupItems = computed(
      () => selectedMenuGroupItems.value.length > 0
    );

    const handleAddDestinationMenuGroup = (menuGroup) => {
      form.value.destinationMenuGroups.push({
        ...menuGroup,
        menuGroupCategory: null,
      });
    };

    const moveProductsPayload = computed(() => {
      const { sourceMenuGroup, products, destinationMenuGroups } = form.value;

      return {
        sourceMenuGroupId: sourceMenuGroup.id,
        productIds: products.map((product) => product.id),
        destinationMenuGroups: destinationMenuGroups
          .map((destinationMenuGroup) => {
            const correctItem = selectedMenuGroupItems.value.find(
              (item) =>
                item.id === destinationMenuGroup.id &&
                item.menuGroupCategory?.id ===
                  destinationMenuGroup.menuGroupCategory?.id
            );

            return correctItem
              ? {
                  id: destinationMenuGroup.id,
                  menuGroupCategoryId:
                    destinationMenuGroup.menuGroupCategory?.id || null,
                }
              : null;
          })
          .filter((item) => item !== null),
      };
    });

    const handleMoveProducts = async () => {
      await formRef.value.validate();
      if (formRef.value.isValid) {
        try {
          await api.copyProductsToMenuGroups.moveMenuGroups(
            moveProductsPayload.value
          );
          toast.success("Products have been moved successfully");
          formRef.value.reset();
          form.value = {
            sourceMenuGroup: null,
            products: [],
            destinationMenuGroups: [],
          };
          selectedMenuGroupItems.value = [];
        } catch (error) {
          toast.error(error?.message || "Unable to move products", {
            timeout: 2000,
          });
          formRef.value.reset();
          formValid.value = false;
        }
      }
    };

    const getMenuGroups = async () => {
      try {
        if (
          !props.isRemovalMode ||
          !sourceMenuGroup.value ||
          products.value.length === 0
        ) {
          return;
        }
        const response = await api.copyProductsToMenuGroups.getMenuGroups({
          menuGroupId: sourceMenuGroup.value.id,
          productId: products.value.map((product) => product.id),
        });
        console.log("response", response);
        form.value.destinationMenuGroups = response;
      } catch (e) {
        console.log("e", e);
      }
    };

    const sourceMenuGroup = computed(() => form.value.sourceMenuGroup);
    const products = computed(() => form.value.products);
    const sourceMenuGroupId = computed(() => form.value.sourceMenuGroup?.id);

    watch(sourceMenuGroup, getMenuGroups);
    watch(products, getMenuGroups);

    return {
      form,
      formValid,
      formRef,
      rules,
      selectedMenuGroupItems,
      hasSelectedMenuGroupItems,
      sourceMenuGroupId,
      handleMoveProducts,
      handleAddDestinationMenuGroup,
    };
  },
};
</script>
