<template>
  <div>
    <p
      v-if="!editMode && !modelValue.length"
      class="v-list-item-subtitle font-weight-medium cm-text-base"
    >
      No items selected
    </p>
    <product-autocomplete-input
      v-if="editMode"
      @onProductAdded="handleProductAdded"
      label="Add Product Search"
      placeholder="Search for a product by barcode"
      data-cy="cypress-product-field"
      :rules="$attrs.rules"
      :disabled="$attrs.disabled"
      :menuGroupId="menuGroupId"
    />
    <slot />
    <div class="md:-cm-m-2" :class="!editMode ? 'mt-0' : 'mt-3'">
      <div
        class="md:cm-inline-block md:cm-m-2 cm-mb-4"
        v-for="product in paginatedProducts"
        :key="product.id"
      >
        <closable-chip
          :closable="editMode"
          :text="product.name"
          class="cm-w-full"
          @closeChip="handleProductRemoval(product)"
        />
      </div>
    </div>
    <v-pagination
      v-if="showPagination"
      v-model="page"
      class="mt-4"
      :length="totalPages"
      @update:model-value="handlePaginationChange"
    ></v-pagination>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import ProductAutocompleteInput from "@/components/copyProductsToMenuGroups/forms/ProductAutocompleteInput.vue";
import ClosableChip from "@/components/ui/ClosableChip.vue";
export default {
  name: "CouponExcludedItems",
  components: {
    ProductAutocompleteInput,
    ClosableChip,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    menuGroupId: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const itemsPerPage = 8;
    const page = ref(1);

    const totalPages = computed(() => {
      return Math.ceil(props.modelValue.length / itemsPerPage);
    });

    const paginatedProducts = computed(() => {
      const startIndex = (page.value - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return props.modelValue.slice(startIndex, endIndex);
    });

    const handleProductAdded = (product) => {
      emit("update:modelValue", [...props.modelValue, product]);
    };

    const handleProductRemoval = (product) => {
      emit(
        "update:modelValue",
        props.modelValue.filter((item) => item.id !== product.id)
      );
    };

    const handlePaginationChange = (newPage) => {
      page.value = newPage;
    };

    const showPagination = computed(
      () => props.modelValue.length > itemsPerPage
    );

    return {
      handlePaginationChange,
      handleProductAdded,
      handleProductRemoval,
      paginatedProducts,
      totalPages,
      showPagination,
    };
  },
};
</script>
