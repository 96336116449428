<template>
  <fulfilment-conditions />
</template>

<script>
import FulfilmentConditions from "@/components/fulfilmentConditions/Index.vue";
export default {
  components: {
    FulfilmentConditions,
  },
};
</script>
