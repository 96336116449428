<template>
  <simple-text-field
    density="compact"
    type="number"
    label="Purchase Amount Required"
    placeholder="Add Purchase Amount Required"
    tooltipText="Purchase Amount Required is the amount of money that must be spent to earn a reward."
    icon="mdi-information-outline"
    icon-color="#14569E"
    v-bind="$attrs"
  />
</template>
<script>
import SimpleTextField from "@/components/ui/SimpleTextField.vue";
export default {
  name: "LoyaltyRewardsFormPurchaseAmountRequiredField",
  components: {
    SimpleTextField,
  },
};
</script>
