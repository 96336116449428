<template>
  <v-checkbox
    @update:model-value="updateSetting"
    :label="label"
    :model-value="value"
    data-cy="cypress-white-label-manager-checkbox-field"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
    },
    label: {
      type: String,
    },
  },
  setup(_, { emit }) {
    const updateSetting = (value) => {
      emit("onValueUpdate", value);
    };

    return {
      updateSetting,
    };
  },
};
</script>
