<template>
  <retailers-dashboard />
</template>

<script>
import RetailersDashboard from "@/components/retailersDashboard/Index.vue";
export default {
  components: {
    RetailersDashboard,
  },
};
</script>
