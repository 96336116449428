<template>
  <div>
    <simple-card
      color="white"
      :border="true"
      elevation="0"
      class="cm-pa-4 cm-rounded-0 cm-bg-white cm-mb-6 cm-hidden md:cm-block"
    >
      <v-row>
        <v-col cols="6">
          <create-schedule-card />
        </v-col>
        <v-col cols="6">
          <simple-card
            class="cm-pa-2 cm-rounded-lg"
            :border="true"
            elevation="0"
          >
            <template #header>
              <div class="d-flex">
                <div>
                  <simple-card-title
                    class="mb-5"
                    title="Scheduling an update is even easier than ever"
                  />
                  <p>
                    Lets get you up and running with our brand
                    new schedule store update wizard
                  </p>
                </div>
                <v-img
                  :src="clockIcon"
                  width="90px"
                  max-width="90px"
                  class="cm-mt-0 ml-3 d-none d-lg-flex"
                ></v-img>
              </div>
            </template>
          </simple-card>
        </v-col>
      </v-row>
    </simple-card>

    <dashboard-table-card />

    <bottom-navigation class="cm-fixed md:cm-hidden">
      <simple-button
        size="large"
        color="#14569E"
        class="cm-capitalize cm-text-base cm-font-semibold"
        text="Scheduled Update"
        @click="handleCreateSchedule"
        prepend-icon="mdi-plus"
      />
      <!--
      <template #leftButton>
        <simple-button
          size="large"
          color="#14569E"
          class="cm-capitalize cm-text-base cm-font-semibold"
          text="Scheduled Update"
          @click="handleCreateSchedule"
          prepend-icon="mdi-plus"
        />
      </template>
      <template #rightButton>
        <simple-button
          size="large"
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#14569E"
          variant="secondary"
          text="Search"
          prepend-icon="mdi-magnify"
        />
      </template>
      -->
    </bottom-navigation>
    
  </div>
</template>
<script>
import { useScheduleNavigationStore } from "@/stores/ScheduleStoreUpdates/navigation.ts";
import { useScheduleStore } from "@/stores/ScheduleStoreUpdates/schedule.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleCardTitle from "@/components/ui/SimpleCardTitle.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import DashboardTableCard from "@/components/scheduleStoreUpdates/cards/DashboardTableCard.vue";
import CreateScheduleCard from "@/components/scheduleStoreUpdates/cards/CreateScheduleCard.vue";
import clockIcon from "@/assets/clock-icon.svg";
export default {
  name: "DashboardView",
  components: {
    SimpleCard,
    SimpleCardTitle,
    SimpleButton,
    BottomNavigation,
    DashboardTableCard,
    CreateScheduleCard,
  },
  setup() {
    const scheduleNavigationStore = useScheduleNavigationStore();
    const scheduleStore = useScheduleStore();

    const handleCreateSchedule = () => {
      scheduleStore.$reset();
      scheduleNavigationStore.viewScheduleForm();
    };

    return {
      ...scheduleNavigationStore,
      handleCreateSchedule,
      clockIcon,
    };
  },
};
</script>
