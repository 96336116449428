<template>
  <GoogleMap
    ref="mapRef"
    :style="mapStyle"
    :api-key="apiKey"
    :center="mapCenter"
    :zoom="mapZoom"
    disable-default-ui="true"
  >
    <MapMarker
      v-for="(markerPosition, index) in markerPositions"
      :key="index"
      :options="markerPosition"
    />
    <MapPolygon
      v-for="(polygonPosition, index) in polygonPositions"
      :key="index"
      :options="polygonPosition"
    />
    <MapCircle
      v-for="(circlePosition, index) in circlePositions"
      :key="index"
      :options="circlePosition"
    />
  </GoogleMap>
</template>

<script>
import { defineComponent, ref, toRefs, onMounted, computed } from 'vue'
import { GoogleMap, Marker, Polygon, Circle } from "vue3-google-map";

export default defineComponent({
  components: {
    GoogleMap,
    MapMarker: Marker,
    MapPolygon: Polygon,
    MapCircle: Circle,
  },
  props: {
    mapApiKey: {
      type: String,
    },
    zones: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const { zones } = toRefs(props);
    const mapCenter = ref(null);
    const mapZoom = ref(12);
    const mapRef = ref(null);

    const mapOptions = {
      strokeColor: "#e42034",
      strokeOpacity: 0.9,
      strokeWeight: 2,
      fillColor: "#e42034",
      fillOpacity: 0.35,
    };

    const mapStyle = computed(() => {
      return {
        width: "100%",
        height: "400px",
      };
    });

    const mapApiKey = props.mapApiKey;

    const markerPositions = computed(() => {

      const polygonMarkerPositions = zones.value.map(
        (polygon, index) => {
          if (polygon.coordinates.center) return
          
          const lat = polygon.coordinates.map(coord => coord.lat);
          const lng = polygon.coordinates.map(coord => coord.lng);
          const x1 = Math.min(...lat);
          const y1 = Math.min(...lng);
          const x2 = Math.max(...lat);
          const y2 = Math.max(...lng);
          const center = { x: 0, y: 0 };
          center.x = x1 + (x2 - x1) / 2;
          center.y = y1 + (y2 - y1) / 2;

          return {
            label: calculateLetter(index),
            position: {
              lat: center.x,
              lng: center.y,
            },
            draggable: false,
          }
        
        }
      )

      const circleMarkerPositions = zones.value.map(
        (circle, index) => {
          if (!circle.coordinates.center) return

          return {
            label: calculateLetter(index),
            position: {
              lat: circle.coordinates.center.lat,
              lng: circle.coordinates.center.lng,
            },
            draggable: false,
          }
        }
      )

      return [...polygonMarkerPositions, ...circleMarkerPositions]
    })
    
    const polygonPositions = computed(() => {
      const polygonZones = [];
      zones.value.forEach(function(zone, index) {
        if (!zone.coordinates.radius) {
          let pathObj = {};
          pathObj.paths = zone.coordinates;
          pathObj.options = mapOptions;
          polygonZones.push(pathObj);
        }
      })
      return polygonZones;
    })

    const circlePositions = computed(() => {
      const circleZones = [];
      zones.value.forEach(function(zone, index) {
        if (zone.coordinates.radius) {
          zone.coordinates.options = mapOptions;
          circleZones.push(zone.coordinates);
        }
      })
      return circleZones;
    })

    const initializeMap = async () => {
      await new Promise((resolve) => {
        const checkLoaded = () => {
          if (window.google && window.google.maps) {
            resolve();
          } else {
            setTimeout(checkLoaded, 100);
          }
        }
        checkLoaded();
      })

      if (mapRef.value) {
        const bounds = new window.google.maps.LatLngBounds();

        zones.value.forEach(function(zone, index) {
          if (zone.coordinates.radius) {
            bounds.extend(zone.coordinates.center);
          } else if (!zone.coordinates.radius) {
            zone.coordinates.forEach(function(coordinates, index2) {
              bounds.extend(coordinates);
            })
          }
        })

        mapRef.value.map.fitBounds(bounds);
      }
    }

    onMounted(() => {
      initializeMap();
    })

    function calculateLetter(index) {
      index++
      const prepend = Math.floor(index / 26)
      let letter = ''
      if (prepend > 0) {
        letter = prepend
        index = index - (26 * prepend)
      }
      letter = letter + String.fromCharCode(index + 64);
      return letter;
    };

    return {
      apiKey: mapApiKey,
      mapCenter,
      mapZoom,
      mapRef,
      mapStyle,
      markerPositions,
      polygonPositions,
      circlePositions,
    };
  },
});
</script>
