<template>
  <v-form v-model="valid" lazy-validation>
    <v-row class="pa-3">
      <v-col cols="12" sm="3" class="pb-0 pb-sm-3">
        <v-select
          v-model="selectedStatus"
          density="compact"
          label="Status"
          :items="['Live', 'Complete']"
          variant="outlined"
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="9">
        <v-text-field
          v-model="crmLink"
          @keyup.prevent="updateCrmLink(item)"
          :rules="rules.crmLink"
          placeholder="Enter Zoho URL"
          density="compact"
          label="CRM Deal Link"
          variant="outlined"
          :hide-details="valid"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from "vue";
import { useRetailerStore } from "@/stores/RetailersDashboard/retailer.ts";
import { useToast } from "vue-toastification";

import { debounce } from "lodash";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: String || Number,
      required: true,
    },
  },
  setup(props) {
    const valid = ref(true);
    const toast = useToast();
    const selectedStatus = ref(null);
    const crmLink = ref("");
    const retailerStore = useRetailerStore();

    const rules = {
      crmLink: [
        (v) => !!v || "CRM Link is required",
        (v) => {
          const pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
              "(\\:\\d+)?" + // port
              "(\\/[-a-z\\d%_.~+]*)*" + // path
              "(\\?[;&amp;a-z\\d%_.~+=-]*)?" + // query string
              "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // fragment locator
          return pattern.test(v) || "Invalid URL";
        },
      ],
    };

    let isInitialStatusUpdate = true;

    watch(selectedStatus, (newStatus, oldStatus) => {
      if (isInitialStatusUpdate) {
        isInitialStatusUpdate = false;
        return;
      }
      if (newStatus !== oldStatus) {
        updateStatus(props.item);
      }
    });

    const updateStatus = debounce(async () => {
      try {
        await retailerStore.updateStatus(
          props.id,
          selectedStatus.value.toLowerCase()
        );
        toast.success("Status updated");
      } catch (error) {
        toast.error(error.message);
      }
    }, 300);

    let lastToastMessage = "";

    const updateCrmLink = debounce(async (item) => {
      try {
        if (!valid.value) {
          if (lastToastMessage !== "Invalid URL") {
            toast.error("Invalid URL");
            lastToastMessage = "Invalid URL";
          }
          return;
        }

        await retailerStore.update({
          retailer: {
            details: {
              crmLink: crmLink.value,
            },
          },
        });
        if (lastToastMessage !== "CRM Link updated") {
          toast.success("CRM Link updated");
          lastToastMessage = "CRM Link updated";
        }
      } catch (error) {
        if (lastToastMessage !== error.message) {
          toast.error(error.message);
          lastToastMessage = error.message;
        }
      }
    }, 300);

    onMounted(async () => {
      setTimeout(async () => {
        await retailerStore.getStatus(props.id);
        crmLink.value = props?.item?.details?.crmLink;

        selectedStatus.value = retailerStore.status.complete
          ? "Complete"
          : retailerStore.status.live
          ? "Live"
          : "";
      }, 400);
    });

    return {
      selectedStatus,
      crmLink,
      updateCrmLink,
      rules,
      valid,
      updateStatus,
    };
  },
});
</script>
