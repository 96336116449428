<template>
  <v-card
    class="cm-bottom-0 cm-left-0 cm-right-0 cm-p-4 cm-rounded-t-lg"
    elevation="2"
    color="white"
    v-bind="$attrs"
  >
    <slot />
    <v-row>
      <v-col cols="6">
        <slot name="leftButton" />
      </v-col>
      <v-col cols="6">
        <slot name="rightButton" />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "BottomNavigation",
  setup() {
    return {};
  },
};
</script>
