<template>
  <autocomplete-input
    v-model:search="search"
    :loading="loading"
    density="compact"
    label="Store Name"
    placeholder="Search for a store"
    hide-no-data
    :hide-details="hideDetails"
    v-bind="$attrs"
    :items="items"
    clearable
    return-object
    chips
  />
</template>
<script>
import { ref, watch } from "vue";
import debounce from "lodash.debounce";
import { useLoyaltyRewardsStore } from "@/stores/LoyaltyRewards/store.ts";
import AutocompleteInput from "@/components/ui/AutocompleteInput.vue";
import { useToast } from "vue-toastification";
export default {
  name: "SingleStoreInput",
  components: {
    AutocompleteInput,
  },
  props: {
    hideDetails: {
      type: Boolean,
      default: true,
    },
    isSuperAdmin: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { attrs, emit }) {
    const lowayltyRewardsStore = useLoyaltyRewardsStore();
    const items = ref([]);
    const loading = ref(false);
    const search = ref(null);
    const toast = useToast();
    const noStoresToastShown = ref(false);

    watch(search, (val) => {
      val && val !== attrs.modelValue && val.length >= 3 && searchStores(val);
    });

    const searchStores = debounce(async (term) => {
      try {
        loading.value = true;
        items.value = await lowayltyRewardsStore.searchStores({ term });
        if (!items.value.length && !noStoresToastShown.value) {
          noStoresToastShown.value = true;
          toast.error("No stores found!", {
            timeout: 2000,
            onClose: () => {
              noStoresToastShown.value = false;
            },
          });
        }
        loading.value = false;
      } catch (e) {
        loading.value = false;
        noStoresToastShown.value = false;
        toast.error(e, {
          timeout: 2000,
        });
      }
    }, 400);

    return {
      items,
      loading,
      search,
    };
  },
};
</script>
