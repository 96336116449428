<template>
  <v-btn
    class="w-100 rounded-lg"
    :class="variant === 'secondary' ? `text-${color}` : 'text-white'"
    :color="computedColor"
    v-bind="$attrs"
    :variant="computedType"
  >
    {{ text }}
  </v-btn>
</template>
<script>
import { computed } from "vue";
export default {
  name: "SimpleButton",
  props: {
    variant: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const computedType = computed(() => {
      switch (props.variant) {
        case "secondary":
          return "outlined";
        default:
          return "flat";
      }
    });

    const computedColor = computed(() => {
      switch (props.variant) {
        case "secondary":
          return props.color;
        case "success":
          return "#188D6F";
        case "cancel":
          return "#E42034";
        default:
          return props.color;
      }
    });

    return { computedType, computedColor };
  },
};
</script>
