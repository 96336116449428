<template>
  <div class="cm-mb-6">
    <p
      class="font-weight-bold cm-text-sm mt-5"
      :class="`mb-${editMode ? '4' : '0'}`"
    >
      Products Exclusions
    </p>
    <p
      v-if="!editMode && !form.itemRestrictions.specificItemIds.length"
      class="v-list-item-subtitle font-weight-medium cm-text-base"
    >
      No items selected
    </p>
    <product-autocomplete-input
      v-if="editMode"
      @onProductAdded="handleProductAdded"
      class="mb-5"
      label="Products Exclusions"
      placeholder="Please select any products that are excluded from this code..."
      data-cy="cypress-form-step-three-product-exclusions-field"
    />
    <slot />
    <div class="md:-cm-m-2" :class="!editMode ? 'mt-0' : 'mt-3'">
      <div
        class="md:cm-inline-block md:cm-m-2 cm-mb-4"
        v-for="product in form.itemRestrictions.specificItemIds"
        :key="product.id"
      >
        <closable-chip
          :closable="editMode"
          :text="product.name"
          class="cm-w-full"
          @closeChip="handleProductRemoval(product)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import ProductAutocompleteInput from "@/components/couponManager/forms/ProductAutocompleteInput.vue";
import ClosableChip from "@/components/ui/ClosableChip.vue";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
export default {
  name: "CouponExcludedItems",
  components: {
    ProductAutocompleteInput,
    ClosableChip,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const couponStore = useCouponStore();
    const form = ref(couponStore.coupon);

    const handleProductAdded = (product) => {
      if (form.value.itemRestrictions.specificItemIds) {
        form.value.itemRestrictions.specificItemIds.push(product);
      } else {
        form.value.itemRestrictions.specificItemIds = [product];
      }
    };

    const handleProductRemoval = (product) => {
      form.value.itemRestrictions.specificItemIds =
        form.value.itemRestrictions.specificItemIds.filter(
          (item) => item.id !== product.id
        );
    };
    return {
      form,
      handleProductAdded,
      handleProductRemoval,
    };
  },
};
</script>
