import { defineStore } from "pinia";

export const useSearchStore = defineStore("search", {
  state: () => {
    return {
      searchLoading: false,
      query: {
        nameOrCode: null,
        name: null,
        code: null,
        type: null,
        storeId: null,
      },
      orderBy: {
        label: "Date",
        value: "id",
      },
      pagination: {
        orderedBy: "",
        perPage: 0,
        totalCount: 0,
        pageCount: 0,
        page: 0,
      },
    };
  },
  getters: {
    itemsPerPage(state) {
      return state.pagination.perPage;
    },
    currentPage(state) {
      return state.pagination.page;
    },
    totalItems(state) {
      return state.pagination.totalCount;
    },
  },
  actions: {},
});
