import { defineStore } from "pinia";
import api from "@/utils/api.js";
import { useToast } from "vue-toastification";

const toast = useToast();

export const useProductStore = defineStore("product", {
  actions: {
    async searchProducts(payload) {
      try {
        const { products } = await api.loyaltyRewards.products.search(payload);

        return products;
      } catch (e) {
        toast.error(e, {
          timeout: 2000,
        });
      }
    },
  },
});
