<template>
  <coupon-manager />
</template>

<script>
import CouponManager from "@/components/couponManager/Index.vue";
export default {
  components: {
    CouponManager,
  },
};
</script>
