import { isObject } from "./objectHelper.js";

const addressLineParts = [
  "addressLine1",
  "addressLine2",
  "town",
  "county",
  "postcode",
  "countryCode",
];

export const addressLine = (address) => {
  if (!isObject(address)) return "";
  const addressLinePars = [];

  addressLineParts.forEach((addressPart) => {
    if (Object.prototype.hasOwnProperty.call(address, addressPart)) {
      if (address[addressPart].replace(/\s/g, "")) {
        addressLinePars.push(address[addressPart]);
      }
    }
  });

  return addressLinePars.join(", ");
};

export const isAddressValid = (address) => {
  const { addressLine1, countryCode, postcode, town } = address;
  return Boolean(addressLine1 && countryCode && postcode && town);
};

export const getAddressParts = (context) => {
  if (!isObject(context)) return "";
  const availableAddressParts = {};

  addressLineParts.forEach((addressPart) => {
    if (Object.prototype.hasOwnProperty.call(context, addressPart)) {
      availableAddressParts[addressPart] = context[addressPart];
    }
  });

  return availableAddressParts;
};
export const isPostcodeValid = (postcode) => {
  if (!postcode) return false;
  postcode = postcode.replace(/\s/g, "");

  const ukRegex = /([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})/i; // UK
  const ieRegex = /^([AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/i; // Ireland
  const caRegex =
    /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/i; // Canada
  const zaRegex = /^\d{4}$/; // South Africa
  const esRegex = /^\d{5}$/; // Spain
  const thRegex = /^\d{5}$/; // Thailand
  const usRegex = /^\d{5}([ \-]\d{4})?$/; // United States
  const huRegex = /^\d{4}$/; // Hungary
  const ttRegex = /^\d{6}$/; // Trinidad and Tobago
  const ilRegex = /^\d{5}$/; // Israel
  const auRegex = /^\d{4}$/; // Australia
  const nzRegex = /^\d{4}$/; // New Zealand
  const seRegex = /^\d{3}[ ]?\d{2}$/; // Sweden
  const noRegex = /^\d{4}$/; // Norway
  const isRegex = /^\d{3}$/; // Iceland
  const krRegex = /^\d{3}[\-]\d{3}$/; // South Korea
  const brRegex = /^\d{5}[\-]\d{3}$/; // Brazil
  const cyRegex = /^\d{4}$/; // Cyprus
  const bbRegex = /^[A-Z]{2}[ ]?\d{5}$/; // Barbados
  const inRegex = /^\d{6}$/; // India
  const trRegex = /^\d{5}$/; // Turkey
  const chRegex = /^\d{4}$/; // Switzerland
  const amRegex = /^\d{4}$/; // Armenia
  const frRegex = /^\d{2}[ ]?\d{3}$/; // France

  return Boolean(
    ukRegex.test(postcode) || // United Kingdom
      ieRegex.test(postcode) || // Ireland
      caRegex.test(postcode) || // Canada
      zaRegex.test(postcode) || // South Africa
      esRegex.test(postcode) || // Spain
      thRegex.test(postcode) || // Thailand
      usRegex.test(postcode) || // United States
      huRegex.test(postcode) || // Hungary
      ttRegex.test(postcode) || // Trinidad and Tobago
      ilRegex.test(postcode) || // Israel
      auRegex.test(postcode) || // Australia
      nzRegex.test(postcode) || // New Zealand
      seRegex.test(postcode) || // Sweden
      noRegex.test(postcode) || // Norway
      isRegex.test(postcode) || // Iceland
      krRegex.test(postcode) || // South Korea
      brRegex.test(postcode) || // Brazil
      cyRegex.test(postcode) || // Cyprus
      bbRegex.test(postcode) || // Barbados
      inRegex.test(postcode) || // India
      trRegex.test(postcode) || // Turkey
      chRegex.test(postcode) || // Switzerland
      amRegex.test(postcode) || // Armenia
      frRegex.test(postcode) // France
  );
};

export const regexes = {
  "United Kingdom": /([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})/i,
  "Republic of Ireland":
    /^([AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/i,
  Canada: /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/i,
  "South Africa": /^\d{4}$/,
  Spain: /^\d{5}$/,
  Thailand: /^\d{5}$/,
  "United States": /^\d{5}([ \-]\d{4})?$/,
  Hungary: /^\d{4}$/,
  "Trinidad and Tobago": /^\d{6}$/,
  Israel: /^\d{5}$/,
  Australia: /^\d{4}$/,
  "New Zealand": /^\d{4}$/,
  Sweden: /^\d{3}[ ]?\d{2}$/,
  Norway: /^\d{4}$/,
  Iceland: /^\d{3}$/,
  "South Korea": /^\d{3}[\-]\d{3}$/,
  Brazil: /^\d{5}[\-]\d{3}$/,
  Cyprus: /^\d{4}$/,
  Barbados: /^[A-Z]{2}[ ]?\d{5}$/,
  India: /^\d{6}$/,
  Turkey: /^\d{5}$/,
  Switzerland: /^\d{4}$/,
  Armenia: /^\d{4}$/,
  France: /^\d{2}[ ]?\d{3}$/,
};
