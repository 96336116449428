export const ep = {
  contact: "/api/contact",
  global: "/api/global",
  home: "/api/home",
  faq: "/api/faq",
  forgotPassword: "/api/forgot-password",
  login: "/api/login",
  register: "/api/register",
  resetPassword: "/api/reset-password",
  store: "/api/store",
  page: "/api/pages",
};
