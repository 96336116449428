<template>
  <v-form
    validate-on="input"
    ref="formRef"
    v-model="formValid"
    @submit.prevent="handleSubmit"
    class="cm-flex cm-flex-col"
  >
    <simple-card
      color="white"
      elevation="3"
      class="cm-p-2 cm-mb-20 cm-rounded-lg cm-white cm-overflow-visible"
      closabled
      @close="handleCancel"
    >
      <template #header>
        <div
          class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary"
        >
          Schedule a new update
        </div>
      </template>
      <form>

        <v-row>

          <v-col cols="12" md="6">
            <simple-date-input
              v-model="formattedStartDate"
              @update:modelValue="newDate => checkDate(newDate, 'start')"
              :rules="rules.startingDate"
              label="Start Date"
              hide-details="auto"
              :isSuperAdmin="isSuperAdmin"
              :min-date="startDateMinDate"
            />
          </v-col>
          
          <v-col cols="12" md="6">
            <simple-date-input
              v-model="formattedEndDate"
              @update:modelValue="newDate => checkDate(newDate, 'end')"
              label="End Date"
              hide-details="auto"
              :isSuperAdmin="isSuperAdmin"
              :min-date="endDateMinDate"
            />
          </v-col>

          <v-col v-if="mode == 'edit' || mode == 'preview'" cols="12" md="12">
            <store-autocomplete-input
              v-model="form.store"
              @update:modelValue="checkState"
              :rules="rules.stores"
              hide-details="auto"
              :isSuperAdmin="isSuperAdmin"
              :disabled="mode == 'edit'"
            />
          </v-col>

          <v-col v-if="mode == 'create'" cols="12" md="12">
            <stores-autocomplete-input
              v-model="form.store"
              @update:modelValue="checkState"
              hide-details="auto"
              :isSuperAdmin="isSuperAdmin"
            />

            <div class="d-flex align-center justify-center cm-font-bold cm-mt-2 cm-mb-2">or</div>

            <simple-text-field
              v-model="form.storeIds"
              @update:modelValue="checkState"
              density="compact"
              label="Store IDs (include a space in between)"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="6">
            <simple-text-field
              density="compact"
              label="Title"
              v-model="form.title"
              :rules="rules.title"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="6">
            <simple-select-input
              v-model="form.type"
              :rules="rules.type"
              @update:modelValue="checkState"
              density="compact"
              label="I want to update"
              :items="typeOptions"
              item-title="label"
              item-value="value"
              hide-details="auto"
            />
          </v-col>

        </v-row>

        <v-row v-if="form.type == 'opening times' && (form.store || form.storeIds)" justify="center">

          <v-col cols="12">
            <simple-select-input
              v-model="form.day"
              :rules="rules.day"
              density="compact"
              label="On"
              :items="[{
                  label: 'Select a day',
                  value: '',
                },{
                  label: 'Sunday',
                  value: 0,
                },
                {
                  label: 'Monday',
                  value: 1,
                },
                {
                  label: 'Tuesday',
                  value: 2,
                },
                {
                  label: 'Wednesday',
                  value: 3,
                },
                {
                  label: 'Thursday',
                  value: 4,
                },
                {
                  label: 'Friday',
                  value: 5,
                },
                {
                  label: 'Saturday',
                  value: 6,
                },
              ]"
              item-title="label"
              item-value="value"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12" md="6">
            <simple-time-input
              v-model="formattedStartTime"
              :rules="rules.startTime"
              label="Starting from"
              hide-details="auto"
              :start-time="startStartTime"
              @update:modelValue="handleStartTimeChange"
              :isSuperAdmin="isSuperAdmin"
            />
          </v-col>

          <v-col cols="12" md="6">
            <simple-time-input
              v-model="formattedEndTime"
              :rules="rules.endTime"
              label="To"
              hide-details="auto"
              :min-time="endMinTime"
              :start-time="endStartTime"
              @update:modelValue="handleEndTimeChange"
              :isSuperAdmin="isSuperAdmin"
              :disabled="formattedStartTime == null"
            />
          </v-col>

        </v-row>

        <v-row v-if="form.type == 'delivery fee and minimum spends' && (form.store || form.storeIds)" justify="center">

          <v-col cols="12" v-if="form.zones.length > 0 && loadMap">

            <v-card class="pa-0" elevation="3">
              <app-map :zones="fees.zones" :map-api-key="mapApiKey" />
            </v-card>
          </v-col>

          <v-col cols="12">
            <div class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary">
              All Delivery Zone Information
            </div>
          </v-col>

          <v-col cols="12">
            <v-row justify="center" align="center">
              <v-col cols="12" md="3">
                <strong>All</strong> Zones
              </v-col>

              <v-col cols="6" md="3">
                <simple-text-field
                  v-model="form.minimumSpendForDelivery"
                  :rules="rules.minimumSpendForDelivery"
                  label="Min Spend"
                  hide-details="auto"
                />
              </v-col>

              <v-col cols="6" md="3">
                <simple-text-field
                  v-model="form.minimumSpendForFreeDelivery"
                  :rules="rules.minimumSpendForFreeDelivery"
                  label="Free Delivery"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <div
              class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary"
            >
              Individual Delivery Zones
            </div>
          </v-col>


          <v-col cols="12"  v-for="(zone, index) in form.zones">
            <v-row justify="center" align="center">
              <v-col cols="12" md="3">
                <strong>{{ calculateLetter(index) }}</strong> Zone
              </v-col>

              <v-col cols="12" md="3">
                <simple-text-field
                  v-model="zone.cost"
                  :rules="rules.zoneFee"
                  label="Delivery Fee"
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </v-col>
          
        </v-row>

        <v-row v-if="form.type == 'store message' && (form.store || form.storeIds)" justify="center">
          <v-col cols="12">
            <simple-text-field
              density="compact"
              label="Store Message"
              v-model="form.storeMessage"
              hide-details="auto"
            />
          </v-col>
        </v-row>

      </form>
      <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">

        <template #leftButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#E42034"
            size="large"
            :text="mode == 'create' || mode == 'preview' ? `Cancel` : `Delete Update`"
            @click="mode == 'create' || mode == 'preview' ? handleCancel() : scheduleDelete()"
          ></simple-button>

          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold cm-mt-2"
            :color="mode == 'create' || mode == 'preview' ? `#14569E` : `#E42034`"
            variant="secondary"
            size="large"
            :text="`Bulk Delete Similar (` + (similar.length + 1) + `)`"
            v-if="mode == 'edit' && similar.length && form.type == 'store message'"
            @click="scheduleDelete(true)"
          ></simple-button>
        </template>
        <template #rightButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#14569E"
            size="large"
            :text="mode == 'create' ? `Save Update` : 'Save Changes'"
            type="submit"
            :disabled="isDisabled || mode == 'preview'"
          ></simple-button>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold cm-mt-2"
            color="#14569E"
            variant="secondary"
            size="large"
            :text="`Bulk Update Similar (` + (similar.length + 1) + `)`"
            v-if="mode == 'edit' && similar.length && form.type == 'store message'"
            @click="handleSubmit(true)"
          ></simple-button>
        </template>

      </bottom-navigation>
    </simple-card>
  </v-form>
</template>
<script>
import { ref, watch, reactive, toRaw, computed, onMounted, onBeforeUnmount, nextTick } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";
import { useToast } from "vue-toastification";
import api from "@/utils/api.js";
import debounce from "lodash.debounce";

import { useScheduleNavigationStore } from "@/stores/ScheduleStoreUpdates/navigation.ts";
import { useScheduleStore } from "@/stores/ScheduleStoreUpdates/schedule.ts";
import { useAuthStore } from "@/stores/auth.ts";
import { useBusinessStore } from "@/stores/business.ts";
import {
  RULE_REQUIRED,
} from "@/constants/rules.js";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";

import SimpleTextField from "@/components/ui/SimpleTextField.vue";
import StoreAutocompleteInput from "@/components/scheduleStoreUpdates/forms/StoreAutocompleteInput.vue"
import StoresAutocompleteInput from "@/components/scheduleStoreUpdates/forms/StoresAutocompleteInput.vue"
import SimpleSelectInput from "@/components/ui/SimpleSelectInput.vue";
import SimpleDateInput from "@/components/ui/SimpleDateInput.vue";
import SimpleTimeInput from "@/components/ui/SimpleTimeInput.vue";

import AppMap from "@/components/scheduleStoreUpdates/AppMap.vue";

export default {
  name: "ScheduleFormPartView",
  components: {
    SimpleCard,
    SimpleButton,
    BottomNavigation,
    SimpleTextField,
    StoreAutocompleteInput,
    StoresAutocompleteInput,
    SimpleSelectInput,
    SimpleDateInput,
    SimpleTimeInput,
    AppMap,
  },
  props: {
    mapApiKey: {
      type: String,
    },
  },
  setup(props) {

    const scheduleNavigationStore = useScheduleNavigationStore();
    const scheduleStore = useScheduleStore();
    const { fees, mode } = storeToRefs(useScheduleStore());
    const { isSuperAdmin } = storeToRefs(useAuthStore());
    const authStore = useAuthStore();
    const businessStore = useBusinessStore();

    const form = ref({ ...scheduleStore.schedule });
    const formValid = ref(false);
    const formRef = ref(null);
    const toast = useToast();

    const similar = ref({});

    const startStartTime = ref({ hours: 0, minutes: 0 });
    const endMinTime = ref(null);
    const endStartTime = ref({ hours: 0, minutes: 0 });

    const loadMap = ref(false);

    const rules = ref({
      title: [RULE_REQUIRED],
      startingDate: [RULE_REQUIRED],
      stores: [RULE_REQUIRED],
      type: [RULE_REQUIRED],
      minimumSpendForFreeDelivery: [RULE_REQUIRED],
      minimumSpendForDelivery: [RULE_REQUIRED],
      day: [RULE_REQUIRED],
      startTime: [RULE_REQUIRED],
      endTime: [RULE_REQUIRED],
      zoneFee: [RULE_REQUIRED],
    });

    const mapApiKey = props.mapApiKey;

    const typeOptions = computed(() => {
      return (form.value.store && form.value.store.length > 1 || form.value.storeIds)
        ? [
            { label: "Store Message", value: "store message" },
          ]
        : [
            { label: "Opening Times", value: "opening times" },
            { label: "Delivery Fee and Minimum Spend", value: "delivery fee and minimum spends" },
            { label: "Store Message", value: "store message" },
          ];
    });

    onMounted(async () => {

      if (scheduleStore.schedule.id) {
        mode.value = "edit";

        try {
          const schedule = await scheduleStore.getSchedule(scheduleStore.schedule.id);
          form.value.startingDate = moment(schedule.queuedAt).format("YYYY-MM-DD");
          form.value.title = schedule.title
          form.value.store = schedule.store
          form.value.type = schedule.type

          if (schedule.endingDate) {
            form.value.endingDate = moment(schedule.endingDate).format("YYYY-MM-DD");
          }

          form.value.status = schedule.status

          if (form.value.status != "Queued") {
            mode.value = "preview";
          }

          if (schedule.type == "store message") {
            form.value.storeMessage = schedule.storeMessage
            similar.value = await scheduleStore.similarSchedules(schedule)
          } else if (schedule.type == "opening times") {
            form.value.day = schedule.day
            form.value.startTime = schedule.startTime
            form.value.endTime = schedule.endTime
          } else if (schedule.type == "delivery fee and minimum spends") {
            try {
              await scheduleStore.getDeliveryZones(schedule.store.id);
              form.value.minimumSpendForDelivery = schedule.minimumSpendForDelivery
              form.value.minimumSpendForFreeDelivery = schedule.minimumSpendForFreeDelivery
              form.value.zones = fees.value.zones

              await nextTick()
              loadMap.value = true;

              if ((schedule.zones)) {
                schedule.zones.forEach(schedulezone => {
                  form.value.zones.forEach(zone => {
                    if (zone.id == schedulezone.id) {
                      zone.cost = schedulezone.fee
                    }
                  });
                });
              }
            } catch (e) {
              toast.error(e.message, {
                timeout: 2000,
              });
            }
          }

        } catch (e) {
          toast.error(e.message, {
            timeout: 2000,
          });

          scheduleNavigationStore.viewDashboard();
        }
      } else {
        mode.value = "create";
      }
    });

    onBeforeUnmount(() => {
      mode.value = null;
    });

    const checkDate = (newDate, type) => {
      if (type == 'start' && form.value.endingDate) {
        if (form.value.endingDate <= form.value.startingDate) {
          form.value.endingDate = null;
        }
      }
    };

    const handleSubmit = async (bulkUpdate = false) => {

      if (!form.value.store && !form.value.storeIds) {
        toast.error('Either store or store ids must be filled');
        return;
      }

      let storeIds = [];
      let storeId = null;


      if (form.value.storeIds) {
        storeIds = form.value.storeIds.split(/\s+/).map(Number);
      } else if (Array.isArray(form.value.store)) {
        storeIds = form.value.store.map(item => item.id);
      } else {
        storeIds = [form.value.store.id];
      }

      if (form.value.type != "store message") {
        storeId = storeIds[0]
      }

      try {
        const { valid } = await formRef.value.validate();
        if (!valid) {
          return;
        }

        let formFields = {}

        if (form.value.type == "store message") {
          formFields = {
            storeId: storeIds[0],
            type: form.value.type,
            title: form.value.title,
            queuedAt: form.value.startingDate,
            createdBy: businessStore.userId,
            storeMessage: form.value.storeMessage,
          }
        } else if (form.value.type == "opening times") {
          formFields = {
            storeId: storeId,
            type: form.value.type,
            title: form.value.title,
            queuedAt: form.value.startingDate,
            createdBy: businessStore.userId,
            day: form.value.day,
            startTime: form.value.startTime,
            endTime: form.value.endTime,
          }
        } else if (form.value.type == "delivery fee and minimum spends") {
          await scheduleStore.getDeliveryZones(storeId);
          let zones = []
          fees.value.zones.forEach(schedulezone => {
            form.value.zones.forEach(zone => {
              if (zone.id == schedulezone.id) {
                const currentZone = {
                  id: zone.id,
                  fee: zone.cost
                }
                zones.push(currentZone)
              }
            });
          });

          formFields = {
            storeId: storeId,
            type: form.value.type,
            title: form.value.title,
            queuedAt: form.value.startingDate,
            createdBy: businessStore.userId,
            minimumSpendForDelivery: form.value.minimumSpendForDelivery,
            minimumSpendForFreeDelivery: form.value.minimumSpendForFreeDelivery,
            zones: zones
          }
        }

        if (form.value.endingDate) {
          formFields.endingDate = form.value.endingDate;
        }

        try {
          if (mode.value == "edit") {

            await scheduleStore.updateSchedule(form.value.id, formFields);

            if (bulkUpdate == true) {
              const bulkIds = similar.value.map(item => item.id);
              bulkIds.forEach( async function (id, index) {
                formFields.storeId = similar.value[index].store.id
                await scheduleStore.updateSchedule(id, formFields);
              });
            }

            if (bulkUpdate == true) {
              toast.success("Schedules Updated");
            } else {
              toast.success("Schedule Updated");
            }
          } else {

            for (let i = 0; i < storeIds.length; i++) {
              formFields.storeId = storeIds[i];
              await scheduleStore.createSchedule(formFields);
            }

            if (bulkUpdate == true) {
              toast.success("Schedules Created");
            } else {
              toast.success("Schedule Created");
            }
          }
        } catch (e) {
          toast.error(e.message, {
            timeout: 2000,
          });
        }

        scheduleNavigationStore.viewDashboard();
      } catch (e) {
        toast.error(e.message, {
          timeout: 2000,
        });
      }
  
    };

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    const startDateMinDate = computed(() => {
      return moment().add('days', 1).format("YYYY-MM-DD");
    });

    const endDateMinDate = computed(() => {
      return moment(form.value.startingDate).add('days', 1).format("YYYY-MM-DD");
    });

    const formattedStartDate = computed({
      set(value) {
        form.value.startingDate = moment(value).format("YYYY-MM-DD");
      },
      get() {
        return form.value.startingDate;
      },
    });

    const formattedEndDate = computed({
      set(value) {
        form.value.endingDate = moment(value).format("YYYY-MM-DD");
      },
      get() {
        return form.value.endingDate;
      },
    });

    const formattedStartTime = computed({
      set(value) {
        form.value.startTime = moment(value).format("HH:mm");
      },
      get() {
        return form.value.startTime;
      },
    });

    const formattedEndTime = computed({
      set(value) {
        form.value.endTime = moment(value).format("HH:mm");
      },
      get() {
        return form.value.endTime;
      },
    });

    const handleCancel = () => {
      scheduleNavigationStore.viewDashboard();
    };

    const scheduleDelete = async (bulkDelete = false) => {
      await scheduleStore.deleteSchedule(scheduleStore.schedule.id);

      if (bulkDelete == true) {
        const bulkIds = similar.value.map(item => item.id);
        bulkIds.forEach(async id => {
          await scheduleStore.deleteSchedule(id);
        });
      }

      scheduleNavigationStore.viewDashboard();

      if (bulkDelete == true) {
        toast.success("Schedules Deleted");
      } else {
        toast.success("Schedule Deleted");
      }
    };

    function calculateLetter(index) {
      index++
      const prepend = Math.floor(index / 26)
      let letter = ''
      if (prepend > 0) {
        letter = prepend
        index = index - (26 * prepend)
      }
      letter = letter + String.fromCharCode(index + 64);
      return letter;
    };

    const checkState = async (event) => {

      const numberSeriesPattern = /^(\d+\s)*\d+$/;
      if (numberSeriesPattern.test(event)) {
        form.value.store = null;
      } else if (form.value.store) {
        form.value.storeIds = null;
      }

      if ((form.value.store && form.value.store.length > 1 || form.value.storeIds) && form.value.type != "store message" && form.value.type != null) {
        form.value.type = '';        
      }

      loadMap.value = false;
      if (form.value.store && form.value.type && form.value.type == "delivery fee and minimum spends") {
        try {
          await scheduleStore.getDeliveryZones(form.value.store[0].id);

          form.value.minimumSpendForDelivery = fees.value.minimumSpendForDelivery
          form.value.minimumSpendForFreeDelivery = fees.value.minimumSpendForFreeDelivery
          form.value.zones = fees.value.zones

          await nextTick()
          loadMap.value = true;
        } catch (e) {
          toast.error(e.message, {
            timeout: 2000,
          });
        }
      }
    };

    const handleStartTimeChange = (time) => {
      endMinTime.value = time;
      endStartTime.value = time;
      form.value.endTime = null;
    };

    return {
      form,
      mode,
      isDisabled,
      handleSubmit,
      checkDate,
      handleCancel,
      scheduleDelete,
      formValid,
      formRef,
      rules,
      startDateMinDate,
      endDateMinDate,
      formattedStartDate,
      formattedEndDate,
      formattedStartTime,
      formattedEndTime,
      startStartTime,
      endMinTime,
      endStartTime,
      handleStartTimeChange,
      fees,
      calculateLetter,
      checkState,
      isSuperAdmin,
      mapApiKey,
      typeOptions,
      loadMap,
      similar,
    }
  }
};
</script>
