import { defineStore } from "pinia";

export const useNavigationStore = defineStore("navigation", {
  state: () => {
    return {
      view: "dashboard",
    };
  },
  getters: {},
  actions: {
    viewDashboard() {
      this.view = "dashboard";
    },
    viewCouponFormStepThree() {
      this.view = "coupon-form-step-three";
    },
    viewCouponFormStepTwo() {
      this.view = "coupon-form-step-two";
    },
    viewCouponFormStepOne() {
      this.view = "coupon-form-step-one";
    },
    viewCouponDetails() {
      this.view = "coupon-details";
    },
    viewCouponReview() {
      this.view = "coupon-review";
    },
    viewSearchFilter() {
      this.view = "search-filter";
    },
    viewSearchResults() {
      this.view = "search-results";
    },
    viewSearch() {
      this.view = "search";
    },
  },
});
