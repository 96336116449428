<template>
  <v-autocomplete
    :loading="loading"
    density="compact"
    :items="items"
    @update:search="handleSearch"
    @update:modelValue="handleSelect"
    item-title="name"
    item-value="id"
    return-object
    hide-no-data
    v-model:search="search"
    v-model="selected"
    append-inner-icon="mdi-magnify"
    variant="outlined"
    v-bind="$attrs"
  />
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import debounce from "lodash.debounce";
import { useToast } from "vue-toastification";
import api from "@/utils/api.js";

export default {
  name: "MenuGroupSearch",
  props: {
    modelValue: {
      type: Object,
    },
    autoClearAfterSelect: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  setup(props, { emit }) {
    const items = ref([]);
    const loading = ref(false);
    const search = ref();
    const selected = ref();
    const toast = useToast();

    onMounted(() => {
      if (props.modelValue) {
        items.value = [props.modelValue];
        selected.value = props.modelValue;
      }
    });

    const handleSearch = debounce(async (term) => {
      try {
        if (!term || term === selected.value?.name) {
          return;
        }
        loading.value = true;
        const { menuGroups } =
          await api.copyProductsToMenuGroups.menuGroupsList({
            searchName: term,
          });
        items.value = menuGroups;
        loading.value = false;
      } catch (e) {
        loading.value = false;
        toast.error(e, {
          timeout: 2000,
        });
      }
    }, 400);

    const handleSelect = (selectedItem) => {
      if (selectedItem && search) {
        emit("onSelected", selectedItem);
        if (props.autoClearAfterSelect) {
          nextTick(() => {
            search.value = null;
          });
        }
      }
    };

    return {
      selected,
      items,
      loading,
      search,
      handleSearch,
      handleSelect,
    };
  },
};
</script>
