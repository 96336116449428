<template>
  <div :class="border ? borderClass : null" class="simple-checkbox">
    <v-checkbox
      v-bind="$attrs"
      class="cm-md-0"
      :class="border ? 'bordered-checkbox' : ''"
      :color="color"
      :style="borderStyle"
      false-icon="mdi-circle"
      true-icon="mdi-check-circle"
      hide-details
    >
      <template #label>
        <div class="label" :class="semiBorder ? 'cm-font-bold' : ''">
          {{ label }}
        </div>
      </template>
    </v-checkbox>
  </div>
</template>
<script>
import { computed } from "vue";
export default {
  name: "SimpleCheckbox",
  props: {
    label: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    border: {
      type: Boolean,
      default: false,
    },
    semiBorder: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const borderClass = computed(() => {
      if (props.semiBorder) {
        return `v-checkbox v-selection-control border-color-primary border-color-secondary`;
      }
      return `v-checkbox v-selection-control border-color-primary border-color-secondary `;
    });
    const borderStyle = computed(() => {
      return props.border
        ? {
            border: `1px ${props.semiBorder ? "solid" : "dashed"} ${
              props.semiBorder ? "#D5D5D5" : ""
            }`,
            borderLeft: `${props.semiBorder ? "none" : "1px dashed"}`,
            borderRight: `${props.semiBorder ? "none" : "1px dashed"}`,
            borderRadius: `${props.semiBorder ? "0" : "8px"} `,
            padding: "10px",
          }
        : {};
    });
    return {
      borderClass,
      borderStyle,
    };
  },
};
</script>
<style>
.bordered-checkbox .v-selection-control {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}
</style>
