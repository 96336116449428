<template>
  <div>
    <WhiteLabelManagerFormFieldColorPicker
      v-if="colors.length === 1"
      :colorValue="colors[0]"
      @colorUpdate="onColorUpdate(0, $event)"
      v-bind="$attrs"
    />
    <div v-if="colors.length === 2">
      <WhiteLabelManagerFormFieldColorPicker
        label="Light Mode"
        :colorValue="colors[0]"
        @colorUpdate="onColorUpdate(0, $event)"
        v-bind="$attrs"
      />
      <WhiteLabelManagerFormFieldColorPicker
        label="Dark Mode"
        :colorValue="colors[1]"
        @colorUpdate="onColorUpdate(1, $event)"
        v-bind="$attrs"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import WhiteLabelManagerFormFieldColorPicker from "@/components/whiteLabelManager/form/fields/ColorPicker.vue";

export default {
  components: {
    WhiteLabelManagerFormFieldColorPicker,
  },
  props: {
    value: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const colors = ref(props.value.split("|"));

    watch(
      () => props.value,
      (newValue) => {
        colors.value = newValue.split("|");
      }
    );

    const onColorUpdate = (index, color) => {
      colors.value[index] = color;
      emit("onValueUpdate", colors.value.join("|"));
    };

    return {
      colors,
      onColorUpdate,
    };
  },
};
</script>
