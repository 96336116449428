<template>
  <schedule-store-updates />
</template>

<script>
import ScheduleStoreUpdates from "@/components/scheduleStoreUpdates/Index.vue";
export default {
  components: {
    ScheduleStoreUpdates,
  },
};
</script>
