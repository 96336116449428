<template>
  <simple-text-field
    density="compact"
    label="Coupon Name"
    tooltipText="The name for your coupons, helps you to identify it in the list"
    icon="mdi-information-outline"
    icon-color="#14569E"
    placeholder="Coupon Name"
    v-bind="$attrs"
  />
</template>
<script>
import SimpleTextField from "@/components/ui/SimpleTextField.vue";
export default {
  name: "CouponFormNameField",
  components: {
    SimpleTextField,
  },
};
</script>
