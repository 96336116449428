<template>
  <GoogleMap
    :api-key="apiKey"
    v-bind="$attrs"
    :center="center"
    :disable-default-ui="disableDefaultUi"
    :gesture-handling="gestureHandling"
    :zoom="15"
  >
    <Marker :options="markerOptions" @dragend="handleDragEnd" />
  </GoogleMap>
</template>

<script>
import { defineComponent, toRefs, computed } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
  components: { GoogleMap, Marker },
  props: {
    disableDefaultUi: {
      type: Boolean,
      default() {
        return false;
      },
    },
    gestureHandling: {
      type: String,
      default() {
        return "auto";
      },
    },
    location: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const { location, disableDefaultUi } = toRefs(props);
    const markerOptions = computed(() => {
      return {
        draggable: !disableDefaultUi.value,
        position: location.value,
      };
    });

    const handleDragEnd = (position) => {
      emit("onMarkerUpdated", {
        lng: position.latLng.lng(),
        lat: position.latLng.lat(),
      });
    };

    return {
      center: location,
      markerOptions,
      handleDragEnd,
      apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    };
  },
});
</script>
