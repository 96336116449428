<template>
  <div v-if="domainName" class="pa-3">
    <v-row>
      <v-col cols="6">
        <div class="cm-text-xl font-weight-bold text-text-darker mb-3">
          Domain Name
        </div>
        <v-card class="pa-3" elevation="3">
          <v-list>
            <edit-text-field
              text-field-name="Domain Name"
              :edit-model="domainName"
              :rules="rules.domainName"
              @edit-model-value="domainName = $event"
              @update-model-value="
                basicStoreInfo.updateBusinessBasicsInfo(id, item)
              "
            >
            </edit-text-field>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-divider v-if="domainName" class="my-5"></v-divider>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useBasicStoreInfo } from "@/stores/RetailersDashboard/basicStoreInfo.ts";
import RetailersDashboardEditTextField from "@/components/retailersDashboard/forms/RetailersDashboardEditTextField.vue";

import { RULE_DOMAIN_NAME } from "@/constants/rules.js";
export default {
  components: {
    EditTextField: RetailersDashboardEditTextField,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: String || Number,
      required: true,
    },
  },
  setup(props) {
    const basicStoreInfo = useBasicStoreInfo();
    const details = ref({});

    const domainName = computed({
      get() {
        return details?.value?.domainName
          ? details.value.domainName.replace(/\s{2,}/g, " ")
          : "";
      },
      set(value) {
        details.value.domainName = value;
      },
    });

    const rules = ref({
      domainName: RULE_DOMAIN_NAME,
    });

    onMounted(async () => {
      setTimeout(async () => {
        details.value = await props?.item?.details;
      }, 400);
    });
    return {
      details,
      rules,
      basicStoreInfo,
      domainName,
    };
  },
};
</script>
