<template>
  <v-breadcrumbs divider="/" class="cm-justify-center">
    <template v-for="(item, index) in items" :key="index">
      <v-breadcrumbs-item :active="item.active" active-class="cm-font-bold">
        {{ item.label }}
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider v-if="index < items.length - 1">
        <v-icon icon="mdi-chevron-right"></v-icon>
      </v-breadcrumbs-divider>
    </template>
  </v-breadcrumbs>
</template>
<script>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useLoyaltyRewardsNavigationStore } from "@/stores/LoyaltyRewards/loyaltyRewardsNavigation.ts";
export default {
  name: "CouponFormBreadcrumbs",
  setup() {
    const navigationLoyaltyRewardsStore = useLoyaltyRewardsNavigationStore();
    const { view } = storeToRefs(navigationLoyaltyRewardsStore);

    const items = computed(() => {
      return [
        {
          view: "loyalty-rewards-create-form-step-one",
          label: "Rewards Details",
          active: view.value === "loyalty-rewards-create-form-step-one",
        },
        {
          view: "loyalty-rewards-create-form-step-two",
          label: "Excluded From Rewards",
          active: view.value === "loyalty-rewards-create-form-step-two",
        },
      ];
    });

    return { items };
  },
};
</script>
