<template>
  <simple-number-field
    density="compact"
    label="Percentage Value"
    tooltipText="The discount percentage you would like to reduce the basket value by if a customer uses the coupon. The discount is applied before fees"
    icon="mdi-information-outline"
    icon-color="#14569E"
    placeholder="10 % Percentage off"
    decimals="2"
    max="100"
    min="1"
    v-bind="$attrs"
  />
</template>
<script>
import SimpleNumberField from "@/components/ui/SimpleNumberField.vue";
export default {
  name: "CouponFormPercentageValueField",
  components: {
    SimpleNumberField,
  },
};
</script>
