<template>
  <simple-checkbox label="This is for new customers only" v-bind="$attrs" />
</template>
<script>
import SimpleCheckbox from "@/components/ui/SimpleCheckbox.vue";
export default {
  name: "CouponFormNewCustomersOnlyField",
  components: {
    SimpleCheckbox,
  },
};
</script>
