export function getViteBusinessId() {
  return import.meta.env.VITE_BUSINESS_ID;
}

export function getViteApiBaseUrl() {
  return import.meta.env ? import.meta.env.VITE_API_BASE_URL : "";
}

export function getViteMocking() {
  return import.meta.env.VITE_MOCKING;
}

export function getViteGoogleMapsApiKey() {
  return import.meta.env ? import.meta.env.VITE_GOOGLE_MAPS_API_KEY : "";
}
