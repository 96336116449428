import { defineStore } from "pinia";

export const useRetailersNavigationStore = defineStore("retailersNavigation", {
  state: () => {
    return {
      view: "dashboard",
    };
  },
  getters: {},
  actions: {
    viewRetailersDashboard() {
      this.view = "dashboard";
    },
    viewRetailersDashboardDetails() {
      this.view = "retailers-details";
    },
  },
});
