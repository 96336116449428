<template>
  <div class="cm-border-b cm-pb-6 cm-mb-6">
    <div class="cm-text-base cm-font-bold mb-6">Your selected products</div>
    <product-manager :menuGroupId="menuGroupId" edit-mode v-bind="$attrs" />
  </div>
</template>

<script>
import ProductManager from "@/components/copyProductsToMenuGroups/forms/ProductManager.vue";

export default {
  props: {
    menuGroupId: {
      type: Number,
    },
  },
  components: {
    ProductManager,
  },
};
</script>
