<template>
  <div class="pa-3">
    <v-row>
      <v-col
        v-if="getCollectionHours && getCollectionHours.length"
        cols="12"
        md="6"
      >
        <div class="tw-text-xl font-weight-bold text-text-darker mb-3">
          Collection Opening Hours
        </div>
        <v-card class="pa-3" elevation="3" :loading="loading">
          <v-list>
            <v-list-item v-for="(item, day) in getCollectionHours" :key="day">
              <div class="d-flex d-sm-block flex-column">
                <strong class="mr-3 text-capitalize"
                  >{{ dayOfWeekLabel(item) }}:
                </strong>
                <span v-if="item.open.length && item.close.length"
                  >{{ item.open }} to {{ item.close }}</span
                >
                <span v-if="!item.open.length || !item.close.length">
                  Not Available
                </span>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        v-if="getDeliveryHours && getDeliveryHours.length"
        cols="12"
        md="6"
      >
        <div class="tw-text-xl font-weight-bold text-text-darker mb-3">
          Delivery Opening Hours
        </div>
        <v-card class="pa-3" elevation="3">
          <v-list>
            <v-list-item v-for="(item, day) in getDeliveryHours" :key="day">
              <div class="d-flex d-sm-block flex-column">
                <strong class="mr-3 text-capitalize"
                  >{{ dayOfWeekLabel(item) }}:
                </strong>
                <span v-if="item.open.length && item.close.length"
                  >{{ item.open }} to {{ item.close }}</span
                >
                <span v-if="!item.open.length || !item.close.length">
                  Not Available
                </span>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col v-if="getTableHours && getTableHours.length" cols="12" md="6">
        <div class="tw-text-xl font-weight-bold text-text-darker mb-3">
          Table Opening Hours
        </div>
        <v-card class="pa-3" elevation="3">
          <v-list>
            <v-list-item v-for="(item, day) in getTableHours" :key="day">
              <div>
                <strong class="mr-3 text-capitalize"
                  >{{ dayOfWeekLabel(item) }}:
                </strong>
                <span v-if="item.open.length && item.close.length"
                  >{{ item.open }} to {{ item.close }}</span
                >
                <span v-if="!item.open.length || !item.close.length">
                  Not Available
                </span>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-divider
      v-if="getTableHours || getDeliveryHours || getCollectionHours"
      class="my-5"
    ></v-divider>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useBasicStoreInfo } from "@/stores/RetailersDashboard/basicStoreInfo.ts";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: String || Number,
      required: true,
    },
  },
  setup(props) {
    const basicStoreInfo = useBasicStoreInfo();
    const openingHours = ref({});
    const loading = ref(true);

    const dayOfWeekLabel = (day) => {
      switch (day.weekday) {
        case 0:
          return "Sunday";
        case 1:
          return "Monday";
        case 2:
          return "Tuesday";
        case 3:
          return "Wednesday";
        case 4:
          return "Thursday";
        case 5:
          return "Friday";
        case 6:
          return "Saturday";
      }
    };

    const getCollectionHours = computed(() => {
      return openingHours?.value?.collection;
    });
    const getDeliveryHours = computed(() => {
      return openingHours?.value?.delivery;
    });
    const getTableHours = computed(() => {
      return openingHours?.value?.table;
    });

    onMounted(async () => {
      setTimeout(async () => {
        openingHours.value = await props?.item?.openingHours;
        loading.value = false;
      }, 600);
    });
    return {
      openingHours,
      basicStoreInfo,
      getCollectionHours,
      getDeliveryHours,
      getTableHours,
      dayOfWeekLabel,
      loading,
    };
  },
};
</script>
