import { defineStore } from "pinia";

export const useScheduleNavigationStore = defineStore("scheduleNavigationStore", {
  state: () => {
    return {
      view: "dashboard",
    };
  },
  getters: {},
  actions: {
    viewDashboard() {
      this.view = "dashboard";
    },
    viewScheduleForm() {
      this.view = "schedule-form";
    },
  },
});
