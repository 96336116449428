import { useAuthStore } from "@/stores/auth.ts";

export const useAuthenticate = () => {
  const authStore = useAuthStore();

  const init = async (
    accessToken = null,
    businessId = null,
    isSuperAdmin = 0
  ) => {
    try {
      await authStore.authenticate(accessToken, businessId, isSuperAdmin);
    } catch {
      throw new Error("Unable authenticate user");
    }
  };

  return { init };
};
