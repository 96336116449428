export const couponPayload = (coupon) => {
  const {
    bulkExcludeAlcohol,
    bulkExcludeTobacco,
    code,
    customerRestrictions,
    endDate,
    itemRestrictions,
    name,
    rewardRestrictions,
    startDate,
    type,
    locationRestrictions,
  } = coupon;

  let payload = {
    name,
    code,
    type,
    bulkExcludeAlcohol,
    bulkExcludeTobacco,
    customerRestrictions: {
      ...customerRestrictions,
      singleUsePerCustomer: true,
    },
    rewardRestrictions: {
      minimumSpend: Number(rewardRestrictions.minimumSpend),
      redemptions: Number(rewardRestrictions.redemptions),
    },
    itemRestrictions: {
      ...itemRestrictions,
    },
  };

  if (
    itemRestrictions.specificItemIds &&
    itemRestrictions.specificItemIds.length
  ) {
    payload.itemRestrictions = {
      ...payload.itemRestrictions,
      specificItemIds: itemRestrictions.specificItemIds.map((item) => item.id),
    };
  }

  if (
    itemRestrictions.specificBarcodes &&
    itemRestrictions.specificBarcodes.length
  ) {
    payload.itemRestrictions = {
      ...payload.itemRestrictions,
      specificBarcodes: itemRestrictions.specificBarcodes.map((barcode) =>
        typeof barcode === "string" ? barcode : barcode.barcode
      ),
    };
  }

  if (locationRestrictions.stores && locationRestrictions.stores.length) {
    payload.locationRestrictions = {
      stores: {
        ids: locationRestrictions.stores.map((store) => store.id),
      },
    };
  }

  if (startDate) {
    payload.startDate = startDate;
  }

  if (endDate) {
    payload.endDate = endDate;
  }

  if (type === "set") {
    payload.rewardRestrictions.value = Number(rewardRestrictions.value);
  }

  if (type === "percent") {
    payload.rewardRestrictions.value = Number(rewardRestrictions.value);
    payload.rewardRestrictions.maxDiscount = Number(
      rewardRestrictions.maxDiscount
    );
  }

  return payload;
};
