<template>
  <simple-number-field
    density="compact"
    label="Discount Value"
    placeholder="£10.00 Off..."
    tooltipText="The discount amount you would like to reduce the basket value by if a customer uses the coupon"
    icon="mdi-information-outline"
    icon-color="#14569E"
    v-bind="$attrs"
    decimals="2"
  />
</template>
<script>
import SimpleNumberField from "@/components/ui/SimpleNumberField.vue";
export default {
  name: "CouponFormDiscountValueField",
  components: {
    SimpleNumberField,
  },
};
</script>
