<template>
  <div>
    <v-file-input
      :loading="loading"
      label="Change Image"
      v-bind="$attrs"
      accept="image/jpeg, image/jpg, image/png, image/svg+xml"
      @update:modelValue="handleFileUpdate"
      data-cy="cypress-white-label-manager-image-field"
    />
    <div>{{ value }}</div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useToast } from "vue-toastification";
import api from "@/utils/api.js";
export default {
  props: {
    value: {
      type: String,
    },
  },
  setup(_, { emit }) {
    const toast = useToast();
    const loading = ref(false);
    const handleFileUpdate = async (files) => {
      var formdata = new FormData();
      formdata.append("file", files[0]);
      try {
        loading.value = true;
        const { file, status } = await api.whiteLabelManager.image.upload(
          formdata
        );

        if (status === "success") {
          emit("onValueUpdate", file);
        }

        loading.value = false;
      } catch {
        toast.error("Unable to upload image");
        loading.value = false;
      }
    };

    return {
      loading,
      handleFileUpdate,
    };
  },
};
</script>
