import { useApi } from "../composables/useApi";
import { useAuthStore } from "@/stores/auth.ts";
import { useBusinessStore } from "@/stores/business.ts";
import { storeToRefs } from "pinia";
import { getViteApiBaseUrl } from "../../config.js";

export default {
  whiteLabelManager: {
    settings: {
      get(businessId, type) {
        return useApi(`whitelabel/settings/${businessId}/${type}`);
      },
      update(businessId, body) {
        return useApi(`whitelabel/settings/${businessId}`, {
          method: "PATCH",
          body: JSON.stringify({
            ...body,
          }),
        });
      },
    },
    image: {
      upload(formData) {
        return useApi("image/upload/business-profile", {
          method: "POST",
          headers: {
            "Content-Type": undefined,
          },
          body: formData,
        });
      },
    },
  },
  copyProductsToMenuGroups: {
    menuGroupsList(payload = {}) {
      return useApi("menugroups/list.json", {
        method: "POST",
        body: JSON.stringify({
          ...payload,
        }),
      });
    },
    categoriesList(payload = {}) {
      return useApi("categories/select.json", {
        method: "POST",
        body: JSON.stringify({
          ...payload,
        }),
      });
    },
    getMenuGroups(payload = {}) {
      return useApi("menugroups/get.json", {
        method: "POST",
        body: JSON.stringify({
          ...payload,
        }),
      });
    },
    moveMenuGroups(payload = {}) {
      return useApi("menugroups/move.json", {
        method: "POST",
        body: JSON.stringify({
          ...payload,
        }),
      });
    },
  },
  couponManager: {
    stores: {
      search(payload = {}) {
        const authStore = useAuthStore();
        return useApi("stores/search", {
          method: "POST",
          body: JSON.stringify({
            ...payload,
            businessIdentifier: authStore.businessId,
          }),
        });
      },
    },
    products: {
      search(payload = {}) {
        const authStore = useAuthStore();
        return useApi("products/search", {
          method: "POST",
          body: JSON.stringify({
            ...payload,
            businessId: authStore.businessId,
          }),
        });
      },
    },
    coupon: {
      get(id) {
        return useApi(`coupons/${id}`);
      },
      create(payload = {}) {
        const authStore = useAuthStore();
        return useApi("coupons", {
          method: "POST",
          body: JSON.stringify({
            ...payload,
            businessId: authStore.businessId,
          }),
        });
      },
      update(id, payload = {}) {
        const authStore = useAuthStore();
        return useApi(`coupons/${id}`, {
          method: "PUT",
          body: JSON.stringify({
            ...payload,
            businessId: authStore.businessId,
          }),
        });
      },
      delete(id) {
        const authStore = useAuthStore();
        return useApi(`coupons/${id}`, {
          method: "DELETE",
          body: JSON.stringify({
            businessId: authStore.businessId,
          }),
        });
      },
      search(payload = {}) {
        const authStore = useAuthStore();
        return useApi("coupons/list", {
          method: "POST",
          body: JSON.stringify({
            ...payload,
            businessIdentifier: authStore.businessId,
          }),
        });
      },
      activate(id, payload = {}) {
        const authStore = useAuthStore();
        return useApi(`coupons/activate/${id}`, {
          method: "PATCH",
          body: JSON.stringify({
            ...payload,
            businessId: authStore.businessId,
          }),
        });
      },
      publish(id, payload = {}) {
        const authStore = useAuthStore();
        return useApi(`coupons/publish/${id}`, {
          method: "PATCH",
          body: JSON.stringify({
            ...payload,
            businessId: authStore.businessId,
          }),
        });
      },
    },
  },
  retailersDashboard: {
    retailers: {
      list(payload = {}) {
        return useApi("onboarding-retailers", {
          method: "POST",
          body: JSON.stringify(payload),
        });
      },
      get(id) {
        return useApi(`onboarding-retailers/${id}`, {
          method: "GET",
        });
      },
      update(id, payload = {}) {
        return useApi(`onboarding-retailers/${id}`, {
          method: "POST",
          body: JSON.stringify(payload),
        });
      },
      delete(id) {
        return useApi(`onboarding-retailers/${id}`, {
          method: "DELETE",
        });
      },
      getStatus(id) {
        return useApi(`onboarding-retailers/${id}/retailer-status`, {
          method: "GET",
        });
      },
      updateStatus(id, payload = {}) {
        return useApi(`onboarding-retailers/${id}/retailer-status`, {
          method: "POST",
          body: JSON.stringify(payload),
        });
      },
    },
  },
  loyaltyRewards: {
    stores: {
      search(payload = {}) {
        const authStore = useAuthStore();
        return useApi("stores/search", {
          method: "POST",
          body: JSON.stringify({
            ...payload,
            businessIdentifier: authStore.businessId,
          }),
        });
      },
    },
    products: {
      search(payload = {}) {
        const authStore = useAuthStore();
        return useApi("products/search", {
          method: "POST",
          body: JSON.stringify({
            ...payload,
            businessId: authStore.businessId,
            activeOnly: true
          }),
        });
      },
    },
    reward: {
      getRewards(storeId) {
        return useApi(`stores/${storeId}/loyalty/tiers`);
      },
      getReward(storeId, id) {
        return useApi(`stores/${storeId}/loyalty/tiers/${id}`);
      },
      getTypes() {
        return useApi(`loyalty/rewards/types`);
      },
      getLoyaltyRewardsProgram(storeId) {
        return useApi(`stores/${storeId}/loyalty/program`);
      },
      create(storeId, payload = {}) {
        return useApi(`stores/${storeId}/loyalty/tiers`, {
          method: "POST",
          body: JSON.stringify({
            ...payload,
          }),
        });
      },
      update(storeId, id, payload = {}) {
        return useApi(`stores/${storeId}/loyalty/tiers/${id}`, {
          method: "PUT",
          body: JSON.stringify({
            ...payload,
          }),
        });
      },
      delete(storeId, id) {
        return useApi(`stores/${storeId}/loyalty/tiers/${id}`, {
          method: "DELETE",
        });
      },
      copyFromStoreToStore(storeId, payload = {}) {
        return useApi(`stores/${storeId}/loyalty/program/copy`, {
          method: "POST",
          body: JSON.stringify({
            ...payload,
          }),
        });
      },
      enableLoyaltyRewards(storeId, payload) {
        return useApi(`stores/${storeId}/loyalty/program`, {
          method: "PATCH",
          body: JSON.stringify(payload),
        });
      },
      excludeBulkItems(storeId, payload = {}) {
        return useApi(`stores/${storeId}/loyalty/program`, {
          method: "PATCH",
          body: JSON.stringify({
            ...payload,
          }),
        });
      },
    },
  },
  scheduleStore: {
    schedule: {
      get(id) {
        const authStore = useAuthStore();
        return useApi(`scheduled-updates/${id}`, {
          method: "POST",
          body: JSON.stringify({
            businessId: authStore.businessId,
          }),
          shorturl: true
        });
      },
      delete(id, storeId) {
        const authStore = useAuthStore();
        return useApi(`scheduled-updates/${id}`, {
          method: "DELETE",
          body: JSON.stringify({
            businessId: authStore.businessId,
          }),
          shorturl: true
        });
      },
      search(payload = {}) {
        const authStore = useAuthStore();
        return useApi("scheduled-updates", {
          method: "POST",
          body: JSON.stringify({
            ...payload,
            businessId: authStore.businessId,
          }),
          shorturl: true
        });
      },
      create(payload = {}) {
        const authStore = useAuthStore();
        return useApi("scheduled-updates", {
          method: "PUT",
          body: JSON.stringify({
            ...payload,
            businessId: authStore.businessId,
          }),
          shorturl: true
        });
      },
      update(id, payload = {}) {
        const authStore = useAuthStore();
        return useApi(`scheduled-updates/${id}`, {
          method: "PUT",
          body: JSON.stringify({
            ...payload,
            businessId: authStore.businessId,
          }),
          shorturl: true
        });
      },
    },
    map: {
      get(payload = {}) {
        const authStore = useAuthStore();
        const businessStore = useBusinessStore();
        const { baseApiUrl, isEmbedded } = storeToRefs(businessStore);

        let apiBaseUrl;
        if (isEmbedded.value) {
          apiBaseUrl = baseApiUrl.value;
        } else {
          apiBaseUrl = getViteApiBaseUrl();
        }

        let isStaging = false;
        if (apiBaseUrl.includes('staging')) {
          isStaging = true;
        }

        return useApi("delivery-zones", {
          method: "POST",
          body: JSON.stringify({
            ...payload,
            businessIdentifier: authStore.businessId,
          }),
          shorturl: isStaging ? false : true,
          notadmin: true
        });
      },
    },
  },
  fulfilmentConditions: {
    fulfilment: {
      get(id) {
        return useApi(`fulfilmentConditions/${id}`);
      },
      search(payload = {}) {
        const queryParams = new URLSearchParams(payload).toString();
        return useApi("fulfilmentConditions/list?" + queryParams);
      },
      delete(id, storeId) {
        const authStore = useAuthStore();
        return useApi(`fulfilmentConditions/${id}`, {
          method: "DELETE"
        });
      },
      create(payload = {}) {
        const authStore = useAuthStore();
        return useApi("fulfilmentConditions", {
          method: "POST",
          body: JSON.stringify({
            ...payload,
          }),
        });
      },
      update(id, payload = {}) {
        const authStore = useAuthStore();
        return useApi(`fulfilmentConditions/${id}`, {
          method: "PUT",
          body: JSON.stringify({
            ...payload,
          }),
        });
      },
    },
  },
};
