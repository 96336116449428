<template>
  <v-alert color="error" class="mb-4" v-if="!findTypes">The "{{ setting.name }}" field has incorrect value types</v-alert>
  <div :class="gridClasses" class="grid-flow-row-dense">
    <template v-for="type in filteredTypes" :key="type.key">
      <div
        v-if="checkVisible(type, setting)"
        :class="{ 'cm-col-span-2 cm-order-0': type.key === 'default', 'cm-order-2': type.key !== 'default' }"
      >
        <div class="cm-font-semibold mb-2" v-if="setting.metaType !== 'boolean'">
          {{ setting.name }}
          <span>({{ type.key }})</span>
        </div>

        <white-label-manager-form-text-field
          :value="setting.value[type.key]"
          :rules="[formRules.required]"
          @onValueUpdate="(value) => updateSetting(type.key, value)"
          v-if="setting.metaType === 'string' || setting.metaType === 'url'"
        />
        <white-label-manager-form-checkbox-field
          :value="setting.value[type.key]"
          :label="setting.name"
          @onValueUpdate="(value) => updateSetting(type.key, value)"
          v-else-if="setting.metaType === 'boolean'"
        />
        <white-label-manager-form-image-field
          :value="setting.value[type.key]"
          :rules="[formRules.required, formRules.maxFileSize]"
          @onValueUpdate="(value) => updateSetting(type.key, value)"
          v-else-if="setting.metaType === 'image'"
        />
        <white-label-manager-form-font-field
          :value="setting.value[type.key]"
          @onValueUpdate="(value) => updateSetting(type.key, value)"
          v-else-if="setting.metaType === 'font'"
        />
        <white-label-manager-form-color-field
          :rules="[formRules.required, formRules.color]"
          :value="setting.value[type.key]"
          @onValueUpdate="(value) => updateSetting(type.key, value)"
          v-else-if="setting.metaType === 'color'"
        />
        <white-label-manager-form-wysiwyg-field
          :value="setting.value[type.key]"
          @onValueUpdate="(value) => updateSetting(type.key, value)"
          v-else-if="setting.metaType === 'html'"
        />
      </div>
    </template>

    <div class="cm-col-span-1 cm-order-1" v-if="filteredTypes.some(platform => platform.key === 'default' && filteredTypes.some(platform => platform.key !== 'default'))">
      <div class="cm-font-semibold mb-2">Customize options</div>
      <template v-for="type in filteredTypes">
        <div class="flex items-center mb-4" v-if="type.key != 'default'">
          <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4" v-model="setting[type.key + '_show']" @change="changeType(type.key, setting)">
          <label for="default-checkbox" class="ms-2 text-sm font-medium">{{ type.label }}</label>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import WhiteLabelManagerFormTextField from "@/components/whiteLabelManager/form/fields/TextField.vue";
import WhiteLabelManagerFormCheckboxField from "@/components/whiteLabelManager/form/fields/CheckboxField.vue";
import WhiteLabelManagerFormImageField from "@/components/whiteLabelManager/form/fields/ImageField.vue";
import WhiteLabelManagerFormFontField from "@/components/whiteLabelManager/form/fields/FontField.vue";
import WhiteLabelManagerFormColorField from "@/components/whiteLabelManager/form/fields/ColorField.vue";
import WhiteLabelManagerFormWysiwygField from "@/components/whiteLabelManager/form/fields/WysiwygField.vue";
export default {
  props: {
    setting: Object,
    formRules: Object,
  },
  components: {
    WhiteLabelManagerFormTextField,
    WhiteLabelManagerFormImageField,
    WhiteLabelManagerFormFontField,
    WhiteLabelManagerFormColorField,
    WhiteLabelManagerFormCheckboxField,
    WhiteLabelManagerFormWysiwygField,
  },
  data() {
    return {
      types: [
        { key: "default", label: "Label" },
        { key: "android", label: "Android" },
        { key: "ios", label: "Ios" },
        { key: "web", label: "Web" },
      ],
    };
  },
  computed: {
    filteredTypes() {
      return this.types.filter((type) => type.key in this.setting.value);
    },
    findTypes() {
      return this.types.find((type) => type.key in this.setting.value);
    },
    gridClasses() {
      return {
        "cm-grid": true,
        "cm-gap-4": true,
        "cm-grid-cols-1":
          this.filteredTypes.length === 1 &&
          this.filteredTypes[0].key === "default",
        "cm-grid-cols-3":
          this.filteredTypes.length !== 1 ||
          this.filteredTypes[0].key !== "default",
      };
    },
  },
  methods: {
    updateSetting(key, value) {
      this.$emit("onSettingsUpdate", {
        ...this.setting,
        value: { ...this.setting.value, [key]: value },
      });
    },
    checkVisible(type, setting) {
      if (type.key != 'default') {
        return setting[type.key + '_show'];
      }
      return true;
    },
    changeType(type, setting) {
      if (setting[type + '_show'] == false) {
        setting.value[type] = "";
      }
    }
  },
};
</script>
