<template>
  <div>
    <simple-card
      title="Search Results"
      color="white"
      elevation="3"
      class="cm-p-4 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
      closabled
      @close="handleCancel"
    >
      <search-results-table :options="options" />
    </simple-card>

    <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">
      <simple-button
        size="large"
        class="cm-capitalize cm-text-base cm-font-semibold"
        color="#14569E"
        variant="secondary"
        text="Back"
        @click="viewSearch"
      ></simple-button>
    </bottom-navigation>
  </div>
</template>
<script>
import { ref } from "vue";
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import SearchResultsTable from "@/components/couponManager/tables/SearchResultsTable.vue";
export default {
  name: "SearchResultsView",
  components: {
    SimpleCard,
    SearchResultsTable,
    SimpleButton,
    BottomNavigation,
  },
  setup() {
    const navigationStore = useNavigationStore();
    const options = ref({});

    const handleCancel = () => {
      return navigationStore.viewDashboard();
    };

    return {
      options,
      viewSearch: navigationStore.viewSearch,
      handleCancel,
    };
  },
};
</script>
