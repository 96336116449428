<template>
  <simple-text-field
    density="compact"
    type="number"
    label="Discount Amount in £"
    placeholder="£10.00 Off..."
    tooltipText="The discount amount you would like to reduce the basket value by if a customer uses the reward"
    icon="mdi-information-outline"
    icon-color="#14569E"
    v-bind="$attrs"
  />
</template>
<script>
import SimpleTextField from "@/components/ui/SimpleTextField.vue";
export default {
  name: "LoyaltyRewardsFormDiscountValueField",
  components: {
    SimpleTextField,
  },
};
</script>
