<template>
  <div>
    <simple-card
      color="white"
      elevation="3"
      class="cm-pa-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
    >
      <template #header>
        <div class="cm-text-center cm-font-bold md:cm-hidden">{{ title }}</div>
      </template>
      <div
        class="cm-justify-between cm-hidden md:cm-flex cm-mb-4 cm-items-center"
      >
        <div class="d-flex align-center justify-center mb-3 mb-lg-0">
          <div
            :class="`mr-3 cm-font-bold`"
          >
            Latest Fulfilment Conditions
          </div>
        </div>
        <div>
          <search-results-table-search />
        </div>
      </div>
      <search-results-table
        :options="latestTableOptions"
      />
    </simple-card>
  </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import SearchResultsTable from "@/components/fulfilmentConditions/tables/SearchResultsTable.vue";
import SearchResultsTableSearch from "@/components/fulfilmentConditions/tables/SearchResultsTableSearch.vue";
import SimpleCard from "@/components/ui/SimpleCard.vue";
export default {
  name: "DashboardTableCard",
  components: {
    SearchResultsTable,
    SearchResultsTableSearch,
    SimpleCard,
  },
  setup() {
    const LATEST_LABEL = "Your Latest Fulfilment Conditions";
    const tab = ref("latest");
    const latestTableOptions = ref({
      limit: 10,
    });

    const title = computed(() => {
      return LATEST_LABEL;
    });

    return {
      tab,
      title,
      latestTableOptions,
    };
  }
};
</script>
