<template>
  <v-app>
    <v-main>
      <v-container :fluid="!lgAndUp">
        <div v-if="isLoggedIn">
          <KeepAlive include="DashboardView, RetailersDetailsView">
            <component :is="componentView" />
          </KeepAlive>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { onMounted, computed, ref } from "vue";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/stores/auth.ts";
import { useRetailersNavigationStore } from "@/stores/RetailersDashboard/retailersNavigation.ts";
import { useAuthenticate } from "@/composables/useAuthenticate.js";
import { useBusinessStore } from "@/stores/business.ts";
import DashboardView from "@/components/retailersDashboard/views/DashboardView.vue";
import RetailersDetailsView from "@/components/retailersDashboard/views/RetailersDetailsView.vue";

import { useDisplay } from "vuetify";

export default {
  name: "RetailersDashboard",
  components: {
    DashboardView,
    RetailersDetailsView,
  },
  props: {
    accessToken: {
      type: String,
    },
    businessId: {
      type: String,
    },
    isSuperAdmin: {
      type: Number,
    },
    baseApiUrl: {
      type: String,
    },
  },
  setup(props) {
    const authenticate = useAuthenticate();
    const toast = useToast();
    const authStore = useAuthStore();
    const navigation = useRetailersNavigationStore();
    const businessStore = useBusinessStore();
    const { lgAndUp } = useDisplay();

    const isLoggedIn = computed(() => {
      return Boolean(authStore.isLoggedIn);
    });

    const componentView = computed(() => {
      return `${navigation.view}-view`;
    });

    onMounted(async () => {
      try {
        if (props.accessToken && props.businessId && props.baseApiUrl) {
          businessStore.baseApiUrl = props.baseApiUrl;
          businessStore.isEmbedded = true;
        }

        await authenticate.init(
          props.accessToken,
          props.businessId,
          props.isSuperAdmin
        );
      } catch (e) {
        toast.error(e.message);
      }
    });

    return {
      componentView,
      isLoggedIn,
      lgAndUp,
    };
  },
};
</script>
