<template>
  <tr>
    <td class="v-data-table__td">
      <a
        href="#"
        @click.prevent="handleClick"
        class="cm-text-base cm-underline cm-text-primary"
        >{{ storeName }}</a
      >
    </td>
  </tr>
</template>
<script>
import { toRefs, computed } from "vue";
import { useNavigationStore } from "@/stores/FulfilmentConditions/navigation.ts";
import { useFulfilmentStore } from "@/stores/FulfilmentConditions/fulfilment.ts";
import { useToast } from "vue-toastification";
export default {
  name: "SearchResultsTableRow",
  props: {
    item: Object,
  },
  setup(props) {
    const {
      id,
      storeName,
    } = toRefs(props.item);
    const navigationStore = useNavigationStore();
    const fulfilmentStore = useFulfilmentStore();
    const toast = useToast();

    const handleClick = () => {
      fulfilmentStore.fulfilment.id = id.value;
      navigationStore.viewFulfilmentForm();
    };

    return {
      storeName,
      handleClick,
    };
  },
};
</script>
