<template>
  <simple-number-field
    density="compact"
    label="Minimum Basket Spend"
    placeholder="Minimum basket value before fees"
    icon="mdi-information-outline"
    icon-color="#14569E"
    v-bind="$attrs"
    decimals="2"
  />
</template>
<script>
import SimpleNumberField from "@/components/ui/SimpleNumberField.vue";
export default {
  name: "CouponFormMinBasketAmountField",
  components: {
    SimpleNumberField,
  },
};
</script>
