import { rest } from "msw";

export default [
  rest.post("/api/onboarding-retailers", (req, res, ctx) => {
    let { status, term } = req.body;

    if (status.toLowerCase() === "show all") {
      status = "";
    }

    const allData = [
      {
        id: 1,
        name: "Cool Business Name",
        status: "incomplete",
        lastStatusChange: null,
        address: {
          address1: "London",
          address2: "London",
          address3: "",
          city: "London",
          postcode: "SW1A 1AA",
          state: "London",
          country: "GB",
        },
        contact: {
          name: "John Doe",
          email: "email@domain.com",
          phone: "07907654321",
        },
        crmLink: "test@link.com",
      },
      {
        id: 2,
        name: "Updated Business Audit Test",
        status: "complete",
        lastStatusChange: "2023-04-05 13:33:08",
        address: {
          address1: "Updated Address Line 12345",
          address2: "Updated Address Line 23456",
          address3: "",
          city: "New Town",
          postcode: "NE25 8RQ",
          state: "Testshire on Audit",
          country: "Scotland",
        },
        contact: {
          name: "Jane Doe",
          email: "samsTestEmail@domain.com",
          phone: "7896965994",
        },
        crmLink: null,
      },
      {
        id: 3,
        name: "Test The Update Again",
        status: "live",
        lastStatusChange: "2023-04-13 09:47:54",
        address: {
          address1: "Shed 26, Unit 35",
          address2: "City Quay",
          address3: "",
          city: "Dundee",
          postcode: "DD1 3JA",
          state: "",
          country: "Scotland",
        },
        contact: {
          name: "Luke Cage",
          email: "testChanges@domain.com",
          phone: "7875484773",
        },
        crmLink: null,
      },
      {
        id: 4,
        name: "New Business",
        status: "live",
        lastStatusChange: "2023-01-30 14:42:46",
        address: {
          address1: "Shed 26, Unit 35",
          address2: "City Quay",
          address3: "",
          city: "Dundee",
          postcode: "DD1 3JA",
          state: "",
          country: "Scotland",
        },
        contact: {
          name: "Lukas Cage",
          email: "testChangesAgain@domain.com",
          phone: "7984857448",
        },
        crmLink: null,
      },
    ];

    const filteredData = allData.filter(
      (item) =>
        (status === "" || item.status.toLowerCase() === status.toLowerCase()) &&
        (item.name.toLowerCase().includes(term.toLowerCase()) ||
          `${item.address.address1} ${item.address.address2} ${item.address.city} ${item.address.postcode} ${item.address.state} ${item.address.country}`
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          `${item.contact.name} ${item.contact.email} ${item.contact.phone}`
            .toLowerCase()
            .includes(term.toLowerCase()))
    );

    return res(
      ctx.json({
        data: filteredData,
        pagination: {
          perPage: 10,
          totalCount: filteredData.length,
          pageCount: Math.ceil(filteredData.length / 10),
          page: 0,
        },
      })
    );
  }),
  rest.get("/api/onboarding-retailers/:id", (req, res, ctx) => {
    return res(
      ctx.json({
        retailer: {
          details: {
            venueType: "restaurant",
            name: "Test The Update Again Again 999999",
            status: "complete",
            lastStatusChange: "2023-04-13 09:47:54",
            address: {
              address1: "Shed 26, Unit 35",
              address2: "City Quay",
              address3: "",
              city: "Dundee",
              postcode: "DD1 3JA",
              state: "",
              country: "Scotland",
              lat: 40.689247,
              lng: -74.044502,
            },
            domainName: "testTheUpdateAgainAgain999999",
            contact: {
              email: "testChanges@domain.com",
              phone: "07806968554",
            },

            crmLink: "https://testThisLink.com",
          },
          services: {
            collection: true,
            delivery: false,
            table: false,
          },
          deliveryZones: [
            {
              id: 8256,
              store_id: 2549,
              cost: 10,
              retailer_cost_exvat: null,
              min_basket_spend: 0,
              time: 25,
              coordinates: {
                radius: 168,
                center: {
                  lat: 51.890543545629896,
                  lng: -2.108873678881842,
                },
              },
              name: "A",
              active: true,
              label: "aaa",
            },
            {
              id: 8257,
              store_id: 2549,
              cost: 5,
              retailer_cost_exvat: null,
              min_basket_spend: 0,
              time: 50,
              coordinates: {
                radius: 299.0133577045359,
                center: {
                  lat: 51.89112623122348,
                  lng: -2.098831488330084,
                },
              },
              name: "ZONE Name 2",
              active: true,
              label: "bbb",
            },
            {
              id: 8260,
              store_id: 2549,
              cost: 20,
              retailer_cost_exvat: null,
              min_basket_spend: 0,
              time: 25,
              coordinates: "TESTER123",
              name: "SammyTest",
              active: true,
              label: "ST",
            },
            {
              id: 8261,
              store_id: 2549,
              cost: 20,
              retailer_cost_exvat: null,
              min_basket_spend: 0,
              time: 25,
              coordinates: "TESTER1234567890000",
              name: "SammyTest",
              active: false,
              label: "ST",
            },
          ],
          openingHours: {
            collection: [
              {
                id: 24026,
                weekday: 1,
                open: "",
                close: "20:00:00",
              },
              {
                id: 24027,
                weekday: 2,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24029,
                weekday: 4,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24030,
                weekday: 5,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24031,
                weekday: 6,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24042,
                weekday: 0,
                open: "00:00:00",
                close: "22:00:00",
              },
            ],
            delivery: [
              {
                id: 24026,
                weekday: 1,
                open: "09:00:00",
                close: "20:00:00",
              },
              {
                id: 24027,
                weekday: 2,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24029,
                weekday: 4,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24030,
                weekday: 5,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24031,
                weekday: 6,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24042,
                weekday: 0,
                open: "00:00:00",
                close: "22:00:00",
              },
            ],
            table: [
              {
                id: 24026,
                weekday: 1,
                open: "09:00:00",
                close: "20:00:00",
              },
              {
                id: 24027,
                weekday: 2,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24029,
                weekday: 4,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24030,
                weekday: 5,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24031,
                weekday: 6,
                open: "09:00:00",
                close: "23:00:00",
              },
              {
                id: 24042,
                weekday: 0,
                open: "00:00:00",
                close: "22:00:00",
              },
            ],
          },
          orderedItems: [
            {
              id: 2,
              hardwareId: 1,
              name: "Sunmi v2",
              qty: 100,
            },
            {
              id: 3,
              hardwareId: 4,
              name: "Sunmi 80mm Cloud Printer",
              qty: 12,
            },
            {
              id: 5,
              hardwareId: 4,
              name: "Sunmi 80mm Cloud Printer",
              qty: 22,
            },
          ],
        },
      })
    );
  }),
  rest.put("/api/onboarding-retailers/:id", (req, res, ctx) => {
    return res(ctx.json({ success: true }));
  }),
  rest.delete("/api/onboarding-retailers/:id", (req, res, ctx) => {
    return res(ctx.json({ success: true }));
  }),
  rest.patch("/api/onboarding-retailers/:id/status", (req, res, ctx) => {
    const { status } = req.body;

    if (!["complete", "live"].includes(status.toLowerCase())) {
      return res(
        ctx.status(400),
        ctx.json({
          error: 'Invalid status. Status can only be "complete" or "live".',
        })
      );
    }

    return res(ctx.status(200), ctx.json({ status }));
  }),
];
