<template>
  <div v-if="isDevelopment">
    <h1>Web Componenets</h1>
    <v-tabs bg-color="primary" v-model="tab" class="cm-mb-8">
      <v-tab to="/copy-products-to-menu-groups"
        >Copy Products To Menu Groups</v-tab
      >
      <v-tab to="/loyality-rewards">Loyality Rewards</v-tab>
      <v-tab to="/white-label-manager">White Label Manager</v-tab>
      <v-tab to="/coupon-manager">Coupon Manager</v-tab>
      <v-tab to="/retailers-dashboard">Retailers Dashboard</v-tab>
      <v-tab to="/schedule-store-updates">Scheduled Store Updates</v-tab>
      <v-tab to="/white-label-setup">White Label Setup</v-tab>
      <v-tab to="/fulfilment-conditions">Fulfilment Conditions</v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { worker } from "@/mocks/browser";
export default {
  setup() {
    const tab = ref(null);
    if (import.meta.env.VITE_MOCKING === "true") {
      worker.start();
    }

    const isDevelopment = computed(() => {
      return false;
    });

    return {
      tab,
      isDevelopment,
    };
  },
};
</script>
