<template>
  <simple-card
    class="cm-pa-2 cm-rounded-lg"
    elevation="3"
  >
    <template #header>
      <simple-card-title title="Fulfilment condition update for your store" class="mb-5" />
    </template>
    <simple-button
      text="Fulfilment new update"
      class="cm-capitalize cm-text-base cm-font-semibold"
      color="#14569E"
      size="large"
      prepend-icon="mdi-plus"
      @click="handleClick"
    />
  </simple-card>
</template>
<script>
import { useNavigationStore } from "@/stores/FulfilmentConditions/navigation.ts";
import { useFulfilmentStore } from "@/stores/FulfilmentConditions/fulfilment.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleCardTitle from "@/components/ui/SimpleCardTitle.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
export default {
  name: "CreateFulfilmentCard",
  components: {
    SimpleCard,
    SimpleButton,
    SimpleCardTitle,
  },
  setup() {
    const navigationStore = useNavigationStore();
    const fulfilmentStore = useFulfilmentStore();

    const handleClick = () => {
      fulfilmentStore.$reset();
      navigationStore.viewFulfilmentForm();
    };

    return {
      handleClick,
    };
  },
};
</script>
