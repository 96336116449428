<template>
  <v-card v-bind="$attrs" :class="border ? borderClass : null">
    <div
      v-if="closabled"
      class="d-flex align-center justify-center position-absolute cm--top-3 cm--right-3 bg-red cm-rounded-full cm-cursor-pointer pa-2 elevation-3"
    >
      <v-icon
        class="d-flex justify-center align-center w-100"
        color="white"
        @click="closeCard"
      >
        mdi-close
      </v-icon>
    </div>
    <v-card-item v-if="showCardHeader">
      <v-card-title v-if="title" class="text-primary text-center">{{
        title
      }}</v-card-title>
      <slot name="header" />
    </v-card-item>
    <v-card-text>
      <slot />
    </v-card-text>
    <v-card-actions v-if="showCardActions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed } from "vue";
export default {
  name: "SimpleCard",
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
    border: {
      type: Boolean,
      default: false,
    },
    closabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const showCardHeader = computed(() =>
      Boolean(context.slots.header || props.title)
    );

    const borderClass = computed(() => {
      return `cm-border-dashed cm-border-2 border-slate-300`;
    });

    const showCardActions = computed(() => Boolean(context.slots.actions));

    const closeCard = () => {
      context.emit("close");
    };

    return {
      borderClass,
      showCardHeader,
      showCardActions,
      closeCard,
    };
  },
};
</script>
