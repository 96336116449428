<template>
       <svg
        width="246"
        height="228"
        viewBox="0 0 246 228"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_0_1)">
          <circle cx="132" cy="109" r="96" fill="white" />
        </g>
        <path
          d="M100.336 204.732V204.709L100.329 204.731L100.336 204.709V204.661L100.351 204.664L100.34 204.697L100.336 204.732Z"
          fill="#97141A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M163.14 199.936C153.334 203.309 142.81 205.14 131.859 205.14C120.84 205.14 110.254 203.286 100.396 199.874C100.539 192.971 100.811 185.2 101.932 178.934L156.851 172.611C157.018 172.795 157.275 172.996 157.544 173.204C157.981 173.544 158.447 173.906 158.593 174.259C160.401 179.072 161.694 184.063 162.452 189.148C162.685 190.712 162.955 195.889 163.14 199.936Z"
          fill="#4D1B1C"
        />
        <path
          d="M129.3 182.464C129.3 182.464 129.961 185.084 129.3 184.91C128.64 184.736 125.485 183.512 125.485 183.512L118.484 182.957C118.484 182.957 122.628 182.701 117.652 182.939C113.736 183.127 105.081 181.794 101.568 181.216C101.68 180.412 101.803 179.648 101.935 178.933L156.856 172.609C159.187 174.157 159.196 176.249 159.862 177.93C152.206 182.016 129.3 182.464 129.3 182.464Z"
          fill="black"
        />
        <path
          d="M195.21 122.517C194.257 133.664 193.384 142.328 191.178 145.177C183.164 152.517 170.529 141.731 164.703 136.506C163.201 148.055 168.241 160.57 156.597 167.289C152.445 169.681 149.815 168.841 146.768 169.589C145.422 169.928 144.229 170.54 143.016 171.254C136.409 175.703 130.026 176.906 123.014 177.844C118.718 178.756 118.904 176.91 116.15 176.618C102.031 175.796 95.1154 173.676 94.969 158.482C94.8996 155.378 95.5198 152.199 94.67 149.149C94.3926 152.414 92.3809 155.158 90.3462 157.617C85.8601 163.985 79.1195 161.872 75.3625 156.514C72.4489 152.749 71.3217 140.507 72.1631 138.59C83.7374 133.447 82.2779 132.967 83.4891 127.788C84.8765 120.731 86.7106 108.322 89.0386 103.738C89.8017 101.083 96.8531 98.7046 103.558 96.9863C108.392 95.7377 112.54 94.9524 112.459 94.9684C115.291 94.899 136.386 94.0846 138.722 92.9365C143.818 92.8831 159.79 97.0599 163.265 97.8895C171.52 102.265 173.061 112.022 177.104 118.322C176.743 117.749 177.332 114.646 177.317 113.675C176.158 101.476 176.531 105.816 177.5 99.3581C182.82 102.302 189.284 104.904 194.297 100.869C194.654 108.056 195.485 115.273 195.21 122.517Z"
          fill="#E42034"
        />
        <g opacity="0.35">
          <path
            d="M95.3789 149.975L95.0321 151.064C94.9661 150.417 94.8445 149.776 94.6686 149.15C94.0443 154.63 87.0761 163.987 81.3115 161.037C84.5649 161.345 89.1343 158.54 90.179 155.473C91.4665 151.691 86.9138 142.934 86.8493 142.814C89.5547 146.152 91.497 144.308 90.6771 141.146C89.8571 137.983 91.3194 105.032 91.3194 105.032L95.3789 149.975Z"
            fill="#97141A"
          />
        </g>
        <path
          d="M89.8396 132.454C88.6707 134.384 86.8865 136.211 85.5026 138.077C85.5488 138.184 85.5937 138.29 85.6372 138.395C87.5962 143.275 83.0337 145.576 79.3939 144.749C78.2743 144.466 77.394 143.509 76.2397 143.322C74.8537 141.763 73.7292 139.99 72.91 138.071C72.3681 136.811 71.9744 135.491 71.7369 134.139C70.2108 130.262 71.1958 129.234 71.8986 128.339C72.3744 127.732 72.722 127.185 72.0713 125.852C70.029 120.734 66.7728 120.047 69.9902 118.013L76.137 122.272L79.4668 124.577L79.8511 124.843L79.867 124.854L80.2035 125.087L82.7202 126.831L83.1177 127.108L84.9109 128.351L85.2986 128.62L86.5834 129.51L87.542 130.174L87.9583 130.462L88.8781 131.099L89.114 131.263C89.6592 131.201 90.0602 131.862 89.8396 132.454Z"
          fill="#FCDCCD"
        />
        <path
          d="M76.1336 122.268L72.0872 128.394L71.8986 128.337C72.3744 127.73 72.722 127.183 72.0713 125.85C70.029 120.732 66.7728 120.045 69.9902 118.011L76.1336 122.268Z"
          fill="#E5A993"
        />
        <path
          d="M89.0528 131.154C86.9475 133.224 84.6777 134.85 82.7867 137.097L82.7811 137.089L82.768 137.07L75.0541 126.439C74.9459 126.194 74.8249 125.937 74.6913 125.665C74.1745 124.611 73.4801 123.362 72.6851 122.012C70.6984 118.637 68.0859 114.623 66.0569 111.406C65.8284 111.045 65.6083 110.694 65.3965 110.354L73.7902 110.14C74.5824 111.189 76.6593 113.985 79.0484 117.231C82.9893 122.583 87.7778 129.161 89.0528 131.154Z"
          fill="#14569E"
        />
        <path
          d="M82.7869 137.097L82.7814 137.09L82.7682 137.071L74.6915 125.666L71.771 121.542L64.7051 111.563L66.0571 111.407L72.5327 110.658L76.7185 121.45L82.7869 137.097Z"
          fill="#14569E"
        />
        <g opacity="0.35">
          <path
            d="M89.0515 131.155C86.9462 133.224 84.6764 134.85 82.7854 137.097L82.7798 137.089L82.7667 137.07L75.0528 126.439C74.9446 126.195 74.8236 125.937 74.69 125.665L71.7695 121.541C72.0635 121.718 72.3689 121.876 72.6838 122.012C72.9333 122.12 73.191 122.209 73.4545 122.277C74.8176 122.624 75.8915 122.185 76.7149 121.449C78.3603 119.982 79.0249 117.317 79.0464 117.231C82.988 122.583 87.7765 129.161 89.0515 131.155Z"
            fill="#E42034"
          />
        </g>
        <path
          d="M88.872 131.093L86.5779 129.503L72.9004 111.229L64.7051 111.562L65.3967 110.354L73.7918 110.14L88.8581 130.962L88.872 131.093Z"
          fill="#E42034"
        />
        <path
          d="M138.132 93.0609L135.867 94.5989L132.787 96.6917C126.899 92.3541 124.463 86.7449 135.113 88.418L135.151 88.4118C135.178 88.407 135.205 88.4028 135.231 88.4007C135.509 88.4361 135.804 88.4867 136.109 88.5512C137.749 89.376 138.132 93.0609 138.132 93.0609Z"
          fill="#E42034"
        />
        <path
          d="M138.813 62.0459C141.836 71.0167 133.633 75.9607 131.803 70.2461C131.015 67.4539 131.853 64.1825 134.136 62.4337L138.813 62.0459Z"
          fill="black"
        />
        <path
          d="M110.097 56.5166C103.972 53.4248 105.942 69.8133 109.819 70.0027C114.488 70.2878 115.368 57.0264 110.097 56.5166Z"
          fill="black"
        />
        <path
          d="M138.134 93.0612C137.689 94.0323 129.296 101.61 125.693 104.646C124.605 104.821 123.504 104.905 122.401 104.9C107.325 93.263 115.417 95.7236 115.627 90.8871C120.729 94.1475 128.901 94.8329 133.137 84.0508C134.017 95.4891 138.967 91.2035 138.134 93.0612Z"
          fill="#FCDCCD"
        />
        <path
          d="M133.135 84.0454C128.99 98.9806 118.964 95.2042 114.921 92.949C115.269 92.3036 115.507 91.6051 115.626 90.8818C120.727 94.1442 128.899 94.8275 133.135 84.0454Z"
          fill="#E5A993"
        />
        <path
          d="M138.136 72.5667C138.109 72.6305 138.079 72.6936 138.046 72.7533C137.413 73.943 136.003 74.3391 135.091 75.0834C134.52 75.5503 134.144 76.1531 134.274 77.1714C134.238 88.462 126.605 94.5922 120.567 93.0494C113.666 91.5975 110.37 86.5467 108.908 80.3423C108.483 78.5436 108.214 76.6484 108.054 74.7151C107.655 69.8592 107.954 64.7661 108.269 60.382C108.359 59.1208 108.452 57.918 108.528 56.7956C108.324 56.5437 111.086 57.5295 115.381 56.9287C118.818 56.7484 123.193 54.0499 124.83 54.6042C130.277 55.4311 133.253 65.0734 133.142 69.2994C133.568 68.195 134.067 67.5041 134.587 67.1344C136.733 65.6041 139.208 69.572 138.136 72.5667Z"
          fill="#FCDCCD"
        />
        <g opacity="0.5">
          <path
            d="M134.585 67.1348C134.298 69.5627 133.456 71.4697 132.134 71.2554C129.845 70.8856 128.584 66.2309 128.779 63.0698C128.974 59.9086 128.333 60.4337 127.752 59.4133C127.171 58.3929 126.828 58.4851 122.619 60.1569C118.409 61.8287 113.393 60.2957 111.667 59.602C109.941 58.9083 108.909 60.3817 108.268 60.3859C108.358 59.1247 108.45 57.9219 108.526 56.7995C108.322 56.5477 111.085 57.5334 115.379 56.9327C118.817 56.7523 123.192 54.0538 124.828 54.6081C130.276 55.435 133.251 65.0773 133.14 69.3033C133.566 68.1955 134.066 67.5045 134.585 67.1348Z"
            fill="#E5A993"
          />
        </g>
        <g opacity="0.5">
          <path
            d="M117.076 73.4943C116.803 73.81 116.51 74.136 116.419 74.5425C116.306 75.0496 116.533 75.5692 116.806 76.0111C117.042 76.3919 117.325 76.7554 117.699 77.0023C118.072 77.2493 118.552 77.3658 118.979 77.2306C120.006 76.9052 120.172 75.7232 120.132 74.7943C120.095 73.9383 119.97 72.2498 119.203 71.7199C118.826 71.459 118.464 71.7199 118.226 72.0202C117.838 72.5093 117.485 73.0205 117.076 73.4943Z"
            fill="#E5A993"
          />
        </g>
        <path
          d="M126.404 81.0766C126.144 86.0428 121.332 88.7676 117.724 85.5433C116.337 84.4639 115.486 83.0238 115.013 81.959C115.885 83.9208 127.19 80.2969 126.404 81.0766Z"
          fill="black"
        />
        <path
          d="M117.839 75.9948C117.457 75.677 117.132 75.2352 116.875 74.7628C118.015 72.9668 120.297 70.4792 118.668 68.901C118.502 68.8677 118.33 69.0204 118.399 69.2479C119.352 71.2457 116.916 72.9411 116.109 74.6504C117.083 78.1286 121.058 77.9239 117.839 75.9948Z"
          fill="black"
        />
        <path
          d="M122.579 69.6168C123.083 68.7434 124.187 68.7316 125.136 68.9377C125.553 67.1729 120.858 69.1652 122.579 69.6168Z"
          fill="black"
        />
        <path
          d="M113.84 69.7439C112.8 67.6628 108.931 69.952 112.608 69.499C113.028 69.4206 113.527 69.7134 113.84 69.7439Z"
          fill="black"
        />
        <path
          d="M112.186 70.6152C111.985 71.2305 111.941 72.5637 112.516 73.0202C112.848 73.2838 113.155 72.973 113.317 72.6844C113.659 72.0747 113.525 71.5163 113.293 70.8843C113.212 70.6637 113.081 70.3294 112.833 70.2503C112.603 70.1733 112.26 70.389 112.186 70.6152Z"
          fill="black"
        />
        <path
          d="M123.069 70.002C122.906 70.2309 122.95 70.7581 122.961 71.0217C122.967 71.3755 123.036 71.7254 123.165 72.0546C123.258 72.2669 123.41 72.5145 123.672 72.509C124.095 72.5 124.203 71.9429 124.336 71.6384C124.628 70.96 123.904 68.8282 123.069 70.002Z"
          fill="black"
        />
        <path
          d="M109.689 67.0998C112.352 66.2931 112.187 65.7381 114.569 66.6885C115.625 66.6815 114.623 65.2726 114.053 65.1825C111.997 64.2494 108.691 66.488 109.689 67.0998Z"
          fill="black"
        />
        <path
          d="M127.712 65.1711C126.633 64.5947 125.439 64.166 124.207 64.1181C123.407 64.0869 122.577 64.2208 121.872 64.6134C121.478 64.8319 121.009 65.1843 120.9 65.6491C120.831 65.9328 121.016 66.1416 121.285 66.2041C121.701 66.2984 122.083 65.9391 122.442 65.7788C123.299 65.3959 124.259 65.3099 125.188 65.3564C125.628 65.3858 126.063 65.4554 126.49 65.5645C126.868 65.6547 127.315 65.8614 127.705 65.7636C127.747 65.7585 127.787 65.7388 127.817 65.708C127.847 65.6772 127.865 65.637 127.869 65.5943C127.875 65.4979 127.796 65.2162 127.712 65.1711Z"
          fill="black"
        />
        <path
          d="M126.084 82.8879C124.789 87.0243 120.46 88.2009 117.586 85.4122C118.5 82.9253 124.547 82.5368 126.084 82.8879Z"
          fill="#E5A993"
        />
        <g opacity="0.5">
          <path
            d="M128.547 78.8689C130.219 78.8689 131.574 77.5133 131.574 75.841C131.574 74.1687 130.219 72.813 128.547 72.813C126.874 72.813 125.519 74.1687 125.519 75.841C125.519 77.5133 126.874 78.8689 128.547 78.8689Z"
            fill="#E5A993"
          />
        </g>
        <g opacity="0.5">
          <path
            d="M112.536 77.373C112.536 77.8212 112.436 78.2638 112.244 78.6688C112.052 79.0738 111.773 79.4312 111.426 79.7152C111.079 79.9992 110.674 80.2027 110.239 80.3111C109.804 80.4195 109.35 80.4301 108.911 80.342C108.486 78.5433 108.217 76.6481 108.058 74.7148C108.519 74.4633 109.037 74.3361 109.563 74.3457C110.088 74.3553 110.602 74.5013 111.053 74.7694C111.505 75.0376 111.879 75.4186 112.139 75.875C112.399 76.3315 112.536 76.8477 112.536 77.373Z"
            fill="#E5A993"
          />
        </g>
        <path
          d="M132.694 70.1832C133.666 70.1034 135.4 67.4993 136.034 66.748C138.041 64.6336 139.854 62.4554 139.332 59.5773C137.195 51.9307 127.128 45.3365 119.984 47.2941C117.972 47.9801 116.572 49.7699 115 51.2148C112.382 53.9161 109.51 53.2016 107.977 54.2907C106.03 55.5969 106.237 58.3307 108.557 59.7015C111.466 57.1196 114.973 60.2648 117.48 59.8035C119.757 59.5829 121.543 58.2524 123.832 57.7786C133.302 56.7352 125.741 65.4924 132.694 70.1832Z"
          fill="black"
        />
        <path
          d="M85.5776 143.199C85.1462 144.276 85.3314 145.845 84.4886 146.658C82.3998 147.11 80.2119 146.682 78.3383 145.701C75.5239 144.244 74.2358 141.028 72.9004 138.306L73.0627 138.369C74.0339 139.965 74.7567 142.007 76.2405 143.322C79.8325 145.109 82.5407 145.838 85.2898 143.089L85.5776 143.199Z"
          fill="#4D1B1C"
        />
        <path
          d="M90.1716 149.39C89.8595 147.774 87.4114 141.746 86.6338 142.982C86.717 144.031 87.8131 144.965 88.2029 146.093C88.9556 147.334 89.6188 151.198 90.1723 149.39H90.1716Z"
          fill="#97141A"
        />
        <path
          d="M70.7245 130.317C71.4085 132.212 73.1525 133.707 74.7015 134.895C76.3754 136.18 78.2879 137.421 80.3474 137.975C80.8608 138.114 81.8042 138.298 82.2079 137.816C82.2176 137.805 82.2231 137.791 82.2236 137.777C82.2241 137.762 82.2195 137.748 82.2106 137.737C82.2018 137.725 82.1892 137.717 82.175 137.714C82.1608 137.711 82.146 137.713 82.133 137.719C81.6141 137.977 81.0176 137.814 80.4883 137.671C79.6232 137.439 78.8012 137.047 78.0146 136.631C76.0812 135.59 74.2402 134.32 72.6794 132.772C71.9531 132.051 71.2295 131.246 70.8376 130.287C70.8098 130.217 70.6989 130.246 70.7245 130.318V130.317Z"
          fill="#E5A993"
        />
        <path
          d="M81.1356 121.564C80.7443 122.655 79.6969 123.985 78.5148 125.255C77.0539 126.826 75.3876 128.308 74.5053 129.127C74.2576 129.357 74.0724 129.536 73.9697 129.651L72.1072 128.375L71.9844 128.291C77.3404 122.039 78.7722 119.437 79.8446 119.311C81.2618 119.632 81.5213 120.48 81.1356 121.564Z"
          fill="#FCDCCD"
        />
        <path
          d="M81.4291 121.377C81.2681 122.487 80.4794 123.865 78.6925 125.206C78.6231 125.228 78.5627 125.245 78.5121 125.256C77.4376 125.502 80.2269 123.606 80.904 121.712C81.101 121.162 81.1197 120.611 80.8131 120.117C79.3778 118.578 79.6275 119.734 72.1044 128.379L72.0864 128.4C70.8926 128.991 77.0644 121.663 79.219 119.262C80.5294 118.691 81.6684 119.735 81.4291 121.377Z"
          fill="#E5A993"
        />
        <path
          d="M80.8909 136.756C83.0101 134.311 86.7706 131.235 89.1174 129.049C91.5613 122.332 79.7928 132.483 78.1355 133.749C78.0564 133.357 78.0252 133.024 77.8331 132.657C77.7471 132.605 77.7138 132.541 77.625 132.489C79.5042 129.533 82.0646 127.337 85.1481 125.504C86.8012 124.777 86.7151 121.954 84.8831 121.909C81.9439 123.842 78.8847 125.475 76.0732 127.572C75.204 128.46 75.102 129.935 74.2266 130.738L80.8909 136.756Z"
          fill="#FCDCCD"
        />
        <path
          d="M74.2988 130.799C76.7108 126.32 77.4739 126.928 80.3381 124.989C84.8138 122.353 85.3438 120.951 86.1652 123.56C86.2165 125.467 82.6273 126.492 81.7623 127.583C75.596 133.207 78.0524 132.481 77.9171 133.661C78.0191 134.573 79.3045 132.979 79.6264 132.787C83.4674 129.895 87.5414 126.775 88.5577 126.912C89.8397 127.444 89.2202 128.88 88.3496 129.491C86.8471 130.71 80.6232 136.045 82.1979 135.891C87.796 130.084 89.4776 129.692 89.7481 128.285C89.6225 124.769 86.734 127.181 85.4909 127.938C84.2221 128.82 82.9638 129.716 81.7276 130.644C81.1255 131.095 80.5338 131.559 79.9379 132.02C78.5706 133.076 78.9223 130.891 79.4148 130.236C80.0156 129.437 80.9077 128.88 81.6485 128.255C82.9194 127.183 84.3213 126.34 85.6657 125.375C87.1918 124.28 86.6369 122.131 85.1003 121.658C84.0161 121.99 83.1899 122.849 82.1223 123.418C75.1028 127.826 75.9033 127.025 74.2988 130.799Z"
          fill="#E5A993"
        />
        <path
          d="M88.854 130.96C86.5946 133.257 81.8379 136.932 82.5469 137.519C87.434 132.94 90.3017 130.638 88.854 130.96Z"
          fill="#E5A993"
        />
        <path
          d="M85.3189 137.897C85.1281 138.233 83.5208 139.754 84.4441 139.701C84.7466 139.509 86.4482 137.561 85.3189 137.897Z"
          fill="#E5A993"
        />
        <path
          d="M160.547 172.611C152.366 184.789 101.513 181.596 100.334 180.077C99.718 179.283 100.171 176.873 100.743 174.751C100.852 174.35 100.965 173.959 101.075 173.592C105.956 176.184 111.387 176.139 116.536 176.686C117.576 176.818 118.703 177.528 119.588 177.945C121.056 178.419 123.359 177.69 125.051 177.56C138.61 175.516 140.053 172.503 145.174 170.129C146.816 169.312 148.625 169.375 150.349 169.353C153.988 168.956 157.508 167.119 160.056 164.914C160.102 165.65 160.493 171.738 160.547 172.611Z"
          fill="#97141A"
        />
        <path
          d="M128.51 184.911C117.342 182.602 119.799 188.359 119.368 177.789C121.992 178.446 125.558 177.277 128.317 177.012C128.379 179.6 128.448 182.258 128.51 184.911Z"
          fill="#E42034"
        />
        <path
          d="M120.492 186.269C120.292 187.538 120.093 188.806 119.906 190.075C119.257 194.476 118.746 198.9 118.809 203.336V203.347C118.5 203.363 118.19 203.378 117.881 203.392V203.381C117.861 202.111 117.884 200.841 117.951 199.572C118.183 195.117 118.869 190.69 119.563 186.269C119.638 185.795 120.567 185.791 120.492 186.269Z"
          fill="black"
        />
        <path
          d="M176.509 128.085C177.976 124.834 172.727 129.721 172.444 130.315C171.5 131.18 167.578 137.647 170.007 136.723C172.205 131.913 173.879 129.932 176.714 127.536L176.137 127.384C176.084 127.603 175.929 128.421 176.509 128.085Z"
          fill="#97141A"
        />
        <path
          d="M175.631 131.314C180.562 116.56 176.412 110.511 177.337 103.031C177.373 102.494 177.705 101.619 176.94 101.652C175.056 104.93 177.12 111.968 177.117 114.033C177.703 119.618 176.876 124.972 175.272 130.282C174.967 130.865 174.861 132.042 175.631 131.314Z"
          fill="#97141A"
        />
        <path
          d="M193.324 101.577C189.22 104.101 183.971 102.687 179.919 100.599C180.383 98.9494 179.354 97.3331 178.254 96.1933C179.138 94.9884 180.63 95.7195 181.951 95.3609C184.44 95.03 188.877 93.3672 189.132 92.7131C189.648 92.3701 190.135 91.9849 190.588 91.5615C191.649 94.7817 192.394 98.239 193.324 101.577Z"
          fill="#14569E"
        />
        <path
          d="M173.833 86.9954C169.814 86.704 174.025 83.6934 174.952 82.8804C175.036 83.0136 175.656 85.9326 175.659 85.9416L173.833 86.9954Z"
          fill="#E5A993"
        />
        <path
          d="M165.415 123.927C165.32 128.144 164.959 132.362 164.746 136.575C164.731 136.553 164.719 136.532 164.706 136.506C163.923 140.98 164.549 144.568 164.602 148.345C164.602 151.438 164.697 155.009 163.724 158.112C163.11 158.181 162.208 158.013 161.59 157.68C153.143 153.24 149.39 166.181 142.154 166.363C130.947 167.308 123.926 147.643 130.144 141.684C136.183 134.96 144.752 136.544 149.347 133.187C151.863 131.33 151.942 127.87 153.055 125.226C154.619 121.865 157.55 117.999 161.42 119.139C165.428 120.64 165.547 121.198 165.415 123.927Z"
          fill="#14569E"
        />
        <path
          d="M186.397 94.1389C184.593 94.8326 182.72 95.3612 180.743 95.4896C180.091 93.7199 177.301 91.3232 178.618 89.4621C178.661 89.3994 178.707 89.3392 178.756 89.2817C176.014 90.0725 174.467 89.0736 174.538 86.715C173.598 86.3397 173.801 84.5084 174.555 84.0353C173.546 82.6174 173.298 81.0933 175.135 80.7541C172.3 76.7647 184.958 79.6442 186.146 79.5304C187.559 83.5448 187.95 87.8957 188.938 92.0551C188.97 92.189 189.003 92.3235 189.036 92.4574C189.513 93.07 187.528 93.6325 186.397 94.1389Z"
          fill="#FCDCCD"
        />
        <path
          d="M176.451 89.7548C176.836 89.6578 177.229 89.5949 177.625 89.5668C178.02 89.5467 178.416 89.5426 178.811 89.5273C179.152 89.5134 179.494 89.5009 179.832 89.4579C180.056 89.4302 180.429 89.4059 180.565 89.195C180.721 88.9515 180.739 88.4757 180.718 88.1933C180.699 87.9471 180.624 87.6717 180.456 87.4837C180.135 87.1223 179.617 87.0571 179.159 87.0612C178.684 87.0654 177.94 87.0799 177.563 87.4081C177.539 87.4327 177.526 87.4656 177.526 87.5C177.526 87.5343 177.539 87.5673 177.563 87.5919C177.963 88.0081 178.864 87.739 179.367 87.7417C179.587 87.7417 179.852 87.7556 180.013 87.9311C180.248 88.1912 180.116 88.6754 180.045 88.9716L180.176 88.8003C179.968 88.9051 179.695 88.9196 179.464 88.939C179.108 88.9744 178.75 88.9918 178.394 89.0341C177.742 89.1111 176.937 89.186 176.375 89.5585C176.349 89.5686 176.328 89.5887 176.317 89.6142C176.305 89.6398 176.305 89.6688 176.315 89.6948C176.325 89.7209 176.345 89.7418 176.371 89.7531C176.396 89.7643 176.425 89.7649 176.451 89.7548Z"
          fill="#E5A993"
        />
        <path
          d="M174.727 86.9306C175.389 86.8217 176.049 86.6199 176.703 86.4638C177.338 86.3119 177.965 86.1169 178.59 85.9289C179.216 85.741 179.839 85.5495 180.463 85.3462C180.763 85.2482 181.064 85.1492 181.365 85.0493C181.667 84.9481 181.985 84.8835 182.232 84.6775C182.408 84.5298 182.254 84.2613 182.055 84.2502C181.743 84.2377 181.435 84.3959 181.142 84.4937C180.821 84.6005 180.5 84.7064 180.178 84.8114C179.577 85.007 178.976 85.2036 178.375 85.401C177.775 85.5974 177.166 85.7743 176.571 85.9851C175.935 86.2106 175.271 86.3889 174.647 86.6421C174.61 86.6539 174.579 86.6796 174.561 86.7136C174.543 86.7476 174.538 86.7873 174.548 86.8246C174.559 86.8618 174.583 86.8936 174.616 86.9134C174.649 86.9331 174.689 86.9393 174.727 86.9306Z"
          fill="#E5A993"
        />
        <path
          d="M174.467 83.9472C175.124 83.8674 175.786 83.6593 176.422 83.4845C177.058 83.3096 177.706 83.1272 178.345 82.9378C178.983 82.7484 179.632 82.5611 180.279 82.3829C180.625 82.2878 180.972 82.197 181.319 82.1054C181.666 82.0138 181.989 81.8702 182.334 81.7537C182.597 81.6656 182.487 81.3056 182.222 81.3465C181.859 81.402 181.514 81.427 181.159 81.522C180.804 81.617 180.465 81.7128 180.125 81.8127C179.481 82 178.84 82.1963 178.199 82.396C177.558 82.5958 176.929 82.8074 176.298 83.0204C175.667 83.2333 175.005 83.4255 174.409 83.7141C174.302 83.7654 174.35 83.9589 174.471 83.9444L174.467 83.9472Z"
          fill="#E5A993"
        />
        <path
          d="M175.505 80.7371C177.176 80.4666 178.867 80.2737 180.55 80.0858C182.21 79.9012 183.875 79.8263 185.537 79.6848C185.845 79.6584 185.855 79.1992 185.537 79.1992C183.828 79.1992 182.11 79.3921 180.416 79.6029C178.736 79.8111 177.094 80.1433 175.445 80.52C175.299 80.5526 175.364 80.7621 175.505 80.7392V80.7371Z"
          fill="#E5A993"
        />
        <path
          d="M186.233 81.3872C185.824 83.0299 184.763 84.5449 183.735 85.942L186.233 81.3872Z"
          fill="#E5A993"
        />
        <path
          d="M107.406 109.055C108.952 108.805 110.584 108.701 112.097 109.179C113.107 109.497 113.982 110.043 114.791 110.717C115.6 111.392 116.379 112.138 117.269 112.729C118.355 113.442 119.584 113.907 120.869 114.091C121.876 114.234 122.894 114.273 123.909 114.207C125.281 114.127 126.683 113.953 127.996 113.545C128.332 113.441 128.293 112.855 127.909 112.9C125.734 113.149 123.603 113.607 121.401 113.385C120.109 113.265 118.86 112.857 117.746 112.192C115.934 111.089 114.567 109.369 112.48 108.724C110.815 108.21 108.984 108.35 107.293 108.647C107.025 108.694 107.141 109.098 107.406 109.055Z"
          fill="#97141A"
        />
        <path
          d="M92.2351 126.958C91.9625 123.665 91.4395 117.146 91.4395 117.146C99.7 111.271 109.674 121.149 110.483 129.526C111.303 137.312 105.053 142.944 97.5946 140.891C96.7074 140.85 95.5912 141.124 95.0127 141.862C95.0148 141.864 92.6153 131.521 92.2351 126.958Z"
          fill="#14569E"
        />
        <path
          d="M154.292 125.91C154.292 125.91 154.293 125.91 154.293 125.91C154.294 125.909 154.294 125.909 154.294 125.909C154.294 125.909 154.295 125.908 154.295 125.908C154.295 125.908 154.295 125.907 154.295 125.907C154.295 125.906 154.295 125.906 154.295 125.906C154.295 125.905 154.294 125.905 154.294 125.905C154.294 125.904 154.294 125.904 154.293 125.904C154.293 125.904 154.292 125.904 154.292 125.904C154.291 125.904 154.29 125.904 154.29 125.905C154.289 125.905 154.289 125.906 154.289 125.907C154.289 125.908 154.289 125.908 154.29 125.909C154.29 125.909 154.291 125.91 154.292 125.91Z"
          fill="#97141A"
        />
        <path
          d="M169.394 103.429C169.106 103.694 168.802 103.942 168.483 104.17C163.293 103.866 163.273 106.748 159.629 107.972C148.65 111.749 142.52 103.735 146.731 94.0562C153.689 95.4533 162.572 97.7112 163.896 98.0511C164.329 98.1489 168.005 101.427 169.394 103.429Z"
          fill="#14569E"
        />
        <path
          d="M128.729 50.5823C135.378 49.1803 144.15 58.7713 137.251 63.8485C136.859 64.0739 136.243 64.4444 136.697 64.9293C141.432 64.2959 141.691 56.9428 138.686 54.0508C136.918 51.6645 130.534 48.5414 128.44 50.309C128.378 50.4574 128.498 50.6302 128.729 50.5823Z"
          fill="black"
        />
        <path
          d="M107.6 57.2858C106.653 57.3621 106.629 56.1585 106.723 55.4877C106.782 55.0262 106.925 54.5794 107.145 54.1697C107.245 53.9914 107.332 53.8111 107.483 53.6688C107.608 53.5495 107.76 53.4933 107.909 53.4108C108.057 53.3282 108.026 53.091 107.862 53.0542C107.376 52.946 106.881 53.4392 106.652 53.8173C106.342 54.3258 106.19 54.9466 106.141 55.5342C106.061 56.4998 106.394 57.8886 107.648 57.6375C107.843 57.5979 107.802 57.2698 107.6 57.2858Z"
          fill="black"
        />
        <path
          d="M140.71 91.89C140.041 98.0688 133.38 102.508 128.522 103.983C113.162 108.725 103.305 93.8407 115.418 91.7589C114.116 92.1522 114.643 94.2042 115.088 95.5298C115.895 98.2762 117.82 99.9084 120.283 101.469C121.09 102.024 122.001 102.187 122.945 102.64C124.055 103.03 123.942 105.118 124.657 104.266C127.036 100.97 133.675 98.3546 135.867 94.5989C136.891 92.8466 136.947 90.846 135.149 88.4125L135.132 88.3896L135.229 88.4014C135.507 88.4368 135.802 88.4875 136.107 88.552C138.269 89.0112 140.859 90.1905 140.71 91.89Z"
          fill="#14569E"
        />
        <path
          d="M90.6875 105.119C91.1877 109.683 90.8547 114.378 91.0933 119.013C91.4513 125.637 92.4994 132.158 93.2854 138.733C94.7227 150.109 94.2364 150.478 95.1244 150.361C95.8659 142.928 93.5337 134.806 92.8914 127.192C91.1939 115.929 92.385 102.462 90.6875 105.121V105.119Z"
          fill="#97141A"
        />
        <g opacity="0.35">
          <path
            d="M191.595 144.597C184.173 153.097 170.495 141.811 164.701 136.509C164.77 136.632 164.747 136.974 164.679 137.398C164.398 125.231 166.254 120.256 166.254 120.256C166.254 120.777 166.584 131.67 171.804 131.122L176.506 128.085C170.933 144.648 185.705 146.725 191.595 144.597Z"
            fill="#97141A"
          />
        </g>
        <g opacity="0.5">
          <path
            d="M160.056 164.914L160.135 166.148C154.891 171.328 155.131 169.433 148.846 170.218C139.279 171.654 139.668 179.209 121.482 178.894C118.333 178.776 120.067 178.27 115.055 177.759C108.714 177.173 104.633 176.531 100.745 174.75C100.854 174.349 100.966 173.958 101.077 173.591C105.958 176.183 111.388 176.138 116.538 176.686C117.578 176.817 118.705 177.527 119.59 177.945C121.058 178.418 123.361 177.689 125.053 177.56C138.612 175.515 140.055 172.502 145.176 170.128C146.817 169.311 148.627 169.374 150.351 169.353C153.988 168.955 157.507 167.118 160.056 164.914Z"
            fill="#4D1B1C"
          />
        </g>
        <g opacity="0.5">
          <path
            d="M138.041 72.7529C137.408 73.9426 135.998 74.3387 135.086 75.083C134.775 73.9349 134.981 72.7245 134.418 71.6249C134.114 71.0298 133.417 70.7828 133.715 70.0655C133.958 69.4807 134.551 69.0486 135.059 68.708C135.897 68.1475 136.767 68.1981 137.307 69.1242C138.02 70.3444 138.272 71.6222 138.041 72.7529Z"
            fill="#E5A993"
          />
        </g>
        <g opacity="0.54">
          <path
            d="M79.8488 124.84L79.8648 124.851C79.1461 125.531 78.3775 126.169 77.6373 126.605C75.0707 128.127 74.3076 130.799 74.3076 130.799C74.3076 130.799 74.3818 130.041 74.5102 129.127C74.6441 128.174 74.8355 127.05 75.061 126.439C75.3863 125.554 78.9776 122.97 80.9116 121.712L81.1405 121.564C81.2459 121.495 81.3486 121.434 81.4367 121.377C81.298 122.311 80.7187 123.439 79.468 124.573L79.8488 124.84Z"
            fill="#E5A993"
          />
        </g>
        <g opacity="0.54">
          <path
            d="M79.3902 144.749C78.2706 144.466 77.3903 143.509 76.236 143.322C74.85 141.763 73.7255 139.99 72.9062 138.071C74.5337 140.001 77.8675 144.086 80.8366 143.898C84.6519 143.661 83.304 139.792 84.9293 138.779C85.1566 138.638 85.3909 138.508 85.6314 138.391C87.5917 143.275 83.0293 145.576 79.3902 144.749Z"
            fill="#E5A993"
          />
        </g>
        <g opacity="0.5">
          <path
            d="M186.404 94.1393C184.6 94.833 182.728 95.3616 180.75 95.4899C180.098 93.7203 177.308 91.3236 178.625 89.4624L180.558 89.2002C180.444 89.2272 179.111 89.5963 180.567 91.9618C181.663 93.7425 186.399 92.745 188.949 92.0527C188.98 92.1865 189.013 92.3211 189.046 92.455C189.52 93.0703 187.535 93.6329 186.404 94.1393Z"
            fill="#E5A993"
          />
        </g>
        <g opacity="0.38">
          <path
            d="M163.22 197.25C157.026 199.145 150.733 200.702 144.369 201.913C151.979 198.336 159.125 193.88 162.448 189.149C162.849 191.834 163.106 194.538 163.22 197.25Z"
            fill="black"
          />
        </g>
        <g filter="url(#filter1_d_0_1)">
          <path
            d="M6.00195 99.49C6.00195 84.5264 18.3491 72.396 33.3127 72.396C48.5177 72.396 61.064 84.7221 61.064 99.927V126.584H33.096C18.1324 126.584 6.00195 114.454 6.00195 99.49Z"
            fill="white"
          />
        </g>
        <path
          d="M53.7068 91.6919C52.9769 93.2007 51.4749 101.986 49.6541 109.835C49.2927 111.392 48.9188 112.913 48.5356 114.333C47.042 114.595 45.3401 114.854 43.5552 115.111C41.5289 115.403 39.3982 115.692 37.3412 115.981C34.93 116.319 32.6219 116.655 30.7117 116.992C30.5214 115.72 30.3253 114.393 30.1232 113.036C29.3936 108.131 28.6048 102.83 27.9329 98.3172L27.932 98.3088C27.82 97.556 27.7115 96.8251 27.6065 96.1225C31.1434 95.5236 34.8475 94.8837 38.5909 94.2375C40.3908 93.9273 42.1997 93.6156 44.0033 93.3079C47.2832 92.7479 50.5461 92.2 53.7068 91.6919Z"
          fill="#00428A"
        />
        <path
          d="M49.6536 109.835C49.2922 111.393 48.9183 112.913 48.535 114.333C43.3592 115.241 35.6873 116.114 30.7112 116.992C30.5209 115.721 30.3248 114.393 30.1227 113.036C30.8476 114.394 31.8209 115.121 33.1289 114.681C38.4716 112.884 41.9463 117.403 48.96 110.485C49.1982 110.25 49.4294 110.033 49.6536 109.835Z"
          fill="#14569E"
        />
        <path
          d="M15.0489 91.454C15.2277 91.4924 28.0662 96.3509 27.6498 96.1128C37.0117 94.5674 44.829 93.2009 53.7045 91.6875C34.9039 86.4752 37.8687 86.7647 33.5549 87.7089C27.4831 88.9384 21.0764 90.2326 15.0489 91.454Z"
          fill="#00428A"
        />
        <path
          d="M27.5971 96.1231C29.7444 95.5891 31.9046 95.1344 34.0695 94.7026C36.2343 94.2708 38.405 93.8783 40.5796 93.5066C42.7542 93.1349 44.9337 92.7942 47.1186 92.4825C49.3034 92.1709 51.4938 91.8901 53.6968 91.6863C51.5502 92.2207 49.3895 92.6761 47.2251 93.1067C45.0607 93.5374 42.8896 93.9315 40.7144 94.3027C38.5392 94.6739 36.3602 95.0141 34.1752 95.3252C31.9903 95.6362 29.8 95.9176 27.5971 96.1231Z"
          fill="#14569E"
        />
        <path
          d="M43.9915 92.8057C43.9926 92.969 43.9935 93.1363 43.9947 93.3054C43.9998 93.9436 44.007 94.6182 44.0152 95.3293C44.079 101.073 44.1818 108.892 43.5471 115.108C41.5208 115.401 39.3901 115.689 37.3331 115.978C38.3641 109.945 38.5942 103.015 38.6679 97.1046C38.6799 96.1554 38.6878 95.2324 38.6941 94.3432C38.762 94.3527 38.6431 94.2817 38.5822 94.2349C38.5762 94.2305 38.5703 94.2258 38.5648 94.2208C38.5447 94.204 38.5402 94.1936 38.5692 94.1975C37.3204 93.6129 36.0345 93.1114 34.7197 92.6962C34.0562 92.4868 33.3811 92.2987 32.6741 92.1219C32.3221 92.0342 31.9647 91.9493 31.5954 91.8663C32.0224 91.7582 32.4073 91.6527 32.7565 91.5504L32.9311 91.499C34.9475 90.8988 35.7618 90.441 36.8738 90.4428C37.4178 90.443 38.0334 90.5545 38.8958 90.8127C39.107 90.8757 39.3316 90.9471 39.5737 91.0274C40.6135 91.3756 41.9722 91.8944 43.8494 92.6281C43.7438 92.618 44.0981 92.8197 43.9915 92.8057Z"
          fill="#E42034"
        />
        <path
          d="M39.5794 91.0311C38.1022 91.6189 36.1913 92.391 34.7259 92.6987C34.0623 92.4893 33.3873 92.3012 32.6803 92.1244C32.6102 92.0818 32.5357 92.0471 32.458 92.0208L32.7627 91.5529L32.9373 91.5015C34.4506 91.0574 35.6929 90.6496 36.88 90.4453C37.424 90.4455 38.0396 90.557 38.902 90.8152C39.1126 90.8783 39.3372 90.9496 39.5794 91.0311Z"
          fill="#97141A"
        />
        <path
          d="M46.1807 89.4864C43.0862 90.2624 39.9846 90.7243 36.8964 91.2922C36.6498 91.3378 36.4032 91.384 36.1567 91.4308C34.1287 91.986 33.4359 92.0317 32.0205 91.9051C31.3491 91.8451 30.5182 91.7453 29.3012 91.6419C30.4391 91.1484 32.2243 90.5124 34.2476 89.956C35.6607 89.5634 37.0939 89.2472 38.5411 89.0087C41.5239 88.5307 44.4295 88.4939 46.1807 89.4864Z"
          fill="#E42034"
        />
        <g opacity="0.41">
          <path
            d="M38.5455 89.0083C38.2496 89.5873 37.6675 90.5973 36.8994 91.2948C36.6528 91.3404 36.4062 91.3866 36.1597 91.4334C34.1318 91.9886 33.4389 92.0343 32.0235 91.9077L34.2508 89.9603C35.6642 89.566 37.0979 89.2483 38.5455 89.0083Z"
            fill="#97141A"
          />
        </g>
        <path
          d="M15.0844 91.4541C16.2526 97.5689 15.7362 104.287 16.9623 110.899C19.0675 111.78 28.5755 116.1 30.7036 116.992L27.5978 96.1227L15.0844 91.4541Z"
          fill="#14569E"
        />
        <path
          d="M32.351 92.0414C31.6022 92.1913 30.859 92.3559 30.1188 92.5226C28.4151 92.9061 26.727 93.3023 25.0197 93.5633C23.7028 93.8155 22.4541 93.6606 21.9792 94.1674C21.8405 94.3162 21.7672 94.5216 21.7787 94.8112C21.6748 96.4002 23.9171 108.417 25.0646 114.607C25.0576 114.661 22.4883 113.614 21.3008 113.039C20.5948 106.543 17.9953 100.134 18.878 93.6237C19.026 93.2688 18.9525 93.1016 19.3074 92.9502C19.778 92.7455 21.0042 92.5662 24.512 92.0052C25.1996 91.9698 26.1662 91.7554 27.204 91.5656C29.1095 91.2164 31.2575 90.9515 32.351 92.0414Z"
          fill="#E42034"
        />
        <g opacity="0.41">
          <path
            d="M32.3479 92.0413C31.5991 92.1911 30.8559 92.3558 30.1157 92.5224C29.2376 92.2338 28.0445 91.8411 27.1991 91.5651C29.1064 91.2162 31.2545 90.9513 32.3479 92.0413Z"
            fill="#7E7E7E"
          />
        </g>
        <path
          d="M39.2236 83.7198L38.1367 88.144L38.3575 88.1983L39.4443 83.774L39.2236 83.7198Z"
          fill="black"
        />
        <path
          d="M35.2648 82.283C33.4322 85.0209 32.3737 89.6936 31.9961 91.4461C33.8268 92.4178 35.4584 86.5874 36.2004 85.6955C36.9322 83.9048 38.412 81.9729 37.3014 81.6309C36.7448 81.4167 35.7899 81.6721 35.2648 82.283Z"
          fill="#97141A"
        />
        <path
          d="M40.4601 84.7923C41.4546 80.5171 37.0045 81.8899 36.0351 81.6245C38.7812 83.7368 33.3709 90.749 32.7391 92.1036C35.4654 92.4443 40.1274 86.9075 40.4601 84.7923Z"
          fill="#E42034"
        />
        <path
          d="M25.8616 85.5981C25.6964 84.7355 26.741 84.4535 27.4601 84.5982C31.0103 85.1464 33.5895 90.9134 32.4415 92.0638C29.3168 90.5318 30.1232 87.4288 26.5225 86.3494C26.2235 86.1956 25.9238 85.9304 25.8616 85.5981Z"
          fill="#97141A"
        />
        <path
          d="M23.8669 87.1076C25.1755 89.5097 28.9061 92.7685 32.4371 91.8776C30.8137 90.0872 25.2143 87.4025 27.4477 84.4883C26.0427 84.4988 22.6417 85.1252 23.8669 87.1076Z"
          fill="#E42034"
        />
        <path
          d="M30.9786 102.959C31.028 103.432 30.9123 103.908 30.6511 104.306C30.3899 104.704 29.9992 104.999 29.5451 105.142L28.5119 101.152C29.6713 100.901 30.8656 101.754 30.9786 102.959Z"
          fill="white"
        />
        <path
          d="M34.9992 109.642C35.0576 111.007 33.2001 111.067 33.0437 109.847C32.9853 108.482 34.843 108.423 34.9992 109.642Z"
          fill="white"
        />
        <path
          d="M47.5032 103.733C47.5621 105.098 45.704 105.158 45.5476 103.938C45.4887 102.573 47.3462 102.512 47.5032 103.733Z"
          fill="white"
        />
        <path
          d="M36.7581 104.802C36.931 104.865 36.886 105.189 36.6327 105.132C36.4575 105.069 36.5016 104.743 36.7581 104.802Z"
          fill="#14569E"
        />
        <path
          d="M33.1247 114.681C33.297 114.744 33.2526 115.067 32.9993 115.01C32.8264 114.947 32.8705 114.621 33.1247 114.681Z"
          fill="#14569E"
        />
        <path
          d="M49.5649 95.4743C48.2865 96.9618 46.1112 95.8205 46.2927 94.1165C47.403 93.8071 48.5069 93.5011 49.6042 93.1984C49.8528 93.5276 49.984 93.9307 49.9768 94.3431C49.9697 94.7556 49.8247 95.1538 49.5649 95.4743Z"
          fill="white"
        />
        <path
          d="M27.9276 98.3138L27.9258 98.2969C27.9267 98.3025 27.9273 98.3081 27.9276 98.3138Z"
          fill="#14569E"
        />
        <g opacity="0.58">
          <path
            d="M25.0664 114.608C25.0595 114.662 22.4901 113.616 21.3026 113.041C20.5966 106.544 17.9972 100.136 18.8799 93.6253C19.0278 93.2704 18.9543 93.1032 19.3093 92.9518C19.6664 93.3881 20.2703 93.7482 21.263 93.8974C21.5193 93.9382 21.7645 94.0316 21.9831 94.1717C21.8444 94.3204 21.771 94.5258 21.7825 94.8155C21.6766 96.4018 23.9189 108.419 25.0664 114.608Z"
            fill="#97141A"
          />
        </g>
        <g opacity="0.58">
          <path
            d="M43.9984 93.3055C44.0036 93.9438 44.0108 94.6184 44.019 95.3294L38.6721 97.1086C38.6841 96.1595 38.692 95.2364 38.6983 94.3473C38.7662 94.3568 38.6473 94.2858 38.5864 94.239C38.5804 94.2346 38.5745 94.2299 38.569 94.2249L38.5734 94.2015L43.9984 93.3055Z"
            fill="#97141A"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_0_1"
            x="18"
            y="0"
            width="228"
            height="228"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="5" />
            <feGaussianBlur stdDeviation="9" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_0_1"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_0_1"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_0_1"
            x="0.00195312"
            y="72.396"
            width="67.0625"
            height="66.188"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="6" />
            <feGaussianBlur stdDeviation="3" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_0_1"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_0_1"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
</template>