<template>
  <v-app style="z-index: 1">
    <v-main>
      <v-container class="cm-h-full md:cm-h-auto">
        <div v-if="isLoggedIn" class="cm-h-full md:cm-h-auto">
          <KeepAlive include="SearchView,SearchResultsView">
            <component :is="componentView" />
          </KeepAlive>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { onMounted, computed, toRefs } from "vue";
import { useToast } from "vue-toastification";
import { useAuthStore } from "@/stores/auth.ts";
import { useNavigationStore } from "@/stores/FulfilmentConditions/navigation.ts";
import { useAuthenticate } from "@/composables/useAuthenticate.js";
import { useBusinessStore } from "@/stores/business.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import DashboardView from "@/components/fulfilmentConditions/views/DashboardView.vue";
import FulfilmentFormView from "@/components/fulfilmentConditions/views/FulfilmentFormView.vue";
export default {
  name: "FulfilmentConditions",
  components: {
    SimpleCard,
    DashboardView,
    FulfilmentFormView,
  },
  props: {
    accessToken: {
      type: String,
    },
    businessId: {
      type: String,
    },
    isSuperAdmin: {
      type: Number,
    },
    baseApiUrl: {
      type: String,
    },
  },
  setup(props) {
    const authenticate = useAuthenticate();
    const toast = useToast();
    const authStore = useAuthStore();
    const navigationStore = useNavigationStore();
    const businessStore = useBusinessStore();

    const isLoggedIn = computed(() => {
      return Boolean(authStore.isLoggedIn);
    });

    const componentView = computed(() => {
      return `${navigationStore.view}-view`;
    });

    onMounted(async () => {
      try {
        if (props.accessToken && props.businessId && props.baseApiUrl) {
          businessStore.baseApiUrl = props.baseApiUrl;
          businessStore.isEmbedded = true;
        }

        await authenticate.init(
          props.accessToken,
          props.businessId,
          props.isSuperAdmin
        );
      } catch (e) {
        toast.error(e.message);
      }
    });

    return {
      componentView,
      isLoggedIn,
    };
  },
};
</script>
