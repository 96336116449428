<template>
  <div class="v-datepicker"> 
    <vue-date-picker
      position="left"
      :teleport="true"
      input-class-name="dp-custom-input"
      time-picker
      disable-time-range-validation
      id="start-date-picker"
      v-bind="$attrs"
    />
    <simple-text-field
      type="text"
      class="picker date-picker-input"
      density="compact"
      hide-details="auto"
      :clearable="false"
      v-bind="$attrs"
    />
  </div>
</template>
<script>
import SimpleTextField from "@/components/ui/SimpleTextField.vue";
export default {
  emits: ["editModelValue"],
  name: "SimpleDateInput",
  components: {
    SimpleTextField,
  },
  setup() {
  },
};
</script>
<style lang="scss">
.v-field--variant-outlined .v-field__outline__notch {
  border-width: 0 !important;
}
.v-datepicker {
  position: relative;
}
.v-datepicker .dp__main {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 12px;
}
.v-datepicker .dp__main > div,
.v-datepicker .dp__input_wrap {
  height: 100%;
}
.v-datepicker .dp__input_wrap {
  position: initial;
}
.dp-custom-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  opacity: 0;
  z-index: 1;
  padding: 9px 0;
}
.dp__clear_icon {
  display: none !important;
}
.picker .v-field__clearable {
  opacity: 1 !important;
}
.dp__action_select {
  background: #14569e !important;
}
.dp__input_icon {
  top: 22px !important;
}
.date-picker-input .v-field {
  padding-left: 30px;
}
</style>