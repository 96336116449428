import moment from "moment";

export const RULE_REQUIRED = (v) => {
  return (v !== null && v !== undefined && v !== "") || "Field is required";
};
export const RULE_VALID_DATE = (v) => {
  if (!v) {
    return;
  }
  return (
    moment(v, "YYYY-MM-DD", true).isValid() || "Not a valid date (dd-mm-yyyy)"
  );
};
export const RULE_VALID_DATE_DD_MM_YYYY = (v) => {
  if (!v) {
    return;
  }
  return (
    moment(v, "DD-MM-YYYY", true).isValid() || "Not a valid date (dd-mm-yyyy)"
  );
};
export const RULE_FUTURE_DATE_YYYY_MM = (v) => {
  if (!v) {
    return;
  }
  return moment(v).isSameOrAfter(moment(), "month") || "Must be a future date";
};
export const RULE_FUTURE_DATE = (v) => {
  if (!v) {
    return;
  }
  return moment(v).isSameOrAfter(moment(), "day") || "Must be a future date";
};
export const RULE_MIN_NUMBER = (minNum) => (value) => {
  return (
    Boolean(Number(value) >= Number(minNum)) || `Field must be min ${minNum}`
  );
};
export const RULE_MAX_NUMBER = (maxNum) => (value) => {
  return (
    Boolean(Number(value) <= Number(maxNum)) || `Field must be max ${maxNum}`
  );
};
export const RULE_VALID_COUPON_CODE = (v) =>
  /^[A-Z0-9\-]+$/.test(v) || "Invalid coupon code format";

export const RULE_QUANTITY_GREATER_THAN_ONE = (v) => {
  return v.length > 0 || "At least 1 store is required";
};

import { isPostcodeValid } from "@/utils/addressHelper.js";

/**
 * Rule Values
 */
export const NAMES_RULE_MIN_LENGTH = 2;
export const TEL_RULE_MIN_LENGTH = 10;
export const PASSWORD_RULE_MIN_LENGTH = 8;
export const PASSWORD_RULE_MAX_LENGTH = 64;
export const MIN_VALUE_FIELD = 0;

/**
 * Rules Error Messages
 */
const RULE_REQUIRED_ERROR = "Field is required";
const RULE_EMAIL_ERROR = "Email is not valid";
const RULE_CONTAINS_LOWERCASE_LETTER_ERROR =
  "Field must contain lowercase letter";
const RULE_CONTAINS_UPPERCASE_LETTER_ERROR =
  "Field must contain uppercase letter";
const RULE_CONTAINS_NUMBER_ERROR = "Field must contain number";
const RULE_MIN_LENGTH_ERROR = (length) => {
  return `Field length must be min ${length} characters`;
};
const RULE_MIN_LENGTH_ERROR_OPTION = (length) => {
  return `Option is required`;
};
const RULE_MAX_LENGTH_ERROR = (length) => {
  return `Field length must be max ${length} characters`;
};
const RULE_MUST_BE_MORE_THAN_ZERO_ERROR = () => {
  return `Field value must be positive`;
};

const RULE_MUST_BE_ZERO_OR_MORE_ERROR = () => {
  return `Field value can't be negative`;
};

const RULE_MATCH_PASSWORD_ERROR = "Field must match password";
const RULE_ONLY_NUMBERS_ERROR = "Field must contain only numbers";
const RULE_VALID_POSTCODE_ERROR = "Field must contain valid postcode";
const RULE_FILE_SIZE_ERROR = "Image size should be less than 30 MB!";
const RULE_ONLY_CHARACTERS_ERROR = "Field must contain only valid letters";
const RULE_ONLY_CHARACTERS_AND_NUMBERS_ERROR =
  "Field should not contain https:// or http:// or www.";

export const RULE_LOGIN_ERROR =
  "Login failed, please check details and try again";
/**
 * Rules
 */

export const RULE_EMAIL = (value) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value) || RULE_EMAIL_ERROR;
};
export const RULE_CONTAINS_LOWERCASE_LETTER = (value) => {
  const pattern = /[a-z]+/;
  return pattern.test(value) || RULE_CONTAINS_LOWERCASE_LETTER_ERROR;
};
export const RULE_CONTAINS_UPPERCASE_LETTER = (value) => {
  const pattern = /[A-Z]+/;
  return pattern.test(value) || RULE_CONTAINS_UPPERCASE_LETTER_ERROR;
};
export const RULE_CONTAINS_NUMBER = (value) => {
  const pattern = /[0-9]+/;
  return pattern.test(value) || RULE_CONTAINS_NUMBER_ERROR;
};
export const RULE_MIN_LENGTH = (lengthNumber) => (value) => {
  return (
    Boolean(
      value !== null && value !== undefined && value.length >= lengthNumber
    ) || RULE_MIN_LENGTH_ERROR(lengthNumber)
  );
};
export const RULE_MIN_LENGTH_OPTION = (lengthNumber) => (value) => {
  return (
    Boolean(
      value !== null && value !== undefined && value.length >= lengthNumber
    ) || RULE_MIN_LENGTH_ERROR_OPTION(lengthNumber)
  );
};
export const RULE_MAX_LENGTH = (lengthNumber) => (value) => {
  return (
    Boolean(
      value !== null && value !== undefined && value.length <= lengthNumber
    ) || RULE_MAX_LENGTH_ERROR(lengthNumber)
  );
};
export const RULE_MATCH_PASSWORD = (matchValue) => (value) => {
  return value === matchValue || RULE_MATCH_PASSWORD_ERROR;
};
export const RULE_ONLY_NUMBERS = (value) => {
  const pattern = /^[0-9]+$/;
  return pattern.test(value) || RULE_ONLY_NUMBERS_ERROR;
};
export const RULE_PHONE_CHARACTERS = (value) => {
  if (!value || !value.length) {
    return true;
  }
};
export const RULE_VALID_POSTCODE = (postcode) => {
  return isPostcodeValid(postcode) || RULE_VALID_POSTCODE_ERROR;
};

export const RULE_MAX_SIZE = (value) => {
  return (
    !value.length ||
    value.reduce((size, file) => size + file.size, 0) < 30000000 ||
    RULE_FILE_SIZE_ERROR
  );
};

export const RULE_ONLY_DOMAIN_CHARACTERS = (value) => {
  const pattern = /^[A-Za-z]+$/;
  return pattern.test(value) || RULE_ONLY_CHARACTERS_ERROR;
};

export const RULE_DOMAIN_NAME = (value) => {
  const pattern = /^(?!www\.|(?:http|ftp)s?:\/\/|[A-Za-z]:\\|\/\/).*$/i;
  return pattern.test(value) || RULE_ONLY_CHARACTERS_AND_NUMBERS_ERROR;
};

export const RULE_MUST_BE_MORE_THAN = (value) => {
  return (
    Boolean(value !== null && value !== undefined && value > MIN_VALUE_FIELD) ||
    RULE_MUST_BE_MORE_THAN_ZERO_ERROR(MIN_VALUE_FIELD)
  );
};

export const RULE_MUST_BE_MORE_THAN_ZERO = (value) => {
  return (
    Boolean(value !== null && value !== undefined && value > 0) ||
    RULE_MUST_BE_MORE_THAN_ZERO_ERROR(0)
  );
};

export const RULE_MUST_BE_ZERO_OR_MORE = (value) => {
  return (
    Boolean(value !== null && value !== undefined && value >= 0) ||
    RULE_MUST_BE_ZERO_OR_MORE_ERROR(0)
  );
};

/**
 * Rule Sets
 */
export const RULE_SET_DOMAIN_NAMES = [
  RULE_REQUIRED,
  RULE_MIN_LENGTH(NAMES_RULE_MIN_LENGTH),
  RULE_DOMAIN_NAME,
];
export const RULE_SET_NAMES = [
  RULE_MIN_LENGTH(NAMES_RULE_MIN_LENGTH),
  RULE_REQUIRED,
];
export const RULE_SET_OPTION = [
  RULE_MIN_LENGTH_OPTION(NAMES_RULE_MIN_LENGTH),
  RULE_REQUIRED,
];
export const RULE_SET_FIRST_NAME = RULE_SET_NAMES;
export const RULE_SET_LAST_NAME = RULE_SET_NAMES;
export const RULE_SET_EMAIL = [RULE_REQUIRED, RULE_EMAIL];
export const RULE_SET_TEL = [
  RULE_REQUIRED,
  RULE_PHONE_CHARACTERS,
  RULE_MIN_LENGTH(TEL_RULE_MIN_LENGTH),
];
export const RULE_SET_TEL_ADDRESS = [RULE_PHONE_CHARACTERS];
export const RULE_SET_PASSWORD = [
  RULE_REQUIRED,
  RULE_CONTAINS_LOWERCASE_LETTER,
  RULE_CONTAINS_UPPERCASE_LETTER,
  RULE_CONTAINS_NUMBER,
  RULE_MIN_LENGTH(PASSWORD_RULE_MIN_LENGTH),
  RULE_MAX_LENGTH(PASSWORD_RULE_MAX_LENGTH),
];
export const RULE_SET_CONFIRM_PASSWORD = [...RULE_SET_PASSWORD];
export const RULE_SET_POSTCODE = [RULE_REQUIRED, RULE_VALID_POSTCODE];
export const RULE_SET_ADDRESS_LINE = [
  RULE_MIN_LENGTH(NAMES_RULE_MIN_LENGTH),
  RULE_REQUIRED,
];
export const RULE_SET_TOWN = [
  RULE_MIN_LENGTH(NAMES_RULE_MIN_LENGTH),
  RULE_REQUIRED,
];
export const RULE_SET_COUNTRY = [
  RULE_MIN_LENGTH(NAMES_RULE_MIN_LENGTH),
  RULE_REQUIRED,
];
export const RULE_ACCEPT_TERMS = [RULE_REQUIRED];
export const RULE_SUBJECT = [RULE_REQUIRED];
export const RULE_MESSAGE = [RULE_REQUIRED];
export const RULE_UPLOADED_FILES = [RULE_MAX_SIZE];
export const RULE_REQUIRED_FIELD = [RULE_REQUIRED];
export const RULE_MIN_COUNT_VALUE = [RULE_MUST_BE_MORE_THAN];
export const RULE_SET_LABELS = [RULE_REQUIRED];
export const RULE_MORE_THAN_ZERO = [RULE_REQUIRED, RULE_MUST_BE_MORE_THAN_ZERO];
export const RULE_ZERO_OR_MORE = [RULE_REQUIRED, RULE_MUST_BE_ZERO_OR_MORE];
export const RULE_SET_NAMES_NOT_REQUIRED = [
  RULE_MIN_LENGTH(NAMES_RULE_MIN_LENGTH),
];
