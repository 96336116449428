<template>
  <copy-products-to-menu-groups />
</template>

<script>
import CopyProductsToMenuGroups from "@/components/copyProductsToMenuGroups/Index.vue";
export default {
  components: {
    CopyProductsToMenuGroups,
  },
};
</script>
