<template>
  <QuillEditor
    :toolbar="toolbarOptions"
    v-model:content="content"
    contentType="html"
    @update:content="updateSetting"
    :style="{ height: '150px' }"
  />
</template>
<script>
import { ref } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  components: {
    QuillEditor,
  },
  props: {
    value: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const content = ref(props.value);
    const toolbarOptions = ref([
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
    ]);

    const updateSetting = (updatedContent) => {
      if (props.value !== updatedContent) {
        emit("onValueUpdate", updatedContent);
      }
    };

    return { content, toolbarOptions, updateSetting };
  },
};
</script>
