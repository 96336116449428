<template>
  <v-autocomplete
    :loading="loading"
    density="compact"
    :items="items"
    @update:search="handleSearch"
    item-title="name"
    item-value="id"
    return-object
    hide-no-data
    append-inner-icon="mdi-magnify"
    variant="outlined"
    v-model="selected"
    v-bind="$attrs"
  />
</template>

<script>
import { ref, onMounted, watch, toRefs, nextTick } from "vue";
import debounce from "lodash.debounce";
import { useToast } from "vue-toastification";
import api from "@/utils/api.js";

export default {
  name: "MenuCategorySearch",
  props: {
    modelValue: Object,
    menuGroupId: Number,
  },
  setup(props) {
    const { modelValue } = toRefs(props);
    const items = ref([]);
    const selected = ref();
    const loading = ref(false);
    const toast = useToast();

    onMounted(() => {
      if (props.modelValue) {
        items.value = [modelValue.value];
        selected.value = modelValue.value;
      }
    });

    watch(modelValue, () => {
      nextTick(() => {
        items.value = [modelValue.value];
        selected.value = modelValue.value;
      });
    });

    const handleSearch = debounce(async (term) => {
      try {
        if (!term || term === selected.value?.name) {
          return;
        }
        loading.value = true;
        const { categories } =
          await api.copyProductsToMenuGroups.categoriesList({
            menuGroupId: props.menuGroupId,
            searchName: term,
          });
        items.value = categories;
        loading.value = false;
      } catch (e) {
        loading.value = false;
        toast.error(e, {
          timeout: 2000,
        });
      }
    }, 400);

    return {
      selected,
      items,
      loading,
      handleSearch,
    };
  },
};
</script>
