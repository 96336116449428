import { defineStore } from "pinia";
import api from "@/utils/api.js";

export const useFulfilmentStore = defineStore("fulfilment", {
  state: () => {
    return {
      mode: null, //create/edit
      fulfilment: {
        store: null,
        deliveryAdjustments: []
      },
    };
  },
  getters: {},
  actions: {
    async getFulfilment(id) {
      try {
        return await api.fulfilmentConditions.fulfilment.get(id);
      } catch {
        throw new Error("Unable to view fulfilment");
      }
    },
    async deleteFulfilment(id) {
      try {
        return await api.fulfilmentConditions.fulfilment.delete(id);
      } catch {
        throw new Error("Unable to delete fulfilments");
      }
    },
    async createFulfilment(payload) {
      try {
        return await api.fulfilmentConditions.fulfilment.create(payload);
      } catch {
        throw new Error("Unable to create fulfilments");
      }
    },
    async updateFulfilment(id, payload) {
      try {
        return await api.fulfilmentConditions.fulfilment.update(id, payload);
      } catch {
        throw new Error("Unable to update fulfilments");
      }
    },
    async searchFulfilments(payload) {
      try {
        return await api.fulfilmentConditions.fulfilment.search(payload);
      } catch {
        throw new Error("Unable to search for fulfilments");
      }
    },
  },
});
