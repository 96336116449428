<template>
  <autocomplete-input
    v-model:search="search"
    :loading="loading"
    density="compact"
    label="Store"
    placeholder="Search for a store"
    hide-no-data
    :hide-details="hideDetails"
    v-bind="$attrs"
    :items="items"
    persistent-clear
    clearable
    chips
    closable-chips
    multiple
    return-object
    :storeLength="items.length"
    :isSuperAdmin="isSuperAdmin"
    hide-selected
  />
</template>
<script>
import { ref, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import debounce from "lodash.debounce";
import { useScheduleStoreStore } from "@/stores/ScheduleStoreUpdates/store.ts";
import { useScheduleStore } from "@/stores/ScheduleStoreUpdates/schedule.ts";
import AutocompleteInput from "@/components/ui/AutocompleteInput.vue";
import { useToast } from "vue-toastification";
export default {
  name: "StoreAutocompleteInput",
  components: {
    AutocompleteInput,
  },
  props: {
    hideDetails: {
      type: Boolean,
      default: true,
    },
    isSuperAdmin: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { attrs, emit }) {
    const scheduleStoreStore = useScheduleStoreStore();
    const scheduleStore = useScheduleStore();
    const items = ref([]);
    const loading = ref(false);
    const search = ref(null);
    const toast = useToast();
    const noStoresToastShown = ref(false);
    const { schedule } = storeToRefs(scheduleStore);

    watch(search, (val) => {
      val && val !== attrs.modelValue && val.length >= 3 && searchStores(val);
    });

    watch(
      () => attrs.modelValue,
      (val) => {
        if (val && val.length) {
          items.value = val;
          search.value = null;
        }
      }
    );

    const searchStores = debounce(async (term) => {
      try {
        loading.value = true;
        items.value = await scheduleStoreStore.searchStores({ term });
        if (!items.value.length && !noStoresToastShown.value) {
          noStoresToastShown.value = true;
          toast.error("No stores found!", {
            timeout: 2000,
            onClose: () => {
              noStoresToastShown.value = false;
            },
          });
        }
        loading.value = false;
      } catch (e) {
        loading.value = false;
        noStoresToastShown.value = false;
        toast.error(e, {
          timeout: 2000,
        });
      }
    }, 400);

    return {
      items,
      loading,
      search,
    };
  },
};
</script>
