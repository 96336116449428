<template>
  <tr>
    <td class="v-data-table__td">
      <v-tooltip :text="displayStatus" location="top">
        <template v-slot:activator="{ props }">
          <div
            v-bind="props"
            class="cm-w-4 cm-h-4 cm-rounded-full"
            :class="statusColorClass"
          ></div>
        </template>
      </v-tooltip>
    </td>
    <td class="v-data-table__td">
      <a
        data-cy="cypress-table-result-link"
        href="#"
        @click.prevent="handleClick"
        class="cm-text-base cm-underline cm-text-primary"
        >{{ name }}</a
      >
    </td>
    <td class="v-data-table__td">
      <span class="cm-text-base">{{ code }}</span>
    </td>
    <td class="v-data-table__td">
      <span class="cm-text-base">{{ timesRedeemed }} times</span>
    </td>
    <td class="v-data-table__td">
      <span class="cm-text-base">£{{ totalSales }}</span>
    </td>
    <td class="v-data-table__td">
      <span class="cm-text-base">{{ newCustomerOrders }}</span>
    </td>
    <td class="v-data-table__td">
      <span class="cm-text-base">£{{ totalDiscount }}</span>
    </td>
  </tr>
</template>
<script>
import { toRefs, computed } from "vue";
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import { useToast } from "vue-toastification";
export default {
  name: "SearchResultsTableRow",
  props: {
    item: Object,
  },
  setup(props) {
    const {
      name,
      displayStatus,
      timesRedeemed,
      id,
      code,
      totalSales,
      newCustomerOrders,
      totalDiscount,
    } = toRefs(props.item);
    const navigationStore = useNavigationStore();
    const couponStore = useCouponStore();
    const toast = useToast();

    const statusColorClass = computed(() => {
      const statusColors = {
        Draft: "cm-bg-orange-500",
        Upcoming: "cm-bg-orange-500",
        Deactivated: "cm-bg-red-600",
        Expired: "cm-bg-red-600",
        Live: "cm-bg-green-600",
      };

      return statusColors[displayStatus.value] || "cm-bg-gray-500";
    });

    const handleClick = () => {
      couponStore.coupon.id = id.value;
      navigationStore.viewCouponDetails();
    };

    return {
      displayStatus,
      timesRedeemed,
      name,
      code,
      totalSales,
      newCustomerOrders,
      totalDiscount,
      statusColorClass,
      handleClick,
    };
  },
};
</script>
