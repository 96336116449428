import { rest } from "msw";

// Mocked handlers for the menu groups manager API
export default [
  rest.post("/api/menugroups/get.json", (req, res, ctx) => {
    return res(
      ctx.json([
        {
          id: 777,
          name: "Scotmid Uddingston",
          isSingleMenu: false,
          menuGroupCategory: { id: 33467, name: "Crisps & Snacks" },
        },
        {
          id: 778,
          name: "Another Uddingston",
          isSingleMenu: false,
          menuGroupCategory: { id: 33468, name: "Crisps & Snacks 2" },
        },
      ])
    );
  }),
  rest.post("/api/menugroups/move.json", (req, res, ctx) => {
    return res(
      ctx.json({
        success: true,
      })
    );
  }),
];
