<template>
  <v-chip class="rounded-lg" v-bind="$attrs">
    <span :style="{ color: textColor }">
      {{ text }}
    </span>
  </v-chip>
</template>
<script>
export default {
  name: "SimpleChip",
  props: {
    text: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
};
</script>
