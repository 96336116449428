<template>
  <v-text-field
    v-bind="$attrs"
    variant="outlined"
    :clearable="clearable"
    :name="name"
    :label="label"
    type="number"
    v-model="value"
    :value="value"
    @change="changeValue"
  >
  </v-text-field>
</template>
<script>
import { ref, watch } from "vue";

export default {
  name: "SimpleNumberField",
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    tooltipText: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    modelValue: {
      type: Number,
      default: "",
    },
    decimals: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: "",
    },
    min: {
      type: Number,
      default: "",
    }
  },
  setup(props, { emit }) {
    const value = ref(props.modelValue);

    const changeValue = (event) => {
      let newValue = parseFloat(event.target.value)
      if (newValue.toString().includes('.') && newValue.toString().split('.')[1].length > props.decimals) {
        newValue = newValue.toFixed(props.decimals);
      }
      if (newValue < 0) {
        newValue = 0;
      }
      if (newValue > parseFloat(props.max).toFixed(props.decimals)) {
        newValue = props.max;
      }
      if (newValue < props.min) {
        newValue = props.min;
      }
      value.value = newValue;
      emit("update:modelValue", newValue);
    }

    watch(
      () => props.modelValue,
      (newValue) => {
        value.value = newValue;
      }
    );
    
    return { value, changeValue }
  }
};
</script>
<style>
.v-field--variant-outlined .v-field__outline__notch {
  border-width: 0 !important;
}
</style>
