<template>
  <simple-select-input
    density="compact"
    label="Discount Type"
    :items="items"
    item-title="label"
    item-value="value"
    v-bind="$attrs"
  />
</template>
<script>
import { ref } from "vue";
import SimpleSelectInput from "@/components/ui/SimpleSelectInput.vue";
export default {
  name: "CouponFormDiscountTypeField",
  components: {
    SimpleSelectInput,
  },
  setup() {
    const items = ref([
      {
        label: "Percentage Off",
        value: "percent",
      },
      {
        label: "Set Discount",
        value: "set",
      },
      {
        label: "Free Delivery",
        value: "freedel",
      },
      {
        label: "Free Item",
        value: "freeitem",
      },
    ]);

    return {
      items,
    };
  },
};
</script>
