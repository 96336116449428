import { createApp } from "vue";
import * as VueRouter from "vue-router";
import App from "./App.vue";
import { createPinia } from "pinia";
import { createVuetify } from "vuetify";
import "vuetify/styles";
import "tailwindcss/tailwind.css";
import "@mdi/font/css/materialdesignicons.min.css";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VDataTable, VDataTableServer } from "vuetify/labs/VDataTable";
import "./assets/main.css";
import "./index.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import CopyProductsToMenuGroupsView from "./views/copyProductsToMenuGroups.vue";
import CouponManagerView from "./views/couponManager.vue";
import LoyaltyRewardsView from "./views/loyaltyRewards.vue";
import RetailersDashboardView from "./views/retailersDashboard.vue";
import WhiteLabelManagerView from "./views/whiteLabelManager.vue";
import WhiteLabelSetupView from "./views/whiteLabelSetup.vue";
import ScheduleStoreUpdatesView from "./views/ScheduleStoreUpdates.vue";
import FulfilmentConditionsView from "./views/fulfilmentConditions.vue";

const routes = [
  {
    path: "/copy-products-to-menu-groups",
    component: CopyProductsToMenuGroupsView,
  },
  {
    path: "/coupon-manager",
    component: CouponManagerView,
  },
  {
    path: "/loyality-rewards",
    component: LoyaltyRewardsView,
  },
  {
    path: "/retailers-dashboard",
    component: RetailersDashboardView,
  },
  {
    path: "/white-label-manager",
    component: WhiteLabelManagerView,
  },
  {
    path: "/white-label-setup",
    component: WhiteLabelSetupView,
  },
  {
    path: "/schedule-store-updates",
    component: ScheduleStoreUpdatesView,
  },
  {
    path: "/fulfilment-conditions",
    component: FulfilmentConditionsView,
  },
];
const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

const vuetify = createVuetify({
  components: {
    ...components,
    VDataTable,
    VDataTableServer,
    VueDatePicker,
  },
  directives,
  icons: {
    iconfont: "mdiSvg",
  },
});

vuetify.icons.iconfont = "mdiSvg";

const app = createApp(App);

app.use(router).use(vuetify).use(Toast).use(createPinia()).mount("#app");
