<template>
  <v-form
    data-cy="cypress-coupon-form-step-two"
    validate-on="input"
    ref="formRef"
    v-model="formValid"
    @submit.prevent="handleSubmit"
    class="cm-flex cm-flex-col"
  >
    <simple-card
      color="white"
      elevation="3"
      class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
      closabled
      @close="handleCancel"
    >
      <template #header>
        <div
          class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary"
        >
          Create New Coupon
        </div>
        <coupon-form-breadcrumbs />
      </template>

      <div>
        <coupon-free-items
          v-if="isDiscountTypeFreeItem"
          :edit-mode="true"
          :rules="rules.specificBarcodes"
        />

        <coupon-form-discount-value-field
          v-if="isDiscountTypeSetDiscount"
          class="mb-2"
          v-model="form.rewardRestrictions.value"
          :rules="rules.discountValue"
          data-cy="cypress-form-step-two-discount-value-field"
        />
        <coupon-form-percentage-value-field
          v-if="isDiscountTypePercent"
          class="mb-2"
          v-model="form.rewardRestrictions.value"
          :rules="rules.percentageValue"
          data-cy="cypress-form-step-two-discount-percent-field"
        />
        <coupon-form-discount-limit-field
          v-if="isDiscountTypePercent"
          class="mb-2"
          :rules="rules.discountLimit"
          v-model="form.rewardRestrictions.maxDiscount"
          data-cy="cypress-form-step-two-max-discount-field"
        />
        <coupon-form-min-basket-amount-field
          class="mb-2"
          v-model="form.rewardRestrictions.minimumSpend"
          :rules="rules.minBasketAmount"
          data-cy="cypress-form-step-two-min-basket-amount-field"
          tooltipText="The amount entered here will be the minimum value that a customer needs to have in their basket to avail of the coupon. This amount is checked before fees are applied. Set this to 0 if you would not like to have a minimum basket spend"
        />
        <coupon-form-redemptions-limit-field
          class="mb-2"
          v-model="form.rewardRestrictions.redemptions"
          :rules="rules.redemptionsLimit"
          data-cy="cypress-form-step-two-redemption-limit-field"
          tooltipText="This field allows you to set a limit on how many times the coupon can be redeemed. This is helpful to ensure that the coupon does not exceed any budget you are allocating for the coupon."
        />
        <coupon-form-coupon-code-field
          class="mb-2"
          v-model="form.code"
          :rules="rules.couponCode"
          data-cy="cypress-form-step-two-coupon-code-field"
          tooltipText="This is the code that people will need to enter in order to avail the coupon"
        />
        <coupon-form-new-customers-only-field
          v-model="form.customerRestrictions.isForNewCustomers"
          class="mb-3"
          data-cy="cypress-form-step-two-customer-only-field"
          color="#14569E"
        />
      </div>
      <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">
        <template #leftButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#14569E"
            variant="secondary"
            size="large"
            data-cy="cypress-form-step-two-back-button"
            text="Back"
            @click="handleBack"
          ></simple-button>
        </template>
        <template #rightButton>
          <simple-button
            type="submit"
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#14569E"
            size="large"
            text="Next"
            data-cy="cypress-form-step-two-next-button"
            :disabled="isDisabled"
          ></simple-button>
        </template>
      </bottom-navigation>
    </simple-card>
  </v-form>
</template>
<script>
import { ref, toRaw, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
import { useToast } from "vue-toastification";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import {
  RULE_REQUIRED,
  RULE_MIN_NUMBER,
  RULE_MAX_NUMBER,
  RULE_VALID_COUPON_CODE,
} from "@/constants/rules.js";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import CouponFormMinBasketAmountField from "@/components/couponManager/forms/couponForm/fields/CouponFormMinBasketAmountField.vue";
import CouponFormRedemptionsLimitField from "@/components/couponManager/forms/couponForm/fields/CouponFormRedemptionsLimitField.vue";
import CouponFormNewCustomersOnlyField from "@/components/couponManager/forms/couponForm/fields/CouponFormNewCustomersOnlyField.vue";
import CouponFormDiscountLimitField from "@/components/couponManager/forms/couponForm/fields/CouponFormDiscountLimitField.vue";
import CouponFormDiscountValueField from "@/components/couponManager/forms/couponForm/fields/CouponFormDiscountValueField.vue";
import CouponFormPercentageValueField from "@/components/couponManager/forms/couponForm/fields/CouponFormPercentageValueField.vue";
import CouponFormCouponCodeField from "@/components/couponManager/forms/couponForm/fields/CouponFormCouponCodeField.vue";
import CouponFormBreadcrumbs from "@/components/couponManager/forms/CouponFormBreadcrumbs.vue";
import CouponFreeItems from "@/components/couponManager/forms/CouponFreeItems.vue";
export default {
  name: "CouponFormStepTwoView",
  components: {
    SimpleCard,
    SimpleButton,
    BottomNavigation,
    CouponFormMinBasketAmountField,
    CouponFormRedemptionsLimitField,
    CouponFormNewCustomersOnlyField,
    CouponFormDiscountLimitField,
    CouponFormDiscountValueField,
    CouponFormPercentageValueField,
    CouponFormCouponCodeField,
    CouponFormBreadcrumbs,
    CouponFreeItems,
  },
  setup() {
    const navigationStore = useNavigationStore();
    const couponStore = useCouponStore();
    const form = ref({ ...couponStore.coupon });
    const formValid = ref(false);
    const formRef = ref(null);
    const editMode = ref(false);
    const rules = ref({
      minBasketAmount: [RULE_REQUIRED, RULE_MIN_NUMBER(0)],
      redemptionsLimit: [
        RULE_REQUIRED,
        RULE_MIN_NUMBER(1),
        RULE_MAX_NUMBER(500),
      ],
      couponCode: [RULE_REQUIRED, RULE_VALID_COUPON_CODE],
      percentageValue: [
        RULE_REQUIRED,
        RULE_MIN_NUMBER(1),
        RULE_MAX_NUMBER(100),
      ],
      discountLimit: [RULE_MAX_NUMBER(50)],
      discountValue: [RULE_REQUIRED, RULE_MIN_NUMBER(0)],
      specificBarcodes: [
        (v) =>
          form.value.itemRestrictions.specificBarcodes.length !== 0 ||
          "Barcode is required",
      ],
    });
    const toast = useToast();
    const { mode } = storeToRefs(useCouponStore());

    const getMonthAbbreviation = (month) => {
      const months = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];

      return months[month - 1];
    };

    onMounted(() => {
      form.value.code = couponCode.value;
      mode.value = "create";
    });

    onBeforeUnmount(() => {
      mode.value = null;
    });

    watch(form.value.rewardRestrictions, () => {
      form.value.code = couponCode.value;
    });
    const couponCode = computed(() => {
      if (isDiscountTypePercent.value || isDiscountTypeFreeItem.value) {
        if (couponStore.coupon.startDate) {
          return (
            couponStore.coupon.name.substring(0, 4).toUpperCase() +
            "-" +
            getMonthAbbreviation(
              parseInt(couponStore.coupon.startDate.substring(5, 7))
            ) +
            "-" +
            couponStore.coupon.startDate.substring(2, 4)
          );
        }
      } else if (isDiscountTypeSetDiscount.value) {
        if (couponStore.coupon.startDate) {
          return (
            (couponStore.coupon.rewardRestrictions.value
              ? couponStore.coupon.rewardRestrictions.value + "OFF-"
              : "") +
            getMonthAbbreviation(
              parseInt(couponStore.coupon.startDate.substring(5, 7))
            ) +
            "-" +
            couponStore.coupon.startDate.substring(2, 4)
          );
        }
      }
      return form.value.code;
    });

    const handleSubmit = async () => {
      try {
        const { valid } = await formRef.value.validate();
        if (!valid) {
          return;
        }

        couponStore.coupon = { ...toRaw(form.value) };
        navigationStore.viewCouponReview();
      } catch (e) {
        toast.error(e, {
          timeout: 2000,
        });
      }
    };

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    const isDiscountTypePercent = computed(() => {
      return Boolean(form.value.type === "percent");
    });

    const isDiscountTypeSetDiscount = computed(() => {
      return Boolean(form.value.type === "set");
    });

    const isDiscountTypeFreeItem = computed(() => {
      return Boolean(form.value.type === "freeitem");
    });

    const handleBack = () => {
      return navigationStore.viewCouponFormStepOne();
    };

    const handleCancel = () => {
      return navigationStore.viewDashboard();
    };

    return {
      isDisabled,
      formRef,
      formValid,
      form,
      isDiscountTypePercent,
      isDiscountTypeSetDiscount,
      isDiscountTypeFreeItem,
      rules,
      handleSubmit,
      handleBack,
      couponCode,
      editMode,
      handleCancel,
    };
  },
};
</script>
