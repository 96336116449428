<template>
  <v-form
    data-cy="cypress-loyalty-rewards-form-step-one"
    validate-on="input"
    ref="formRef"
    v-model="formValid"
    @submit.prevent="handleSubmit"
    class="cm-flex cm-flex-col"
  >
    <simple-card
      color="white"
      elevation="3"
      class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
      closabled
      @close="handleCancel"
    >
      <template #header>
        <div
          class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary"
        >
          Excluded From Rewards
        </div>
        <loyalty-rewards-form-breadcrumbs />
      </template>

      <!-- TODO: this will be added in the future -->
      <!-- <loyalty-rewards-excluded-items
          :editMode="true"
          class="cm-mb-6"
          data-cy="cypress-form-step-one-excluded-items-field"
        /> -->
      <v-row>
        <v-col cols="12" md="6" class="mb-3">
          <simple-checkbox
            v-model="form.excludeTobacco"
            border
            color="#14569E"
            label="Excludes All Tobacco Products"
          />
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <simple-checkbox
            v-model="form.excludeAlcohol"
            border
            color="#14569E"
            label="Excludes All Alcohol Products"
          />
        </v-col>
      </v-row>

      <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">
        <template #leftButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#14569E"
            variant="secondary"
            size="large"
            text="Back"
            @click="handleBack"
            data-cy="cypress-form-step-one-back-button"
          ></simple-button>
        </template>
        <template #rightButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#188D6F"
            size="large"
            text="Create"
            data-cy="cypress-form-step-one-create-button"
            type="submit"
            :disabled="isDisabled"
          ></simple-button>
        </template>
      </bottom-navigation>
    </simple-card>
  </v-form>
</template>
<script>
import { ref, toRaw, computed } from "vue";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";
import { useLoyaltyRewardsNavigationStore } from "@/stores/LoyaltyRewards/loyaltyRewardsNavigation.ts";
// TODO: this will be added in the future
// import LoyaltyRewardsExcludedItems from "@/components/loyaltyRewards/forms/LoyaltyRewardsExcludedItems.vue";
import { useLoyaltyRewardsRewardStore } from "@/stores/LoyaltyRewards/reward.ts";
import { useLoyaltyRewardsStore } from "@/stores/LoyaltyRewards/store.ts";
import { useAuthStore } from "@/stores/auth.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import SimpleCheckbox from "@/components/ui/SimpleCheckbox.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import LoyaltyRewardsFormBreadcrumbs from "@/components/loyaltyRewards/forms/LoyaltyRewardsFormBreadcrumbs.vue";
export default {
  name: "LoyaltyRewardsCreateFormStepTwoView",
  components: {
    SimpleCard,
    SimpleButton,
    SimpleCheckbox,
    BottomNavigation,
    // TODO: this will be added in the future
    // LoyaltyRewardsExcludedItems,
    LoyaltyRewardsFormBreadcrumbs,
  },
  setup() {
    const navigationLoyaltyRewardsStore = useLoyaltyRewardsNavigationStore();
    const loyaltyRewardsRewardStore = useLoyaltyRewardsRewardStore();
    const loyaltyRewardsStore = useLoyaltyRewardsStore();
    const { isSuperAdmin } = storeToRefs(useAuthStore());

    const form = ref({});
    const formValid = ref(false);
    const formRef = ref(null);
    const toast = useToast();

    const handleSubmit = async () => {
      try {
        const { valid } = await formRef.value.validate();
        if (!valid) {
          return;
        }

        loyaltyRewardsRewardStore.reward = {
          ...loyaltyRewardsRewardStore.reward,
          ...toRaw(form.value),
        };

        await loyaltyRewardsRewardStore.createReward(loyaltyRewardsStore?.store?.id, {
          ...loyaltyRewardsRewardStore.reward,
          ...toRaw(form.value),
          config: Object.fromEntries(
            Object.entries(loyaltyRewardsRewardStore?.reward?.config).filter(
              ([_, value]) => value !== null
            )
          ),
          ...(loyaltyRewardsRewardStore?.reward?.config?.itemIds
            ? {
                config: {
                  itemIds: loyaltyRewardsRewardStore?.reward?.config?.itemIds?.map(
                    (item) => item.id
                  ),
                },
              }
            : {}),
          id: undefined,
        });

        navigationLoyaltyRewardsStore.viewLoyaltyRewardsSuccess();
      } catch (e) {
        toast.error(`${e}`, {
          timeout: 2000,
        });
      }
    };

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    const handleCancel = () => {
      navigationLoyaltyRewardsStore.viewDashboard();
    };

    const handleBack = () => {
      navigationLoyaltyRewardsStore.viewLoyaltyRewardsCreateFormStepOne();
    };

    return {
      form,
      isDisabled,
      handleSubmit,
      handleCancel,
      handleBack,
      formValid,
      formRef,
      isSuperAdmin,
    };
  },
};
</script>
