<template>
  <v-form ref="editTextField" v-model="valid" lazy-validation>
    <v-list-item class="flex-wrap">
      <div class="d-flex d-sm-block flex-column">
        <strong class="mr-3">{{ textFieldName }}:</strong>
        <template v-if="editingValue">
          <v-text-field
            v-if="!isListType"
            v-model="editingValueModel"
            class="mt-5"
            variant="outlined"
            density="compact"
            append-inner-icon="mdi-check-bold"
            append-icon="mdi-close"
            color="success"
            :rules="rules"
            @keydown.enter.prevent="
              updateRequest(editingValueModel, textFieldName)
            "
            @click:append-inner="
              updateRequest(editingValueModel, textFieldName)
            "
            @click:append="
              ((editingValueModel !== ' ' || editingValueModel !== '') &&
                valid) ||
              valid === null
                ? ((editingValue = false),
                  updateRequest(editingValueModel, textFieldName))
                : (editingValue = true)
            "
          >
          </v-text-field>
          <v-select
            v-else
            v-model="editingValueModel"
            class="mt-5 cm-text-[#FD6719]"
            :items="listItems"
            :rules="rules"
            hide-details
            density="compact"
            variant="outlined"
            append-icon="mdi-check-bold"
            color="success"
            @click:append="updateRequest(editingValueModel, textFieldName)"
          >
          </v-select>
        </template>

        <template v-else>
          <span variant="text" text elevation="0">{{
            editModel === true ? "" : editModel
          }}</span>
          <slot />
          <!-- TODO: to be readded for phase 2 -->
          <!-- <v-icon
          class="ml-3"
          color="#FD6719"
          size="20"
          icon="mdi-square-edit-outline"
          @click="editAction"
        ></v-icon> -->
        </template>
      </div>
    </v-list-item>
  </v-form>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useToast } from "vue-toastification";

export default defineComponent({
  props: {
    textFieldName: {
      type: String,
      default() {
        return "";
      },
    },
    editModel: {
      type: [Boolean, String, Number],
      default() {
        return "";
      },
    },
    isListType: {
      type: Boolean,
      default() {
        return false;
      },
    },
    listItems: {
      type: Array,
      default() {
        return [];
      },
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["editModelValue", "updateModelValue"],
  setup(props, { emit }) {
    const editingValue = ref(false);
    const valid = ref(true);
    const toast = useToast();
    const editingValueModel = computed({
      get() {
        return props.editModel === true ? "" : props.editModel;
      },
      set(value) {
        emit("editModelValue", value);
      },
    });

    const updateRequest = async (value, textFieldName) => {
      if (valid.value) {
        editingValue.value = false;
        emit("updateModelValue", value);
        toast.success(textFieldName + " updated successfully");
      }
    };

    const editAction = () => {
      editingValue.value = true;
    };

    return {
      editingValueModel,
      editAction,
      editingValue,
      updateRequest,
      valid,
    };
  },
});
</script>
