<template>
  <v-form
    data-cy="cypress-coupon-form-step-one"
    validate-on="input"
    ref="formRef"
    v-model="formValid"
    @submit.prevent="handleSubmit"
    class="cm-flex cm-flex-col"
  >
    <simple-card
      color="white"
      elevation="3"
      class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
      closabled
      @close="handleCancel"
    >
      <template #header>
        <div
          class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary"
        >
          Create New Coupon
        </div>
        <coupon-form-breadcrumbs />
      </template>
      <form>
        <coupon-form-name-field
          class="mb-2"
          v-model="form.name"
          :rules="rules.name"
          data-cy="cypress-form-step-one-name-field"
        />
        <store-autocomplete-input
          v-model="form.locationRestrictions.stores"
          :rules="rules.stores"
          :hide-details="formValid === null"
          :isSuperAdmin="isSuperAdmin"
          class="mb-7"
          data-cy="cypress-form-step-one-stores-field"
        />
        <coupon-types-select-input
          class="mb-2"
          :rules="rules.type"
          v-model="form.type"
          data-cy="cypress-form-step-one-type-field"
        />
        <coupon-form-start-date-field
          class="mb-2"
          v-model="formattedStartDate"
          :rules="rules.startDate"
          data-cy="cypress-form-step-one-start-date-field"
          :min-date="startDateMinDate"
        />
        <coupon-form-end-date-field
          class="mb-2"
          v-model="formattedEndDate"
          :rules="rules.endDate"
          :min-date="formattedStartDate"
          data-cy="cypress-form-step-one-end-date-field"
        />
      </form>
      <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">
        <template #leftButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#14569E"
            variant="secondary"
            size="large"
            text="Cancel"
            @click="handleCancel"
            data-cy="cypress-form-step-one-cancel-button"
          ></simple-button>
        </template>
        <template #rightButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#14569E"
            size="large"
            text="Next"
            data-cy="cypress-form-step-one-next-button"
            type="submit"
            :disabled="isDisabled"
          ></simple-button>
        </template>
      </bottom-navigation>
    </simple-card>
  </v-form>
</template>
<script>
import { ref, toRaw, computed, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";
import { useToast } from "vue-toastification";
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import { useAuthStore } from "@/stores/auth.ts";
import {
  RULE_REQUIRED,
  RULE_VALID_DATE,
  RULE_FUTURE_DATE,
  RULE_QUANTITY_GREATER_THAN_ONE,
} from "@/constants/rules.js";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import StoreAutocompleteInput from "@/components/couponManager/forms/StoreAutocompleteInput.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import CouponTypesSelectInput from "@/components/couponManager/forms/CouponTypesSelectInput.vue";
import CouponFormBreadcrumbs from "@/components/couponManager/forms/CouponFormBreadcrumbs.vue";
import CouponFormNameField from "@/components/couponManager/forms/couponForm/fields/CouponFormNameField.vue";
import CouponFormStartDateField from "@/components/couponManager/forms/couponForm/fields/CouponFormStartDateField.vue";
import CouponFormEndDateField from "@/components/couponManager/forms/couponForm/fields/CouponFormEndDateField.vue";
export default {
  name: "CouponFormStepOneView",
  components: {
    SimpleCard,
    SimpleButton,
    BottomNavigation,
    CouponTypesSelectInput,
    StoreAutocompleteInput,
    CouponFormBreadcrumbs,
    CouponFormNameField,
    CouponFormStartDateField,
    CouponFormEndDateField,
  },
  setup() {
    const navigationStore = useNavigationStore();
    const couponStore = useCouponStore();
    const { mode } = storeToRefs(useCouponStore());
    const { isSuperAdmin } = storeToRefs(useAuthStore());

    const form = ref({ ...couponStore.coupon });
    const formValid = ref(false);
    const formRef = ref(null);
    const toast = useToast();

    const rules = ref({
      name: [RULE_REQUIRED],
      type: [RULE_REQUIRED],
      startDate: [RULE_VALID_DATE, RULE_FUTURE_DATE],
      endDate: [RULE_REQUIRED, RULE_VALID_DATE, RULE_FUTURE_DATE],
      stores: [RULE_REQUIRED, RULE_QUANTITY_GREATER_THAN_ONE],
    });

    onMounted(() => {
      mode.value = "create";
    });

    onBeforeUnmount(() => {
      mode.value = null;
    });

    const handleSubmit = async () => {
      try {
        const { valid } = await formRef.value.validate();
        if (!valid) {
          return;
        }

        couponStore.coupon = {
          ...toRaw(form.value),
        };

        navigationStore.viewCouponFormStepTwo();
      } catch (e) {
        toast.error(e, {
          timeout: 2000,
        });
      }
    };

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    const startDateMinDate = computed(() => {
      return moment().format("YYYY-MM-DD");
    });

    const formattedStartDate = computed({
      set(value) {
        form.value.startDate = moment(value).format("YYYY-MM-DD");
      },
      get() {
        return form.value.startDate;
      },
    });

    const formattedEndDate = computed({
      set(value) {
        form.value.endDate = moment(value).format("YYYY-MM-DD");
      },
      get() {
        return form.value.endDate;
      },
    });

    const handleCancel = () => {
      navigationStore.viewDashboard();
    };

    return {
      form,
      isDisabled,
      handleSubmit,
      handleCancel,
      formValid,
      formRef,
      rules,
      startDateMinDate,
      formattedStartDate,
      formattedEndDate,
      isSuperAdmin,
    };
  },
};
</script>
