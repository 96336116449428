<template>
  <v-data-table
    class="menu-groups-manager-table"
    v-model="table"
    :headers="headers"
    :items="modelValue"
    show-select
    :items-per-page="-1"
    return-object
  >
    <template v-slot:[`item.menuGroup`]="{ item }">
      {{ item.raw.name }}
    </template>
    <template v-slot:[`item.menuCategory`]="{ item }">
      <menu-category-search
        :key="item.index"
        :menuGroupId="item.value.id"
        :modelValue="item.value.menuGroupCategory"
        @update:modelValue="(value) => updateCategory(value, item.index)"
        :rules="categoryRules"
        :readonly="isRemovalMode"
        data-cy="cypress-menu-group-category"
      />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="cm-flex cm-justify-end">
        <confirm-dialog
          title="Delete Selected Menu Group"
          content="Are you sure you want to remove this menu group?"
          v-slot="scope"
          :onConfirm="(value) => deleteItem(item.index)"
        >
          <v-btn
            variant="text"
            icon="mdi mdi-trash-can-outline"
            @click="scope.showDialog"
            data-cy="cypress-menu-group-remove"
          ></v-btn>
        </confirm-dialog>
      </div>
    </template>
    <template #bottom></template>
  </v-data-table>
</template>

<script>
import { ref, computed, watch } from "vue";
import MenuCategorySearch from "@/components/copyProductsToMenuGroups/forms/MenuCategorySearch.vue";
import ConfirmDialog from "@/components/copyProductsToMenuGroups/dialogs/ConfirmDialog.vue";
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    categoryRules: {
      type: Array,
      default: () => [],
    },
    isRemovalMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    MenuCategorySearch,
    ConfirmDialog,
  },
  setup(props, { emit }) {
    const table = ref(null);

    const headers = computed(() => {
      const response = [
        {
          title: "Menu Group",
          key: "menuGroup",
        },
        {
          title: "Menu Category",
          key: "menuCategory",
          width: "400px",
        },
      ];

      if (!props.isRemovalMode) {
        response.push({
          width: "70px",
          title: "",
          key: "actions",
        });
      }

      return response;
    });

    watch(table, (items) => {
      emit("update:selected", items);
    });

    function updateCategory(value, itemIndex) {
      emit(
        "update:modelValue",
        props.modelValue.map((item, index) => {
          if (index === itemIndex) {
            return {
              ...item,
              menuGroupCategory: value,
            };
          }
          return item;
        })
      );
    }

    function deleteItem(index) {
      emit(
        "update:modelValue",
        props.modelValue.filter((item, itemIndex) => itemIndex !== index)
      );
    }

    return {
      table,
      headers,
      updateCategory,
      deleteItem,
    };
  },
};
</script>
<style>
.menu-groups-manager-table .v-input .v-input__details {
  display: none;
}

.menu-groups-manager-table .v-input.v-input--error .v-input__details {
  display: block;
}
</style>
