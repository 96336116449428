<template>
  <vue-date-picker
    position="left"
    :teleport="true"
    input-class-name="dp-custom-input"
    auto-apply
    v-bind="$attrs"
    :enable-time-picker="false"
    :format="format"
    month-picker
  />
  <simple-text-field
    type="text"
    density="compact"
    label="End Reward Period"
    class="date-picker-input"
    placeholder="End Month"
    tooltipText="Your reward will end on the last day of selected month"
    icon="mdi-information-outline"
    icon-color="#14569E"
    v-bind="$attrs"
  />
</template>
<script>
import SimpleTextField from "@/components/ui/SimpleTextField.vue";
export default {
  name: "LoyaltyRewardsFormEndDateField",
  components: {
    SimpleTextField,
  },
  setup() {
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };
    return { format };
  },
};
</script>
<style lang="scss">
.dp-custom-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 95% !important;
  opacity: 0;
  z-index: 1;
}
.dp__clear_icon {
  display: none !important;
}
.picker .v-field__clearable {
  opacity: 1 !important;
}
.dp__action_select {
  background: #14569e !important;
}
.dp__input_icon {
  top: 19px !important;
}
.date-picker-input .v-field {
  padding-left: 30px;
}
</style>
