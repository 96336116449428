<template>
  <autocomplete-input
    v-model:search="search"
    :loading="loading"
    density="compact"
    label="Store Name"
    placeholder="Search for a store"
    hide-no-data
    :hide-details="hideDetails"
    v-bind="$attrs"
    :items="items"
    persistent-clear
    clearable
    chips
    closable-chips
    @click:clear="handleClearChip"
    multiple
    return-object
    :storeLength="items?.length"
    :allStoresSelected="allStoresSelected"
    :isSuperAdmin="isSuperAdmin"
  />
</template>
<script>
import { ref, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import debounce from "lodash.debounce";
import { useLoyaltyRewardsStore } from "@/stores/LoyaltyRewards/store.ts";
import { useLoyaltyRewardsRewardStore } from "@/stores/LoyaltyRewards/reward.ts";
import AutocompleteInput from "@/components/ui/AutocompleteInput.vue";
import { useToast } from "vue-toastification";
export default {
  name: "StoreAutocompleteInput",
  components: {
    AutocompleteInput,
  },
  props: {
    hideDetails: {
      type: Boolean,
      default: true,
    },
    isSuperAdmin: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { attrs, emit }) {
    const lowayltyRewardsStore = useLoyaltyRewardsStore();
    const loyaltyRewardsReward = useLoyaltyRewardsRewardStore();
    const items = ref([]);
    const loading = ref(false);
    const search = ref(null);
    const toast = useToast();
    const noStoresToastShown = ref(false);
    const { allStoresSelected, reward } = storeToRefs(loyaltyRewardsReward);
    const mountedComplete = ref(false);

    watch(search, (val) => {
      val && val !== attrs.modelValue && val.length >= 3 && searchStores(val);
    });

    watch(
      () => attrs.modelValue,
      (val) => {
        if (val && val.length) {
          items.value = val;
          if (items.value.length > 4) {
            allStoresSelected.value = true;
          } else {
            allStoresSelected.value = false;
          }
        }
      }
    );

    onMounted(async () => {
      try {
        if (allStoresSelected.value && !props.isSuperAdmin) {
          items.value = await lowayltyRewardsStore.searchStores({ term: null });
        } else {
          items.value = reward.value?.locationRestrictions?.stores || [];
        }

        if (items.value?.length > 4) {
          allStoresSelected.value = true;
        } else {
          allStoresSelected.value = false;
        }
        emit("update:modelValue", items.value);
          mountedComplete.value = true;
      } catch (error) {
        console.error("Error in onMounted:", error);
        toast.error("Failed to load stores!", {
          timeout: 2000,
        });
      }
    });

    const handleClearChip = async (e) => {
      if (allStoresSelected.value) {
        allStoresSelected.value = false;
      }
    };

    const searchStores = debounce(async (term) => {
      try {
        loading.value = true;
        items.value = await lowayltyRewardsStore.searchStores({ term });
        if (!items.value.length && !noStoresToastShown.value) {
          noStoresToastShown.value = true;
          toast.error("No stores found!", {
            timeout: 2000,
            onClose: () => {
              noStoresToastShown.value = false;
            },
          });
        }
        loading.value = false;
      } catch (e) {
        loading.value = false;
        noStoresToastShown.value = false;
        toast.error(e, {
          timeout: 2000,
        });
      }
    }, 400);

    return {
      items,
      loading,
      search,
      handleClearChip,
      allStoresSelected,
      mountedComplete,
    };
  },
};
</script>
