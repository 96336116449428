export const useFetch = (url, options) => {
  return new Promise((resolve, reject) => {
    return fetch(url, options)
      .then(async (response) => {
        if (!response.ok) {
          try {
            const jsonResponse = await response.json();
            throw jsonResponse;
          } catch (e) {
            const textResponse = await response.text();
            throw textResponse;
          }
        }

        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};
