import { useFetch } from "@/composables/useFetch.js";

export default {
  getToken() {
    return useFetch("/auth-api", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
