import { defineStore } from "pinia";
import api from "@/utils/api.js";

export const useRetailerStore = defineStore("retailer", {
  state: () => {
    return {
      retailer: null,
      retailerId: null,
      status: {},
    };
  },
  getters: {},
  actions: {
    list(search = "", status = "", page = 1, perPage = 5) {
      const requestBody = {
        page: page === 1 ? page : page - 1,
        perPage: perPage,
      };

      if (search.trim()) {
        requestBody.term = search;
      }

      if (status.trim()) {
        requestBody.status = status;
      }

      return api.retailersDashboard.retailers.list(requestBody);
    },
    async get(id) {
      try {
        const { retailer } = await api.retailersDashboard.retailers.get(id);
        this.retailer = retailer;
      } catch (e) {
        throw new Error(e.message);
      }
    },
    async update(payload) {
      try {
        const response = await api.retailersDashboard.retailers.update(
          this.retailerId,
          payload
        );

        if (payload.crmLink) {
          this.retailer.details.crmLink = payload.crmLink;
        }

        return response;
      } catch (e) {
        throw new Error(e.message);
      }
    },
    async delete() {
      try {
        await api.retailersDashboard.retailers.delete(this.retailer.id);
      } catch (e) {
        throw new Error(e.message);
      }
    },
    async getStatus(id) {
      try {
        const response = await api.retailersDashboard.retailers.getStatus(id);

        if (response) {
          this.status = response;
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },
    async updateStatus(id, status) {
      try {
        const response = await api.retailersDashboard.retailers.updateStatus(
          id,
          { status: status }
        );

        if (response.status) {
          this.retailer.status = response.status;
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },
  },
});
