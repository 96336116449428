<template>
  <div :class="[sizeTextClass, alignTextClass, boldClass, color]">
    {{ title }}
  </div>
</template>
<script>
import { toRefs, computed } from "vue";
export default {
  name: "SimpleTestTitle",
  props: {
    title: {
      type: String,
    },
    bold: {
      type: Boolean,
      default() {
        return true;
      },
    },
    align: {
      type: String,
      default() {
        return "left";
      },
    },
    size: {
      type: String,
      default() {
        return "base"; // base, large
      },
    },
    color: {
      type: String,
      default() {
        return "cm-text-primary";
      },
    },
  },
  setup(props) {
    const { size, align, bold, color } = toRefs(props);

    const sizeTextClass = computed(() => {
      return size.value === "cm-base" ? "cm-text-base" : "cm-text-xl";
    });

    const alignTextClass = computed(() => {
      return `cm-text-${align.value}`;
    });

    const boldClass = computed(() => {
      return bold.value ? "cm-font-bold" : null;
    });

    return {
      sizeTextClass,
      alignTextClass,
      boldClass,
    };
  },
};
</script>
