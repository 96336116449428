import { defineStore } from "pinia";

export const useLoyaltyRewardsNavigationStore = defineStore(
  "loyaltyRewardsNavigationStore",
  {
    state: () => {
      return {
        view: "dashboard",
        rewardEditedSuccessfully: false,
        rewardCopiedSuccessfully: false,
      };
    },
    getters: {},
    actions: {
      viewDashboard() {
        this.view = "dashboard";
      },
      viewLoyaltyRewardsEditFormStepOne() {
        this.view = "loyalty-rewards-edit-form-step-one";
      },
      viewLoyaltyRewardsEditFormStepTwo() {
        this.view = "loyalty-rewards-edit-form-step-two";
      },
      viewLoyaltyRewardsCreateFormStepOne() {
        this.view = "loyalty-rewards-create-form-step-one";
      },
      viewLoyaltyRewardsCreateFormStepTwo() {
        this.view = "loyalty-rewards-create-form-step-two";
      },
      viewLoyaltyRewardsCopyForm() {
        this.view = "loyalty-rewards-copy-form";
      },
      viewLoyaltyRewardsSuccess() {
        this.view = "loyalty-rewards-success";
      },
    },
  }
);
