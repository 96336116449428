<template>
  <loyalty-rewards />
</template>

<script>
import LoyaltyRewards from "@/components/loyaltyRewards/Index.vue";
export default {
  components: {
    LoyaltyRewards,
  },
};
</script>
