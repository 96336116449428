<template>
  <div data-cy="cypress-dashboard-view">
    <simple-card
      color="white"
      :border="true"
      elevation="0"
      class="cm-pa-4 cm-rounded-0 cm-bg-white cm-mb-6 cm-hidden md:cm-block"
    >
      <v-row>
        <v-col cols="6">
          <create-coupon-card />
        </v-col>
        <v-col cols="6">
          <simple-card
            class="cm-pa-2 cm-rounded-lg"
            :border="true"
            elevation="0"
          >
            <template #header>
              <div class="d-flex">
                <div>
                  <simple-card-title
                    class="mb-5"
                    title="Creating coupons is now even easier"
                  />
                  <p>
                    Lets get you up and running with our brand new simplified
                    code creation wizard
                  </p>
                </div>
                <v-img
                  src="/src/assets/cloud-icon.svg"
                  width="90px"
                  max-width="90px"
                  class="cm-mt-0 ml-3 d-none d-lg-flex"
                ></v-img>
              </div>
            </template>
          </simple-card>
        </v-col>
      </v-row>
    </simple-card>

    <dashboard-table-card />

    <bottom-navigation class="cm-fixed md:cm-hidden">
      <template #leftButton>
        <simple-button
          size="large"
          color="#14569E"
          class="cm-capitalize cm-text-base cm-font-semibold"
          text="Create Coupon"
          @click="handleCreateCoupon"
          prepend-icon="mdi-plus"
        />
      </template>
      <template #rightButton>
        <simple-button
          size="large"
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#14569E"
          variant="secondary"
          text="Search"
          prepend-icon="mdi-magnify"
          @click="viewSearch()"
        />
      </template>
    </bottom-navigation>
  </div>
</template>
<script>
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleCardTitle from "@/components/ui/SimpleCardTitle.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import DashboardTableCard from "@/components/couponManager/cards/DashboardTableCard.vue";
import CreateCouponCard from "@/components/couponManager/cards/CreateCouponCard.vue";
export default {
  name: "DashboardView",
  components: {
    SimpleCard,
    SimpleCardTitle,
    DashboardTableCard,
    SimpleButton,
    BottomNavigation,
    CreateCouponCard,
  },
  setup() {
    const navigationStore = useNavigationStore();
    const couponStore = useCouponStore();

    const handleCreateCoupon = () => {
      couponStore.$reset();
      navigationStore.viewCouponFormStepOne();
    };

    return {
      ...navigationStore,
      handleCreateCoupon,
    };
  },
};
</script>
