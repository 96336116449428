import { defineStore } from "pinia";
import api from "@/utils/api.js";
import { useToast } from "vue-toastification";

const toast = useToast();

export const useScheduleStoreStore = defineStore("scheduleStoreStore", {
  actions: {
    async searchStores(payload) {
      try {
        const response = await api.couponManager.stores.search(payload);

        return response.stores;
      } catch (e) {
        toast.error(e, {
          timeout: 2000,
        });
      }
    },
  },
});
