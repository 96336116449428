<template>
  <form class="cm-flex cm-items-center cm-gap-4">
    <label>Search your schedule</label>

    <simple-text-field
      data-cy="cypress-table-search-field"
      class="cm-w-64 lg:cm-w-96"
      hide-details
      density="compact"
      v-model="query"
      placeholder="Search..."
      :prepend-inner-icon="!loading ? 'mdi-magnify' : null"
      :loading="loading"
      @keyup="handleKeyup"
      @click:clear="handleKeyup"
    />
  </form>
</template>
<script>
import { toRaw, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useSearchStore } from "@/stores/ScheduleStoreUpdates/search.ts";
import SimpleTextField from "@/components/ui/SimpleTextField.vue";
import debounce from "lodash.debounce";
export default {
  name: "SearchResultsTableSearch",
  components: {
    SimpleTextField,
  },
  setup() {
    const searchStore = useSearchStore();
    const query = ref(searchStore.query.name);
    const loading = ref(false);
    const { searchLoading } = storeToRefs(searchStore);

    const handleKeyup = debounce(() => {
      loading.value = true;
      searchStore.query.name = toRaw(query.value);
    }, 300);

    watch(searchLoading, (isSearching) => {
      if (!isSearching) {
        loading.value = false;
      }
    });

    return {
      loading,
      query,
      handleKeyup,
    };
  },
};
</script>
