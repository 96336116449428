<template>
  <v-form
    data-cy="cypress-coupon-form-step-three"
    validate-on="blur"
    ref="formRef"
    v-model="formValid"
    @submit.prevent="handleNext"
    class="cm-flex cm-flex-col"
  >
    <simple-card
      color="white"
      elevation="3"
      class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
      closabled
      @close="handleCancel"
    >
      <template #header>
        <div
          class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary"
        >
          Create New Coupon
        </div>
        <coupon-form-breadcrumbs />
      </template>
      <!-- TODO: re-enable when API is ready -->
      <!-- <coupon-excluded-items :edit-mode="true">
        <div class="cm-block md:cm-flex cm-gap-4 mb-4">
          <coupon-form-exclude-alcohol-field
            v-model="form.bulkExcludeTobacco"
            data-cy="cypress-form-step-three-exclude-alcohol-field"
          />
          <coupon-form-exclude-tabacco-field
            v-model="form.bulkExcludeAlcohol"
            data-cy="cypress-form-step-three-exclude-tobacco-field"
          />
        </div>
      </coupon-excluded-items> -->
      <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">
        <template #leftButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#14569E"
            variant="secondary"
            size="large"
            data-cy="cypress-form-step-three-back-button"
            text="Back"
            @click="handleBack"
          ></simple-button>
        </template>
        <template #rightButton>
          <simple-button
            type="submit"
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#14569E"
            data-cy="cypress-form-step-three-next-button"
            size="large"
            text="Next"
            :disabled="isDisabled"
          ></simple-button>
        </template>
      </bottom-navigation>
    </simple-card>
  </v-form>
</template>
<script>
import { ref, toRaw, computed, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import CouponFormBreadcrumbs from "@/components/couponManager/forms/CouponFormBreadcrumbs.vue";
// TODO: re-enable when API is ready
// import CouponExcludedItems from "@/components/couponManager/forms/CouponExcludedItems.vue";
// import CouponFormExcludeAlcoholField from "@/components/couponManager/forms/couponForm/fields/CouponFormExcludeAlcoholField.vue";
// import CouponFormExcludeTabaccoField from "@/components/couponManager/forms/couponForm/fields/CouponFormExcludeTabaccoField.vue";
import { useToast } from "vue-toastification";
export default {
  name: "CouponFormStepThreeView",
  components: {
    SimpleCard,
    SimpleButton,
    BottomNavigation,
    CouponFormBreadcrumbs,
    // TODO: re-enable when API is ready
    // CouponExcludedItems,
    // CouponFormExcludeAlcoholField,
    // CouponFormExcludeTabaccoField,
  },
  setup() {
    const navigationStore = useNavigationStore();
    const couponStore = useCouponStore();
    const form = ref({ ...couponStore.coupon });
    const formValid = ref(false);
    const formRef = ref(null);
    const toast = useToast();
    const { mode } = storeToRefs(useCouponStore());

    onMounted(() => {
      mode.value = "create";
    });

    onBeforeUnmount(() => {
      mode.value = null;
    });

    const handleNext = async () => {
      try {
        const { valid } = await formRef.value.validate();
        if (!valid) {
          return;
        }

        couponStore.coupon = {
          ...toRaw(form.value),
        };

        navigationStore.viewCouponReview();
      } catch (e) {
        toast.error(e, {
          timeout: 2000,
        });
      }
    };

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    const handleBack = () => {
      return navigationStore.viewCouponFormStepTwo();
    };

    const handleCancel = () => {
      return navigationStore.viewDashboard();
    };

    return {
      isDisabled,
      formRef,
      formValid,
      form,
      handleNext,
      handleBack,
      handleCancel,
    };
  },
};
</script>
