<template>
  <v-form
    validate-on="input"
    ref="formRef"
    v-model="formValid"
    @submit.prevent="handleSubmit"
    class="cm-flex cm-flex-col"
  >
    <simple-card
      color="white"
      elevation="3"
      class="cm-p-2 cm-mb-20 cm-rounded-lg cm-white cm-overflow-visible"
      closabled
      @close="handleCancel"
    >
      <template #header>
        <div
          class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary"
        >
          Fulfilment conditions update
        </div>
      </template>

      <form>

        <v-row>
          <v-col cols="12" md="12">
            <store-autocomplete-input
              v-model="form.store"
              @update:modelValue="checkState"
              :rules="rules.store"
              hide-details="auto"
              :isSuperAdmin="isSuperAdmin"
              :disabled="mode == 'edit'"
            />
          </v-col>
        </v-row>

        <v-row v-if="form.store">
          <v-col cols="12" md="12">
            <v-expansion-panels
              multiple
            >
              <v-expansion-panel v-for="(day, index) in form.deliveryAdjustments">
                <v-expansion-panel-title>{{ convertToFullDay(index) }}</v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-row v-if="day.deliveryTimeSlots" v-for="(timeslot, index2) in day.deliveryTimeSlots">
                    <v-col cols="12" md="4">
                      <simple-time-input
                        v-model="timeslot.startTimeValues"
                        @update:modelValue="newTime => checkTime(newTime, timeslot, 'startTime')"
                        label="Start time"
                        hide-details="auto"
                        :start-time="startTime"
                        :max-time="timeslot.endTimeValues"
                        :isSuperAdmin="isSuperAdmin"
                        :value="formatTime(timeslot.startTime)"
                        no-today
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <simple-time-input
                        v-model="timeslot.endTimeValues"
                        @update:modelValue="newTime => checkTime(newTime, timeslot, 'endTime')"
                        label="End time"
                        hide-details="auto"
                        :start-time="timeslot.startTimeValues"
                        :min-time="timeslot.startTimeValues"
                        :isSuperAdmin="isSuperAdmin"
                        :value="formatTime(timeslot.endTime)"
                        no-today
                        :disabled="timeslot.startTimeValues == null"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <simple-text-field
                        density="compact"
                        v-model="timeslot.fee"
                        label="Fee"
                        hide-details="auto"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <simple-checkbox
                        v-model="timeslot.isActive"
                        color="#14569E"
                        label="Active"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <simple-checkbox
                        v-model="timeslot.isOutOfHours"
                        color="#14569E"
                        label="Out of hours"
                      />
                    </v-col>

                    <v-col cols="12" md="4">
                      <simple-button
                        class="cm-capitalize cm-text-base cm-font-semibold"
                        color="#E42034"
                        text="Delete timeslot"
                        variant="secondary"
                        @click="deleteTimeSlot(index, index2)"
                      ></simple-button>
                    </v-col>

                    <v-col cols="12" md="12" v-if="index2 !== day.deliveryTimeSlots.length - 1">
                      <v-divider style="border-color:#000000;opacity:0.1;"></v-divider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="12">
                      <simple-button
                        class="cm-capitalize cm-text-base cm-font-semibold"
                        color="#14569E"
                        text="Add timeslot"
                        variant="secondary"
                        @click="addBlankTimeSlot(index)"
                      ></simple-button>
                    </v-col>
                  </v-row>

                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

      </form>

      <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">

        <template #leftButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#E42034"
            size="large"
            :text="mode == 'create' || mode == 'preview' ? `Cancel` : `Delete Update`"
            @click="mode == 'create' || mode == 'preview' ? handleCancel() : fulfilmentDelete()"
          ></simple-button>
        </template>
        <template #rightButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#14569E"
            size="large"
            :text="mode == 'create' ? `Save Update` : 'Save Changes'"
            type="submit"
            :disabled="isDisabled || mode == 'preview'"
          ></simple-button>
        </template>

      </bottom-navigation>
    </simple-card>
  </v-form>
</template>
<script>
import { ref, watch, reactive, toRaw, computed, onMounted, onBeforeUnmount, nextTick } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";
import { useToast } from "vue-toastification";

import { useNavigationStore } from "@/stores/FulfilmentConditions/navigation.ts";
import { useFulfilmentStore } from "@/stores/FulfilmentConditions/fulfilment.ts";

import {
  RULE_REQUIRED,
} from "@/constants/rules.js";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";

import SimpleTextField from "@/components/ui/SimpleTextField.vue";
import StoreAutocompleteInput from "@/components/scheduleStoreUpdates/forms/StoreAutocompleteInput.vue";
import SimpleTimeInput from "@/components/ui/SimpleTimeInput.vue";
import SimpleCheckbox from "@/components/ui/SimpleCheckbox.vue";

export default {
  name: "FulfilmentFormView",
  components: {
    SimpleCard,
    SimpleButton,
    BottomNavigation,
    SimpleTextField,
    StoreAutocompleteInput,
    SimpleTimeInput,
    SimpleCheckbox,
  },
  setup() {
    const navigationStore = useNavigationStore();
    const fulfilmentStore = useFulfilmentStore();
    const { mode } = storeToRefs(useFulfilmentStore());

    const form = ref({ ...fulfilmentStore.fulfilment });
    const formValid = ref(false);
    const formRef = ref(null);
    const toast = useToast();

    const formFields = ref({});

    const rules = ref({
      store: [RULE_REQUIRED],
    });

    onMounted(async () => {
      
      if (fulfilmentStore.fulfilment.id) {
        mode.value = "edit";

        try {
          const fulfilment = await fulfilmentStore.getFulfilment(fulfilmentStore.fulfilment.id);
          form.value = fulfilment;
          form.value.store = {
            id: form.value.storeId,
            name: form.value.storeName
          }
          addTimeValues();
        } catch (e) {
          toast.error(e.message, {
            timeout: 2000,
          });

          navigationStore.viewDashboard();
        }
      } else {
        mode.value = "create";
      }
    });

    onBeforeUnmount(() => {
      mode.value = null;
      form.value = null;
    });

    const formatTime = (value) => {
      return moment(value, 'HH:mm').format('HH:mm');
    };

    const handleSubmit = async () => {

      try {
        checkAllDays(form);

        const { valid } = await formRef.value.validate();
        if (!valid) {
          return;
        }

        formFields.value.storeId = form.value.store.id;
        formFields.value.deliveryAdjustments = form.value.deliveryAdjustments;
        
        if (mode.value == "edit") {
          await fulfilmentStore.updateFulfilment(form.value.id, formFields.value);
          toast.success("Fulfilment Conditions Updated");
        } else {
          await fulfilmentStore.createFulfilment(formFields.value);
          toast.success("Fulfilment Conditions Created");
        }

        navigationStore.viewDashboard();

      } catch (e) {
        toast.error(e.message, {
          timeout: 2000,
        });
      }
    };

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    const handleCancel = () => {
      navigationStore.viewDashboard();
    };

    const fulfilmentDelete = async () => {
      await fulfilmentStore.deleteFulfilment(fulfilmentStore.fulfilment.id);

      navigationStore.viewDashboard();

      toast.success("Fulfilment Deleted");
    };

    const checkState = async (event) => {

      const fulfilments = await fulfilmentStore.searchFulfilments({
        storeName: event.name,
        limit: 1,
        skip: 0,
      });

      if (fulfilments.fulfilmentConditions[0]) {
        mode.value = "edit";
        form.value = {
          store: {
            id: event.id,
            name: event.name
          },
          deliveryAdjustments: fulfilments.fulfilmentConditions[0].deliveryAdjustments
        }
      } else {
        form.value = {
          store: {
            id: event.id,
            name: event.name
          },
          deliveryAdjustments: {
            sat: {
              deliveryTimeSlots: []
            },
            sun: {
              deliveryTimeSlots: []
            },
            mon: {
              deliveryTimeSlots: []
            },
            tue: {
              deliveryTimeSlots: []
            },
            wed: {
              deliveryTimeSlots: []
            },
            thu: {
              deliveryTimeSlots: []
            },
            fri: {
              deliveryTimeSlots: []
            },
          }
        }
      }

      addTimeValues();
    };

    const checkTime = (newTime, timeslot, type) => {
      const formattedTime = moment(newTime).format("HH:mm");
      timeslot[`${type}Values`] = newTime;
      timeslot[type] = formattedTime;
    };

    const convertToFullDay = (abbreviatedDay) => {
      const days = {
        "sat": "Saturday",
        "sun": "Sunday",
        "mon": "Monday",
        "tue": "Tuesday",
        "wed": "Wednesday",
        "thu": "Thursday",
        "fri": "Friday"
      };

      return days[abbreviatedDay.toLowerCase()] || "Invalid day";
    };

    const startTime = ref({ hours: 0, minutes: 0 });

    const addBlankTimeSlot = (day) => {
      if (form.value.deliveryAdjustments[day]) {
        form.value.deliveryAdjustments[day].deliveryTimeSlots.push({
          startTime: "",
          endTime: "",
          fee: 0,
          isActive: false,
          isOutOfHours: false
        });
      }
    }

    const deleteTimeSlot = (day, index) => {
      if (form.value.deliveryAdjustments[day]) {
        if (index >= 0 && index < form.value.deliveryAdjustments[day].deliveryTimeSlots.length) {
          form.value.deliveryAdjustments[day].deliveryTimeSlots.splice(index, 1);
        }
      }
    };

    const checkAllDays = (form) => {
      const days = Object.keys(form.value.deliveryAdjustments);
      for (let day of days) {
        const slots = form.value.deliveryAdjustments[day].deliveryTimeSlots;
        // const activeSlots = slots.filter(slot => slot.isActive);
        if (checkOverlap(slots)) {
          throw new Error(`Overlap found on ${convertToFullDay(day)}`);
        }
        if (checkEmptySlots(slots)) {
          throw new Error(`Empty slot found on ${convertToFullDay(day)}`);
        }
        if (checkSameStartEndTimes(slots)) {
          throw new Error(`Start time and end time cannot be the same on ${convertToFullDay(day)}`);
        }
      }
    };

    const timeStringToMinutes = (timeString) => {
      const [hours, minutes] = timeString.split(':').map(Number);
      return hours * 60 + minutes;
    };

    const checkOverlap = (slots) => {
      for (let i = 0; i < slots.length; i++) {
        const slot1Start = timeStringToMinutes(slots[i].startTime);
        const slot1End = timeStringToMinutes(slots[i].endTime);
        for (let j = i + 1; j < slots.length; j++) {
          const slot2Start = timeStringToMinutes(slots[j].startTime);
          const slot2End = timeStringToMinutes(slots[j].endTime);
          if ((slot1Start < slot2End && slot1End > slot2Start)) {
            return true;
          }
        }
      }
      return false;
    };

    const checkEmptySlots = (slots) => {
      for (let slot of slots) {
        if (!slot.startTime || !slot.endTime) {
          return true;
        }
      }
      return false;
    };

    const checkSameStartEndTimes = (slots) => {
      for (let slot of slots) {
        if (slot.startTime === slot.endTime) {
          return true;
        }
      }
      return false;
    };

    const addTimeValues = () => {
      for (const day in form.value.deliveryAdjustments) {
        if (Object.prototype.hasOwnProperty.call(form.value.deliveryAdjustments, day)) {
          const deliveryTimeSlots = form.value.deliveryAdjustments[day].deliveryTimeSlots;

          if (deliveryTimeSlots.length == 0) {
            // addBlankTimeSlot(day);
          } else {
            deliveryTimeSlots.forEach(slot => {
              if (slot.fee == null) {
                slot.fee = 0;
              }

              let [hours, minutes] = slot.startTime.split(':').map(Number);
              slot.startTimeValues = {
                hours,
                minutes,
                seconds: 0
              };

              [hours, minutes] = slot.endTime.split(':').map(Number);
              slot.endTimeValues = {
                hours,
                minutes,
                seconds: 0
              };
            });
          }
        }
      }
    };

    return {
      form,
      mode,
      isDisabled,
      handleSubmit,
      handleCancel,
      fulfilmentDelete,
      checkState,
      checkTime,
      formValid,
      formRef,
      rules,
      convertToFullDay,
      formatTime,
      startTime,
      addBlankTimeSlot,
      deleteTimeSlot,
    }
  }
};
</script>
