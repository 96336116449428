<template>
  <div>
    <slot :showDialog="showDialog"></slot>
    <v-dialog
      v-model="dialogVisible"
      width="630px"
      data-cy="cypress-confirm-dialog"
    >
      <v-card>
        <v-card-text>
          <div class="cm-p-6">
            <div
              v-if="title"
              class="cm-text-2xl cm-font-bold cm-text-red-600 text-center cm-mb-6"
            >
              {{ title }}
            </div>
            <div v-if="content">
              {{ content }}
            </div>
            <div class="cm-grid cm-grid-cols-2 cm-gap-4 cm-mt-6">
              <div>
                <v-btn
                  color="#14569E"
                  class="cm-text-white"
                  block
                  elevation="0"
                  @click="handleConfirm"
                  :loading="loading"
                  data-cy="cypress-confirm-dialog-confirm-button"
                  >Confirm</v-btn
                >
              </div>
              <div>
                <v-btn
                  @click="dialogVisible = false"
                  variant="outlined"
                  block
                  elevation="0"
                  data-cy="cypress-confirm-dialog-cancel-button"
                  >Cancel</v-btn
                >
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const dialogVisible = ref(false);
    const loading = ref(false);

    const showDialog = () => {
      console.log("showDialog");
      dialogVisible.value = true;
    };

    const handleConfirm = async () => {
      dialogVisible.value = false;
      if (props.onConfirm) {
        loading.value = true;
        try {
          await props.onConfirm();
          loading.value = false;
        } catch {
          loading.value = false;
        }
      }
    };

    return {
      dialogVisible,
      showDialog,
      handleConfirm,
    };
  },
};
</script>
