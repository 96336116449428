<template>
  <simple-text-field
    density="compact"
    label="Description"
    tooltipText="The description for your reward, helps you to understand its purpose."
    icon="mdi-information-outline"
    icon-color="#14569E"
    placeholder="Reward Description"
    v-bind="$attrs"
  />
</template>
<script>
import SimpleTextField from "@/components/ui/SimpleTextField.vue";
export default {
  name: "LoyaltyRewardsFormDescriptionField",
  components: {
    SimpleTextField,
  },
};
</script>
