<template>
  <simple-select-input
    density="compact"
    label="Type"
    :items="items"
    item-title="name"
    item-value="id"
    v-bind="$attrs"
  />
</template>
<script>
import { ref, onMounted } from "vue";
import { useLoyaltyRewardsRewardStore } from "@/stores/LoyaltyRewards/reward.ts";
import SimpleSelectInput from "@/components/ui/SimpleSelectInput.vue";
export default {
  name: "LoyaltyRewardsFormDiscountTypeField",
  components: {
    SimpleSelectInput,
  },
  setup() {
    const loyaltyRewardsRewardStore = useLoyaltyRewardsRewardStore();
    const items = ref([]);

    onMounted(async () => {
      await loyaltyRewardsRewardStore.getTypes();
      items.value = await loyaltyRewardsRewardStore.types;
    });

    return {
      items,
    };
  },
};
</script>
