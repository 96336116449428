<template>
  <v-chip
    class="rounded-lg closeable-chip"
    v-bind="$attrs"
    :closable="closable"
    variant="outlined"
    size="x-large"
    close-icon="mdi-close-circle-outline"
    @click:close="() => $emit('closeChip')"
  >
    <v-img
      v-if="image.mdpi_1x"
      class="ma-2"
      :width="40"
      aspect-ratio="1"
      cover
      :src="image.mdpi_1x"
    ></v-img>
    <span
      class="cm-font-bold cm-text-base cm-truncate"
      :style="{ color: textColor }"
    >
      {{ text }}
    </span>
  </v-chip>
</template>

<script>
export default {
  name: "ClosableChip",
  emits: ["closeChip"],
  props: {
    text: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      default: "#E42034",
    },
    closable: {
      type: Boolean,
      default: true,
    },
    image: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
};
</script>
<style>
.closeable-chip.v-chip--variant-outlined {
  border-style: dashed !important;
  border-color: rgb(100 116 139);
}
.closeable-chip.v-chip.v-chip--size-x-large {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.closeable-chip .v-chip__close {
  margin-left: 30px !important;
  margin-right: 10px !important;
  font-size: 30px !important;
  color: #7f8695 !important;
}
</style>
