<template>
  <simple-text-field
    density="compact"
    type="number"
    label="Maximum Discount Value in £"
    tooltipText="The amount entered here will be the maximum value that the discount will be applied to. Setting a value here helps protect against exceptionally high basket values having a very large discount."
    icon="mdi-information-outline"
    icon-color="#14569E"
    placeholder="£0.00"
    v-bind="$attrs"
  />
</template>
<script>
import SimpleTextField from "@/components/ui/SimpleTextField.vue";
export default {
  name: "LoyaltyRewardsFormDiscountLimitField",
  components: {
    SimpleTextField,
  },
};
</script>
