<template>
  <div v-if="services" class="pa-3">
    <v-row>
      <v-col cols="6">
        <div class="cm-text-xl font-weight-bold text-text-darker mb-3">
          Services
        </div>
        <v-card class="pa-3" elevation="3">
          <v-list>
            <edit-text-field
              v-if="collection"
              is-list-type
              class="text-capitalize"
              :list-items="['Yes', 'No']"
              text-field-name="Collection"
              :edit-model="collection"
              :rules="rules.collection"
              @edit-model-value="collection = $event"
              @update-model-value="servicesDataUpdate(id, item, 'Collection')"
            >
            </edit-text-field>
            <edit-text-field
              v-if="delivery"
              is-list-type
              class="text-capitalize"
              :list-items="['Yes', 'No']"
              text-field-name="Delivery"
              :edit-model="delivery"
              :rules="rules.delivery"
              @edit-model-value="delivery = $event"
              @update-model-value="servicesDataUpdate(id, item, 'Delivery')"
            >
            </edit-text-field>
            <edit-text-field
              v-if="table"
              is-list-type
              class="text-capitalize"
              :list-items="['Yes', 'No']"
              text-field-name="Table Ordering"
              :edit-model="table"
              :rules="rules.table"
              @edit-model-value="table = $event"
              @update-model-value="servicesDataUpdate(id, item, 'Table')"
            >
            </edit-text-field>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-divider v-if="services" class="my-5"></v-divider>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useServices } from "@/stores/RetailersDashboard/services.ts";
import RetailersDashboardEditTextField from "@/components/retailersDashboard/forms/RetailersDashboardEditTextField.vue";
import { useToast } from "vue-toastification";

import { debounce } from "lodash";

export default {
  components: {
    EditTextField: RetailersDashboardEditTextField,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: String || Number,
      required: true,
    },
  },
  setup(props) {
    const servicesStore = useServices();
    const services = ref({});
    const toast = useToast();

    const collection = computed({
      get() {
        return services.value.collection ? "Yes" : "No";
      },
      set(value) {
        services.value.collection = value === "Yes" ? true : false;
      },
    });

    const delivery = computed({
      get() {
        return services.value.delivery ? "Yes" : "No";
      },
      set(value) {
        services.value.delivery = value === "Yes" ? true : false;
      },
    });

    const table = computed({
      get() {
        return services.value.table ? "Yes" : "No";
      },
      set(value) {
        services.value.table = value === "Yes" ? true : false;
      },
    });

    const servicesDataUpdate = async (id, item, service) => {
      try {
        await servicesStore.updateServicesInfo(id, {
          ...item,
          services: {
            ...item.services,
            [service.toLowerCase()]: item.services[service.toLowerCase()],
          },
        });
      } catch (error) {
        toast.error(error.message);
      }
    };

    const rules = ref({
      collection: [(v) => !!v || "Collection is required"],
      delivery: [(v) => !!v || "Delivery is required"],
      table: [(v) => !!v || "Table Ordering is required"],
    });

    onMounted(async () => {
      setTimeout(async () => {
        services.value = await props?.item?.services;
      }, 400);
    });
    return {
      servicesStore,
      rules,
      collection,
      delivery,
      table,
      services,
      servicesDataUpdate,
    };
  },
};
</script>
