import { defineStore } from "pinia";
import api from "@/utils/api.js";

export const useScheduleStore = defineStore("scheduleStore", {
  state: () => {
    return {
      mode: null, //create/edit
      loaded: null,
      state: 1,
      allStoresSelected: true,
      schedule: {
        id: null,
        title: null,
        startingDate: null,
        endingDate: null,
        store: null,
        storeIds: null,
        type: null,
        day: null,
        startTime: null,
        endTime: null,
        minimumSpendForDelivery: null,
        minimumSpendForFreeDelivery: null,
        zones: [],
        storeMessage: null,
        status: null,
      },
      fees: {
        minimumSpendForDelivery: null,
        minimumSpendForFreeDelivery: null,
        zones: []
      },
      map: null,
      similar: [],
    };
  },
  getters: {},
  actions: {
    async getSchedule(id) {
      try {
        return await api.scheduleStore.schedule.get(id);
      } catch {
        throw new Error("Unable to get schedule");
      }
    },
    async deleteSchedule(id) {
      try {
        return await api.scheduleStore.schedule.delete(id);
      } catch {
        throw new Error("Unable to delete schedule");
      }
    },
    async createSchedule(payload) {
      try {
        return await api.scheduleStore.schedule.create(payload);
      } catch {
        throw new Error("Unable to create schedule");
      }
    },
    async updateSchedule(id, payload) {
      try {
        return await api.scheduleStore.schedule.update(id, payload);
      } catch {
        throw new Error("Unable to create schedule");
      }
    },
    async getDeliveryZones(storeId) {
      try {
        const map = await api.scheduleStore.map.get({
          storeId: storeId,
        });
        this.fees = map;
      } catch {
        throw new Error("Unable to get map");
      }
    },
    async searchSchedules(payload) {
      try {
        return await api.scheduleStore.schedule.search(payload);
      } catch {
        throw new Error("Unable to search for schedules");
      }
    },
    async similarSchedules(schedule) {

      const values = {
        queuedAt: schedule.queuedAt,
        name: schedule.title,
        type: schedule.type,
        storeMessage: schedule.storeMessage
      };
      
      this.similar = await this.searchSchedules(values);

      return this.similar.filter(item =>
          item.type === schedule.type &&
          item.title === schedule.title &&
          item.queuedAt === schedule.queuedAt &&
          item.storeMessage === schedule.storeMessage &&
          item.id !== schedule.id
      );

    },
  },
});
