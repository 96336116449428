<template>
  <tr>
    <td class="v-data-table__td">
      <a
        data-cy="cypress-table-result-link"
        href="#"
        @click.prevent="handleClick"
        class="cm-text-base cm-underline cm-text-primary"
        >{{ title }}</a
      >
    </td>
    <td class="v-data-table__td">
      <span class="cm-text-base">{{ store.name }}</span>
    </td>
    <td class="v-data-table__td">
      <span class="cm-text-base cm-capitalize">{{ type }}</span>
    </td>
    <td class="v-data-table__td">
      <span class="cm-text-base">{{ formattedDate(queuedAt) }}</span>
    </td>
    <td class="v-data-table__td">
      <span class="cm-text-base">{{ formattedDate(endingDate) }}</span>
    </td>
    <td class="v-data-table__td">
      <span class="cm-text-base">{{ formattedCreatedBy(createdBy.name) }}</span>
    </td>
  </tr>
</template>
<script>
import { toRefs, computed } from "vue";
import moment from "moment";
import { useScheduleNavigationStore } from "@/stores/ScheduleStoreUpdates/navigation.ts";
import { useScheduleStore } from "@/stores/ScheduleStoreUpdates/schedule.ts";
import { useToast } from "vue-toastification";
export default {
  name: "SearchResultsTableRow",
  props: {
    item: Object,
  },
  setup(props) {
    const {
      id,
      title,
      store,
      type,
      queuedAt,
      endingDate,
      createdBy,
    } = toRefs(props.item);
    const scheduleNavigationStore = useScheduleNavigationStore();
    const scheduleStore = useScheduleStore();
    const toast = useToast();

    if (title.value == null) {
      title.value = 'Unnamed Event'
    }

    const handleClick = async () => {
      scheduleStore.schedule.id = id.value;
      scheduleNavigationStore.viewScheduleForm();
    };

    function formattedDate(date) {
      if (date) {
        return moment(date).format("DD/MM/YY")
      }
    };

    function formattedCreatedBy(name) {
      if (name == 'mtc.') {
        name = 'Snappy'
      }
      return name
    };

    return {
      title,
      store,
      type,
      queuedAt,
      endingDate,
      formattedDate,
      createdBy,
      formattedCreatedBy,
      handleClick,
    };
  },
};
</script>
