<template>
  <simple-number-field
    density="compact"
    type="number"
    label="Redemption Limit"
    placeholder="Number of times the coupon can be used"
    v-bind="$attrs"
    icon="mdi-information-outline"
    icon-color="#14569E"
    decimals="0"
    max="500"
    min="1"
  />
</template>
<script>
import SimpleNumberField from "@/components/ui/SimpleNumberField.vue";
export default {
  name: "CouponFormRedemptionsLimitField",
  components: {
    SimpleNumberField,
  },
};
</script>
