<template>
  <div data-cy="cypress-copy-view">
    <copy-products-to-menu-groups />
  </div>
</template>
<script>
import CopyProductsToMenuGroups from "@/components/copyProductsToMenuGroups/CopyProductsToMenuGroups.vue";
export default {
  components: {
    CopyProductsToMenuGroups,
  },
};
</script>
