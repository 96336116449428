<template>
  <v-text-field
    persistent-placeholder
    variant="outlined"
    :model-value="colorValue"
    @input="updateTextField"
    v-bind="$attrs"
  >
    <template v-slot:prepend-inner>
      <v-menu v-model="menu" :close-on-content-click="false" location="end">
        <template v-slot:activator="{ props }">
          <v-icon icon="mdi-pencil-circle" v-bind="props" />
        </template>
        <v-color-picker
          :model-value="colorValue"
          hide-inputs
          @update:model-value="updateColor"
        ></v-color-picker>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    colorValue: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const menu = ref(false);

    const updateColor = (value) => {
      emit("colorUpdate", value);
    };

    const updateTextField = (event) => {
      emit("colorUpdate", event.target.value);
    };

    return {
      menu,
      updateTextField,
      updateColor,
    };
  },
};
</script>
