<template>
  <simple-card
    class="cm-pa-2 cm-rounded-lg"
    elevation="3"
    data-cy="cypress-create-card"
  >
    <template #header>
      <simple-card-title title="Create Your new Coupon Now" class="mb-5" />
    </template>
    <simple-button
      data-cy="cypress-create-card-button"
      text="Create New Coupon"
      class="cm-capitalize cm-text-base cm-font-semibold"
      color="#14569E"
      size="large"
      prepend-icon="mdi-plus"
      @click="handleClick"
    />
  </simple-card>
</template>
<script>
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleCardTitle from "@/components/ui/SimpleCardTitle.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
export default {
  name: "CreateCouponCard",
  components: {
    SimpleCard,
    SimpleButton,
    SimpleCardTitle,
  },
  setup() {
    const navigationStore = useNavigationStore();
    const couponStore = useCouponStore();

    const handleClick = () => {
      couponStore.$reset();
      return navigationStore.viewCouponFormStepOne();
    };

    return {
      handleClick,
    };
  },
};
</script>
