<template>
  <v-form data-cy="cypress-loyalty-rewards-form-step-one" validate-on="input" ref="formRef" v-model="formValid"
    @submit.prevent="handleSubmit" class="cm-flex cm-flex-col">
    <simple-card color="white" elevation="3" class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible" closabled
      @close="handleCancel">
      <template #header>
        <div class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary">
          Create Reward
        </div>
        <loyalty-rewards-form-breadcrumbs />
      </template>
      <form>
        <loyalty-rewards-types-select-input class="mb-2" :rules="rules.typeId" v-model="form.typeId"
          data-cy="cypress-form-step-one-type-field" />
        <template v-if="displayRelevantFields">
          <loyalty-rewards-form-name-field class="mb-2" v-model="form.name" :rules="rules.name"
            :placeholder="placeholder" data-cy="cypress-form-step-one-name-field" />
          <loyalty-rewards-free-items v-if="isDiscountTypeFreeItem" :edit-mode="false" :rules="rules.freeItem" />
          <loyalty-rewards-form-discount-value-field v-if="isDiscountTypeSetDiscount" class="mb-2"
            v-model="form.config.amount" :rules="rules.amount" data-cy="cypress-form-step-one-discount-value-field" />
          <loyalty-rewards-form-percentage-value-field v-if="isDiscountTypePercent" class="mb-2"
            v-model="form.config.percentageValue" :rules="rules.percentageValue"
            data-cy="cypress-form-step-one-discount-percent-field" />
          <loyalty-rewards-form-discount-limit-field v-if="isDiscountTypePercent" class="mb-2"
            :rules="rules.discountLimit" v-model="form.config.discountLimit"
            data-cy="cypress-form-step-one-max-discount-field" />
          <loyalty-rewards-form-purchase-amount-required-field class="mb-2" v-model="form.spendRequired"
            :rules="rules.spendRequired" data-cy="cypress-form-step-one-discount-value-field" />
          <loyalty-rewards-form-description-field class="mb-2" v-model="form.description" :rules="rules.description"
            data-cy="cypress-form-step-one-description-field" />
          <loyalty-rewards-form-start-date-field class="mb-2" v-model="formattedStartDate" :rules="rules.startsAt"
            data-cy="cypress-form-step-one-start-date-field" :min-date="startDateMinDate" />
          <loyalty-rewards-form-end-date-field class="mb-2" v-model="formattedEndDate" :rules="rules.expiresAt"
            :min-date="endDateMinDate" data-cy="cypress-form-step-one-end-date-field" />
        </template>
      </form>
      <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">
        <template #leftButton>
          <simple-button class="cm-capitalize cm-text-base cm-font-semibold" color="#14569E" variant="secondary"
            size="large" text="Cancel" @click="handleCancel"
            data-cy="cypress-form-step-one-cancel-button"></simple-button>
        </template>
        <template #rightButton>
          <simple-button class="cm-capitalize cm-text-base cm-font-semibold" color="#14569E" size="large" text="Next"
            data-cy="cypress-form-step-one-next-button" type="submit" :disabled="isDisabled"></simple-button>
        </template>
      </bottom-navigation>
    </simple-card>
  </v-form>
</template>
<script>
import { ref, toRaw, computed, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import moment from "moment";
import { useToast } from "vue-toastification";
import { useLoyaltyRewardsNavigationStore } from "@/stores/LoyaltyRewards/loyaltyRewardsNavigation.ts";
import { useLoyaltyRewardsRewardStore } from "@/stores/LoyaltyRewards/reward.ts";
import { useAuthStore } from "@/stores/auth.ts";
import {
  RULE_REQUIRED,
  RULE_VALID_DATE_DD_MM_YYYY,
  RULE_QUANTITY_GREATER_THAN_ONE,
  RULE_MIN_NUMBER,
  RULE_MAX_NUMBER,
} from "@/constants/rules.js";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import LoyaltyRewardsTypesSelectInput from "@/components/loyaltyRewards/forms/LoyaltyRewardsTypesSelectInput.vue";
import LoyaltyRewardsFormBreadcrumbs from "@/components/loyaltyRewards/forms/LoyaltyRewardsFormBreadcrumbs.vue";
import LoyaltyRewardsFormNameField from "@/components/loyaltyRewards/forms/loyaltyRewardsForm/fields/LoyaltyRewardsNameField.vue";
import LoyaltyRewardsFormDescriptionField from "@/components/loyaltyRewards/forms/loyaltyRewardsForm/fields/LoyaltyRewardsFormDescriptionField.vue";
import LoyaltyRewardsFormStartDateField from "@/components/loyaltyRewards/forms/loyaltyRewardsForm/fields/LoyaltyRewardsFormStartDateField.vue";
import LoyaltyRewardsFormEndDateField from "@/components/loyaltyRewards/forms/loyaltyRewardsForm/fields/LoyaltyRewardsFormEndDateField.vue";
import LoyaltyRewardsFormDiscountValueField from "@/components/loyaltyRewards/forms/loyaltyRewardsForm/fields/LoyaltyRewardsFormDiscountValueField.vue";
import LoyaltyRewardsFormPercentageValueField from "@/components/loyaltyRewards/forms/loyaltyRewardsForm/fields/LoyaltyRewardsFormPercentageValueField.vue";
import LoyaltyRewardsFormPurchaseAmountRequiredField from "@/components/loyaltyRewards/forms/loyaltyRewardsForm/fields/LoyaltyRewardsFormPurchaseAmountRequiredField.vue";
import LoyaltyRewardsFormDiscountLimitField from "@/components/loyaltyRewards/forms/loyaltyRewardsForm/fields/LoyaltyRewardsFormDiscountLimitField.vue";
import LoyaltyRewardsFreeItems from "@/components/loyaltyRewards/forms/LoyaltyRewardsFreeItems.vue";
export default {
  name: "LoyaltyRewardsCreateFormStepOneView",
  components: {
    SimpleCard,
    SimpleButton,
    BottomNavigation,
    LoyaltyRewardsTypesSelectInput,
    LoyaltyRewardsFormBreadcrumbs,
    LoyaltyRewardsFormNameField,
    LoyaltyRewardsFormDescriptionField,
    LoyaltyRewardsFormStartDateField,
    LoyaltyRewardsFormEndDateField,
    LoyaltyRewardsFormDiscountValueField,
    LoyaltyRewardsFormPercentageValueField,
    LoyaltyRewardsFormPurchaseAmountRequiredField,
    LoyaltyRewardsFormDiscountLimitField,
    LoyaltyRewardsFreeItems,
  },
  setup() {
    const navigationLoyaltyRewardsStore = useLoyaltyRewardsNavigationStore();
    const loyaltyRewardsRewardStore = useLoyaltyRewardsRewardStore();
    const { isSuperAdmin } = storeToRefs(useAuthStore());
    onMounted(() => {
      loyaltyRewardsRewardStore.resetState();
    });
    const form = ref({
      config: {
        amount: 0,
        percentageValue: 0,
        discountLimit: 0,
      },
    });
    const formValid = ref(false);
    const formRef = ref(null);
    const toast = useToast();

    const startsAt = computed(() => {
      if (form.value.startsAt) {
        return moment(form.value.startsAt, "DD-MM-YYYY")
          .startOf("month")
          .format("DD-MM-YYYY");
      }
      return null;
    });

    const expiresAt = computed(() => {
      if (form.value.expiresAt) {
        return moment(form.value.expiresAt, "DD-MM-YYYY")
          .endOf("month")
          .format("DD-MM-YYYY");
      }
      return null;
    });

    const rules = ref({
      name: [RULE_REQUIRED],
      typeId: [RULE_REQUIRED],
      description: [RULE_REQUIRED],
      startsAt: [RULE_REQUIRED, RULE_VALID_DATE_DD_MM_YYYY],
      expiresAt: [RULE_REQUIRED, RULE_VALID_DATE_DD_MM_YYYY],
      stores: [RULE_REQUIRED, RULE_QUANTITY_GREATER_THAN_ONE],
      spendRequired: [RULE_REQUIRED, RULE_MIN_NUMBER(0.01)],
      percentageValue: [
        RULE_REQUIRED,
        RULE_MIN_NUMBER(1),
        RULE_MAX_NUMBER(100),
      ],
      discountLimit: [RULE_MAX_NUMBER(50)],
      freeItem: [
        v => (loyaltyRewardsRewardStore?.reward?.config?.itemIds?.length !== 0 || !!v) || 'Product is required.'
      ],
    });

    const placeholder = computed(() => {
      if (form.value.typeId === 2) {
        return "Free Item Reward";
      }
      if (form.value.typeId === 3) {
        return "Money Off Reward";
      }
      if (form.value.typeId === 4) {
        return "Percentage Off Reward";
      }
      return "Free Delivery Reward";
    });

    const displayRelevantFields = computed(() => {
      return form.value.typeId ? true : false;
    });

    const isDiscountTypePercent = computed(() => {
      return Boolean(form.value.typeId === 4);
    });

    const isDiscountTypeSetDiscount = computed(() => {
      return Boolean(form.value.typeId === 3);
    });

    const isDiscountTypeFreeItem = computed(() => {
      return Boolean(form.value.typeId === 2);
    });

    const handleSubmit = async () => {
      try {
        const { valid } = await formRef.value.validate();
        if (!valid) {
          return;
        }
        // Initialize the config object based on typeId
        let config = {};
        switch (form.value.typeId) {
          case 2: // Free product
            config = {
              itemIds: loyaltyRewardsRewardStore?.reward?.config?.itemIds || [],
            };
            break;
          case 3: // Money off
            config = {
              amount: Number(form.value.config.amount) || null,
            };
            break;
          case 4: // Percentage off
            config = {
              discount: Number(form.value.config.percentageValue) || null,
              max: Number(form.value.config.discountLimit) || null,
            };
            break;
          // No config needed for type 1 (Free delivery)
        }
        loyaltyRewardsRewardStore.reward = {
          ...toRaw(form.value),
          id: 455,
          typeId: form?.value?.typeId || null,
          name: form?.value?.name || null,
          description: form?.value?.description || null,
          spendRequired: Number(form?.value?.spendRequired) || 0,
          startsAt:
            moment(startsAt.value, "DD-MM-YYYY").format("YYYY-MM-DD") || null,
          expiresAt:
            moment(expiresAt.value, "DD-MM-YYYY").format("YYYY-MM-DD") || null,
          config: config,
          excludedTobacco: form?.value?.excludeAllTobaccoProducts,
          excludedAlcohol: form?.value?.excludeAllAlcoholProducts,
        };
        navigationLoyaltyRewardsStore.viewLoyaltyRewardsCreateFormStepTwo();
      } catch (e) {
        toast.error(e, {
          timeout: 2000,
        });
      }
    };

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    const startDateMinDate = computed(() => {
      return moment().format("YYYY-MM");
    });

    const endDateMinDate = computed(() => {
      return moment(form.value.startsAt).format("YYYY-DD");
    });

    const formattedStartDate = computed({
      set(value) {
        form.value.startsAt = moment(value).format("DD-MM-YYYY");
      },
      get() {
        return form.value.startsAt;
      },
    });

    const formattedEndDate = computed({
      set(value) {
        form.value.expiresAt = moment(value).format("DD-MM-YYYY");
      },
      get() {
        return expiresAt.value;
      },
    });

    const handleCancel = () => {
      loyaltyRewardsRewardStore.resetState();
      navigationLoyaltyRewardsStore.viewDashboard();
    };

    return {
      form,
      isDisabled,
      handleSubmit,
      handleCancel,
      formValid,
      formRef,
      rules,
      startDateMinDate,
      endDateMinDate,
      formattedStartDate,
      formattedEndDate,
      isSuperAdmin,
      isDiscountTypePercent,
      isDiscountTypeSetDiscount,
      isDiscountTypeFreeItem,
      displayRelevantFields,
      placeholder,
    };
  },
};
</script>
