import { defineStore } from "pinia";
import api from "@/utils/api.js";

export const useCouponStore = defineStore("coupon", {
  state: () => {
    return {
      mode: null, //create/edit
      allStoresSelected: true,
      coupon: {
        name: null,
        status: "draft",
        displayStatus: "Unsaved",
        locationRestrictions: {
          stores: [],
        },
        type: null,
        startDate: null,
        endDate: null,
        rewardRestrictions: {
          value: null,
          maxDiscount: null,
          minimumSpend: 0,
          redemptions: 10,
        },
        customerRestrictions: {
          isForNewCustomers: false,
          singleUsePerCustomer: true,
        },
        code: null,
        itemRestrictions: {
          specificItemIds: [],
          specificBarcodes: [],
        },
        bulkExcludeAlcohol: true,
        bulkExcludeTobacco: true,
      },
    };
  },
  getters: {},
  actions: {
    async getCoupon(id) {
      try {
        const { coupon } = await api.couponManager.coupon.get(id);
        this.coupon = coupon;
      } catch {
        throw new Error("Unable to view coupon");
      }
    },
    async createCoupon(payload) {
      try {
        const { coupon } = await api.couponManager.coupon.create({
          coupon: payload,
        });

        this.coupon = coupon;
      } catch {
        throw new Error("Unable to create coupon");
      }
    },
    async updateCoupon(payload) {
      try {
        const { coupon } = await api.couponManager.coupon.update(
          this.coupon.id,
          {
            coupon: payload,
          }
        );
        this.coupon = coupon;
      } catch {
        throw new Error("Unable to update coupon");
      }
    },
    async deleteCoupon(id) {
      try {
        const response = await api.couponManager.coupon.delete(id);
      } catch {
        throw new Error("Unable to delete coupon");
      }
    },
    async searchCoupons(payload) {
      try {
        return await api.couponManager.coupon.search(payload);
      } catch {
        throw new Error("Unable to search for coupons");
      }
    },
    async activateCoupon() {
      try {
        const { coupon } = await api.couponManager.coupon.activate(
          this.coupon.id,
          {
            active: true,
          }
        );
        this.coupon = coupon;
      } catch {
        throw new Error("Unable to activate coupon");
      }
    },
    async deactivateCoupon() {
      try {
        const { coupon } = await api.couponManager.coupon.activate(
          this.coupon.id,
          {
            active: false,
          }
        );
        this.coupon = coupon;
      } catch (e) {
        throw new Error("Unable to deactivate coupon");
      }
    },
  },
});
