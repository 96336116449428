<template>
  <v-select
    v-bind="$attrs"
    tabindex="0"
    :items="items"
    :label="label"
    variant="outlined"
    :color="activeColor"
    :style="{ color: color }"
    autocomplete="off"
    append-inner-icon="mdi-chevron-down"
  ></v-select>
</template>
<script>
export default {
  name: "SimpleSelectInput",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    activeColor: {
      type: String,
      default: "#14569E",
    },
    color: {
      type: String,
      default: "#000000",
    },
  },
  setup() {
    return {};
  },
};
</script>
