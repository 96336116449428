<template>
  <div>
    <v-select
      label="Font Family"
      :items="fontFamilyItems"
      :model-value="fonts[0]"
      @update:modelValue="(newValue) => updateSetting(newValue, 0)"
    ></v-select>
    <div class="cm-grid cm-grid-cols-2 cm-gap-4">
      <div>
        <v-select
          label="Font Size"
          :items="fontSizeItems"
          :model-value="fonts[1]"
          @update:modelValue="(newValue) => updateSetting(newValue, 1)"
        ></v-select>
      </div>
      <div>
        <v-select
          label="Font Accessibility"
          :items="fontAccessibilityItems"
          :model-value="fonts[2]"
          @update:modelValue="(newValue) => updateSetting(newValue, 2)"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script>
import { watch, ref } from "vue";
export default {
  props: {
    value: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const fontFamilyItems = ref([
      "Helvetica Bold",
      "Poppins-Bold",
      "Poppins-Regular",
      "Poppins-SemiBold",
      "Roboto Medium",
      "Montserrat-ExtraBold",
      "Montserrat-Bold",
      "Montserrat-SemiBold",
      "Montserrat-Regular",
    ]);
    const fontSizeItems = ref(
      Array.from({ length: 15 }, (_, i) => (i + 1) * 2)
    );
    const fontAccessibilityItems = ref([
      "body",
      "caption2",
      "caption",
      "headline",
    ]);

    const fonts = ref(props.value.split("|"));

    watch(
      () => props.value,
      (newValue) => {
        fonts.value = newValue.split("|");
      }
    );

    const updateSetting = (newValue, index) => {
      fonts.value[index] = newValue;

      emit("onValueUpdate", fonts.value.join("|"));
    };

    return {
      fonts,
      fontSizeItems,
      fontFamilyItems,
      fontAccessibilityItems,
      updateSetting,
    };
  },
};
</script>
