<template>
  <div v-if="location.length">
    <div class="cm-text-xl font-weight-bold text-text-darker mb-3">
      Store Location
      <!-- TODO: to be readded for phase 2 -->
      <!-- <v-btn variant="text" @click="editMode = !editMode">
        <v-icon
          color="#FD6719"
          size="20"
          icon="mdi-square-edit-outline"
        ></v-icon>
      </v-btn> -->
    </div>
    <v-card class="pa-0" elevation="3">
      <app-map
        :style="mapStyle"
        :disable-default-ui="!editMode"
        :gesture-handling="mapGestureHandling"
        :location="location"
        @onMarkerUpdated="handleMarkerUpdated"
      />
    </v-card>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { useToast } from "vue-toastification";
import { useRetailerStore } from "@/stores/RetailersDashboard/retailer.ts";
import AppMap from "@/components/retailersDashboard/AppMap.vue";
export default {
  components: {
    AppMap,
  },
  props: {
    location: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup() {
    const toast = useToast();
    const retailerStore = useRetailerStore();
    const editMode = ref(false);

    const mapStyle = computed(() => {
      return {
        width: "100%",
        height: editMode.value ? "400px" : "250px",
      };
    });

    const mapGestureHandling = computed(() => {
      return editMode.value ? "auto" : "none";
    });

    const handleMarkerUpdated = ({ lat, lng }) => {
      try {
        // call api when available
        retailerStore.retailer.details.address.lat = lat;
        retailerStore.retailer.details.address.lng = lng;
        toast.success("Store location updated");
      } catch {
        toast.error("Unable to update store location");
      }
    };

    return {
      editMode,
      mapStyle,
      mapGestureHandling,
      handleMarkerUpdated,
    };
  },
};
</script>
