<template>
  <simple-list :title="title" :subtitle="subTitle">
    <div v-if="isEditMode" class="mt-3">
      <slot />
    </div>
  </simple-list>
</template>
<script>
import { toRefs, computed } from "vue";
import SimpleList from "@/components/ui/SimpleList.vue";
export default {
  name: "InlineFormFieldEdit",
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: [String, Number],
    },
    editMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    SimpleList,
  },
  setup(props) {
    const { editMode, subtitle } = toRefs(props);

    const subTitle = computed(() => {
      return editMode.value ? null : subtitle.value;
    });

    const isEditMode = computed(() => Boolean(editMode.value));

    return {
      isEditMode,
      subTitle,
    };
  },
};
</script>
