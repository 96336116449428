import { defineStore } from "pinia";
import api from "../../utils/api.js";

export const useBasicStoreInfo = defineStore({
  id: "basic-store-info-store",
  state: () => {
    return {
      storeInfo: {},
    };
  },
  getters: {
    getStoreInfo: (state) => state.storeInfo,
  },
  actions: {
    updateStoreInfo(value) {
      this.storeInfo = value;
    },
    async updateBusinessBasicsInfo(id, payload) {
      try {
        const response = api.retailersDashboard.retailers.update(id, payload);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getBusinessBasicsInfo(id) {
      try {
        const response = await api.retailersDashboard.retailers.get(id);

        const { retailer } = response.data.value;
        this.updateStoreInfo(retailer);
      } catch (e) {
        throw new Error(e.message);
      }
    },
  },
});
