<template>
  <simple-checkbox
    border
    v-bind="$attrs"
    label="Excludes All Alcohol Products"
    color="#14569E"
  />
</template>
<script>
import SimpleCheckbox from "@/components/ui/SimpleCheckbox.vue";
export default {
  name: "CouponFormExcludeAlcoholField",
  components: {
    SimpleCheckbox,
  },
};
</script>
