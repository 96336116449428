<template>
  <v-text-field
    v-bind="$attrs"
    variant="outlined"
    :clearable="clearable"
    :name="name"
    :label="label"
    type="text"
    v-model="value"
    :value="value"
    @change="changeValue"
  >
    <template v-slot:prepend-inner>
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="24"
        color="#14569E"
      ></v-progress-circular>
    </template>
    <template v-slot:append-inner>
      <v-tooltip v-if="tooltipText" :text="tooltipText" max-width="300px">
        <template v-slot:activator="{ props }">
          <v-icon
            v-bind="props"
            :color="iconColor"
            :icon="icon"
            data-cy="tooltip-icon"
          />
        </template>
      </v-tooltip>
    </template>
  </v-text-field>
</template>
<script>
import { ref, watch } from "vue";

export default {
  emits: ["editModelValue"],
  name: "SimpleTextField",
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    tooltipText: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Number,
      default: "",
    },
  },
  setup(props, { emit }) {
    const value = ref(props.modelValue);

    const changeValue = (event) => {
      let newValue = value
      if (props.uppercase === true) {
        newValue.value = newValue.value.toUpperCase();
      }
      emit("update:modelValue", newValue);
    };

    watch(
      () => props.modelValue,
      (newValue) => {
        value.value = newValue;
      }
    );

    return {
      value,
      changeValue
    };
  },
};
</script>
<style>
.v-field--variant-outlined .v-field__outline__notch {
  border-width: 0 !important;
}
</style>
