<template>
  <simple-card
    data-cy="cypress-details-view"
    color="white"
    elevation="3"
    class="cm-p-4 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
    closabled
    @close="handleClose"
    :loading="loading"
  >
    <retailers-dashboard-status-crm
      :item="retailerStore.retailer"
      :id="retailerStore.retailerId"
    />
    <v-divider :thickness="2" class="border-opacity-50"></v-divider>
    <retailers-details
      :item="retailerStore.retailer"
      :id="retailerStore.retailerId"
    />
    <v-divider :thickness="2" class="border-opacity-50"></v-divider>
    <retailers-store-location class="my-5" :location="retailerLocation" />
    <retailers-domain-name
      :item="retailerStore.retailer"
      :id="retailerStore.retailerId"
    />
    <retailers-services
      :item="retailerStore.retailer"
      :id="retailerStore.retailerId"
    />
    <retailers-opening-hours
      :item="retailerStore.retailer"
      :id="retailerStore.retailerId"
    />
    <div class="w-100 d-flex justify-center">
      <simple-button
        v-if="!isEditMode"
        class="cm-capitalize cm-text-base cm-font-semibold cm-max-w-[400px]"
        color="#DB3C50"
        size="large"
        data-cy="cypress-details-close-button"
        variant="primary"
        text="Close"
        @click="handleClose"
      ></simple-button>
    </div>
  </simple-card>
</template>
<script>
import RetailersDetails from "../cards/RetailersDetails.vue";
import RetailersStoreLocation from "../cards/RetailersStoreLocation.vue";
import RetailersDomainName from "../cards/RetailersDomainName.vue";
import RetailersServices from "../cards/RetailersServices.vue";
import RetailersDashboardStatusCrm from "../forms/RetailersDashboardStatusCrm.vue";
import RetailersOpeningHours from "../cards/RetailersOpeningHours.vue";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import { useRetailerStore } from "@/stores/RetailersDashboard/retailer.ts";
import { useRetailersNavigationStore } from "@/stores/RetailersDashboard/retailersNavigation.ts";
import { onMounted, computed, ref } from "vue";

export default {
  components: {
    RetailersDetails,
    RetailersStoreLocation,
    RetailersDomainName,
    RetailersServices,
    RetailersDashboardStatusCrm,
    RetailersOpeningHours,
    SimpleCard,
    SimpleButton,
  },
  setup() {
    const retailerStore = useRetailerStore();
    const navigation = useRetailersNavigationStore();
    const loading = ref(true);

    onMounted(async () => {
      try {
        await retailerStore.get(retailerStore.retailerId);
        loading.value = false;
      } catch (e) {
        console.log("e", e);
      }
    });

    const retailerLocation = computed(() => {
      return {
        lat: retailerStore.retailer?.details?.address?.lat,
        lng: retailerStore.retailer?.details?.address?.lng,
      };
    });

    const handleClose = () => {
      return navigation.viewRetailersDashboard();
    };

    return { retailerStore, retailerLocation, handleClose, loading };
  },
};
</script>
