<template>
  <div>
    <simple-card
      color="white"
      elevation="3"
      class="cm-pa-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
    >
      <template #header>
        <div class="cm-text-center cm-font-bold md:cm-hidden">{{ title }}</div>
      </template>
      <a
        class="cm-block cm-text-center cm-underline cm-text-primary cm-mb-4 md:cm-hidden"
        href="#"
        @click.prevent="changeTab"
      >
        {{ toggleTitle }}
      </a>

      <div
        class="cm-justify-between cm-hidden md:cm-flex cm-mb-4 cm-items-center"
      >
        <div class="d-flex align-center justify-center mb-3 mb-lg-0">
          <a
            :class="`mr-3 ${
              tab !== 'latest' ? 'cm-underline cm-text-[#14569E]' : ''
            } cm-font-${tab === 'latest' ? 'bold' : 'regular'}`"
            href="#"
            @click.prevent="tab = 'latest'"
          >
            Scheduled Updates
          </a>
          |
          <a
            :class="`ml-3 ${
              tab === 'latest' ? 'cm-underline cm-text-[#14569E]' : ''
            } cm-font-${tab !== 'latest' ? 'bold' : 'regular'}`"
            href="#"
            @click.prevent="tab = 'past'"
          >
            Past Update History
          </a>
        </div>
        <div>
          <search-results-table-search />
        </div>
      </div>
      <search-results-table
        v-if="tab === 'past'"
        :options="pastTableOptions"
      />
      <search-results-table
        v-if="tab === 'latest'"
        :options="latestTableOptions"
      />
    </simple-card>
  </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import SearchResultsTable from "@/components/scheduleStoreUpdates/tables/SearchResultsTable.vue";
import SearchResultsTableSearch from "@/components/scheduleStoreUpdates/tables/SearchResultsTableSearch.vue";
import SimpleCard from "@/components/ui/SimpleCard.vue";
export default {
  name: "DashboardTableCard",
  components: {
    SearchResultsTable,
    SearchResultsTableSearch,
    SimpleCard,
  },
  setup() {
    //const PAST_LABEL = "Past Update History";
    const PAST_LABEL = "Include Past Updates";
    const LATEST_LABEL = "Your Scheduled Updates";
    const tab = ref("latest");
    const pastTableOptions = ref({
      status: 'completed',
      orderDirection: "desc",
      orderBy: "queuedAt",
    });
    const latestTableOptions = ref({
      status: 'pending',
      orderDirection: "asc",
      orderBy: "queuedAt",
    });

    const title = computed(() => {
      if (tab.value === "past") {
        return PAST_LABEL;
      }
      return LATEST_LABEL;
    });

    const toggleTitle = computed(() => {
      if (tab.value === "latest") {
        return PAST_LABEL;
      }
      return LATEST_LABEL;
    });

    const changeTab = () => {
      tab.value = tab.value === "past" ? "latest" : "past";
    };

    return {
      tab,
      title,
      toggleTitle,
      pastTableOptions,
      latestTableOptions,
      changeTab,
    };
  },
};
</script>
