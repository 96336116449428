<template>
  <autocomplete-input
    v-model="model"
    v-model:search="search"
    :loading="loading"
    density="compact"
    hide-no-data
    clearable
    :items="barcodes"
    @update:modelValue="itemChange"
    return-object
    v-bind="$attrs"
  >
  </autocomplete-input>
</template>

<script>
import { ref, watch, nextTick } from "vue";
import debounce from "lodash.debounce";
import AutocompleteInput from "@/components/ui/AutocompleteInput.vue";
import { useProductStore } from "@/stores/CouponManager/product.ts";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import { useToast } from "vue-toastification";
export default {
  name: "CouponFormFreeItemField",
  components: {
    AutocompleteInput,
  },
  setup(props, { attrs, emit }) {
    const productStore = useProductStore();
    const couponStore = useCouponStore();
    const loading = ref(false);
    const search = ref(null);
    const model = ref(null);
    const toast = useToast();
    const barcodes = ref([]);
    const form = ref({ ...couponStore.coupon });

    watch(search, (val) => {
      val && val !== attrs.modelValue && searchProducts(val);
    });

    const searchProducts = debounce(async (barcode) => {
      try {
        if (barcode.length <= 2) {
          return;
        }
        loading.value = true;

        if (search.value !== null) {
          barcodes.value = await productStore.searchProducts({
            barcode,
          });
        }
        loading.value = false;
        if (barcodes.value.length === 0) {
          throw new Error("No product found with this barcode");
        }
        nextTick(() => {
          model.value = null;
          search.value = null;
        });
      } catch (e) {
        loading.value = false;
        toast.error(e.message, {
          timeout: 2000,
        });
      }
    }, 400);

    const itemChange = (e) => {
      if (
        form.value.itemRestrictions.specificBarcodes &&
        form.value.itemRestrictions.specificBarcodes
          .map((p) => p.barcode)
          .includes(e.barcode)
      ) {
        toast.error("Item already added!", {
          timeout: 2000,
        });
        nextTick(() => {
          model.value = null;
          search.value = null;
        });
      } else {
        emit("onProductAdded", e);
        nextTick(() => {
          model.value = null;
          search.value = null;
        });
      }
    };

    const specificBarcodes = [
      (v) => !!v || "Barcode is required",
      (v) => !barcodes.value.includes(v) || "Barcode value already added",
    ];

    return {
      specificBarcodes,
      barcodes,
      search,
      loading,
      itemChange,
      model,
    };
  },
};
</script>
