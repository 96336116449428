<template>
  <div>
    <simple-card border elevation="0">
      <v-row>
        <v-col cols="12" md="6" class="pb-0 pb-sm-3">
          <inline-form-field-edit
            :edit-mode="editMode"
            title="Stores"
            :subtitle="storeName"
          >
            <store-autocomplete-input
              data-cy="cypress-details-form-stores-edit"
              :rules="rules.stores"
              v-model="form.locationRestrictions.stores"
              :isSuperAdmin="isSuperAdmin"
              class="mb-5"
            />
          </inline-form-field-edit>
          <inline-form-field-edit
            :edit-mode="editMode"
            title="Start Date"
            :subtitle="form.startDate"
          >
            <coupon-form-start-date-field
              v-model="formattedStartDate"
              :rules="rules.startDate"
              data-cy="cypress-details-form-start-date-edit"
            />
          </inline-form-field-edit>
          <inline-form-field-edit
            :edit-mode="editMode"
            title="Discount Type"
            :subtitle="discountType"
          >
            <coupon-types-select-input
              v-model="form.type"
              :rules="rules.type"
              data-cy="cypress-details-form-type-edit"
            />
          </inline-form-field-edit>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pt-sm-3">
          <inline-form-field-edit
            :edit-mode="editMode"
            title="Name"
            :subtitle="form.name"
          >
            <coupon-form-name-field
              v-model="form.name"
              :rules="rules.name"
              data-cy="cypress-details-form-name-edit"
            />
          </inline-form-field-edit>
          <inline-form-field-edit
            :edit-mode="editMode"
            title="End Date"
            :subtitle="form.endDate"
          >
            <coupon-form-end-date-field
              v-model="formattedEndDate"
              :rules="rules.endDate"
              data-cy="cypress-details-form-end-date-edit"
            />
          </inline-form-field-edit>
          <inline-form-field-edit
            :edit-mode="editMode"
            title="Discount Code"
            :subtitle="form.code"
          >
            <coupon-form-coupon-code-field
              v-model="form.code"
              :rules="rules.couponCode"
              data-cy="cypress-details-form-coupon-code-edit"
            />
          </inline-form-field-edit>
        </v-col>
      </v-row>
    </simple-card>
    <simple-card elevation="0" class="mt-5">
      <v-row>
        <v-col cols="12" sm="6" class="pb-0 pb-sm-3 px-0">
          <inline-form-field-edit
            v-if="isDiscountTypeSetDiscount"
            class="pl-0 pl-sm-2"
            :edit-mode="editMode"
            title="Discount Value"
            :subtitle="form.rewardRestrictions.value"
          >
            <coupon-form-discount-value-field
              :rules="rules.discountValue"
              v-model="form.rewardRestrictions.value"
              data-cy="cypress-details-form-discount-value-edit"
            />
          </inline-form-field-edit>
          <inline-form-field-edit
            v-if="isDiscountTypeFreeItem"
            class="pl-0 pl-sm-2"
            :edit-mode="editMode"
            title="Free Item"
            :subtitle="getBarcodes"
          >
            <coupon-free-items
              :edit-mode="editMode"
              :rules="rules.specificBarcodes"
            />
          </inline-form-field-edit>
          <inline-form-field-edit
            v-if="isDiscountTypePercent"
            class="pl-0 pl-sm-2"
            :edit-mode="editMode"
            title="Percentage Value"
            :subtitle="form.rewardRestrictions.value"
          >
            <coupon-form-percentage-value-field
              :rules="rules.percentageValue"
              v-if="isDiscountTypePercent"
              v-model="form.rewardRestrictions.value"
              data-cy="cypress-details-form-percentage-value-edit"
            />
          </inline-form-field-edit>
          <inline-form-field-edit
            v-if="isDiscountTypePercent"
            class="pl-0 pl-sm-2"
            :edit-mode="editMode"
            title="Maximum Discount"
            :subtitle="form.rewardRestrictions.maxDiscount"
          >
            <coupon-form-discount-limit-field
              v-if="isDiscountTypePercent"
              :rules="rules.discountLimit"
              v-model="form.rewardRestrictions.maxDiscount"
              data-cy="cypress-details-form-max-discount-edit"
            />
          </inline-form-field-edit>
          <inline-form-field-edit
            :edit-mode="editMode"
            class="pl-0 pl-sm-2"
            title="Redemption Limit"
            :subtitle="form.rewardRestrictions.redemptions"
          >
            <coupon-form-redemptions-limit-field
              :rules="rules.redemptionsLimit"
              v-model="form.rewardRestrictions.redemptions"
              data-cy="cypress-details-form-redemption-limit-edit"
            />
          </inline-form-field-edit>
          <inline-form-field-edit
            :edit-mode="editMode"
            class="pl-0 pl-sm-2"
            title="Min Basket Spend"
            :subtitle="form.rewardRestrictions.minimumSpend"
          >
            <coupon-form-min-basket-amount-field
              :rules="rules.minBasketAmount"
              v-model="form.rewardRestrictions.minimumSpend"
              data-cy="cypress-details-form-min-basket-edit"
            />
          </inline-form-field-edit>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-alert
            text="If you are a store based in England and would like this coupon to
          discount on alcohol products, please speak to our Support Team."
          />
          <!-- TODO: re-enable when API is ready -->
          <!-- <coupon-excluded-items :edit-mode="editMode">
            <div
              class="cm-block md:cm-flex cm-gap-4 mb-4"
              v-if="showBulkExcludeFields"
            >
              <coupon-form-exclude-alcohol-field
                v-model="form.bulkExcludeTobacco"
              />
              <coupon-form-exclude-tabacco-field
                v-model="form.bulkExcludeAlcohol"
              />
            </div>
          </coupon-excluded-items> -->

          <inline-form-field-edit
            :edit-mode="editMode"
            class="pl-0 pl-sm-2"
            :subtitle="form.customerRestrictions.newCustomers"
          >
            <coupon-form-new-customers-only-field
              v-model="form.customerRestrictions.isForNewCustomers"
              data-cy="cypress-details-form-new-customers-edit"
              color="#14569E"
            />
          </inline-form-field-edit>

          <div>
            <simple-button
              text="Copy Coupon Info"
              class="cm-capitalize cm-text-base cm-font-semibold"
              color="#14569E"
              size="large"
              @click="copyCouponInfo"
            />
          </div>
          
        </v-col>
        <v-col cols="12" sm="6" class="pt-0 pl-0 pl-sm-2">
          <simple-list title="Current Status" class="pl-0 pl-sm-1">
            <simple-chip
              class="cm-mt-1"
              :text="form.displayStatus"
              data-cy="cypress-details-status-chip"
              :textColor="statusTextColor"
              :class="statusColorClass"
              :variant="statusVariant"
            />
          </simple-list>
        </v-col>
      </v-row>
    </simple-card>
  </div>
</template>
<script>
import { ref, computed, watch, toRefs, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth.ts";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import {
  RULE_REQUIRED,
  RULE_MAX_NUMBER,
  RULE_VALID_DATE,
  RULE_FUTURE_DATE,
  RULE_VALID_COUPON_CODE,
  RULE_MIN_NUMBER,
  RULE_QUANTITY_GREATER_THAN_ONE,
} from "@/constants/rules.js";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleChip from "@/components/ui/SimpleChip.vue";
import SimpleList from "@/components/ui/SimpleList.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import InlineFormFieldEdit from "@/components/couponManager/forms/InlineFormFieldEdit.vue";
import CouponTypesSelectInput from "@/components/couponManager/forms/CouponTypesSelectInput.vue";
import StoreAutocompleteInput from "@/components/couponManager/forms/StoreAutocompleteInput.vue";
import CouponFormNameField from "@/components/couponManager/forms/couponForm/fields/CouponFormNameField.vue";
import CouponFormStartDateField from "@/components/couponManager/forms/couponForm/fields/CouponFormStartDateField.vue";
import CouponFormEndDateField from "@/components/couponManager/forms/couponForm/fields/CouponFormEndDateField.vue";
import CouponFormCouponCodeField from "@/components/couponManager/forms/couponForm/fields/CouponFormCouponCodeField.vue";
import CouponFormNewCustomersOnlyField from "@/components/couponManager/forms/couponForm/fields/CouponFormNewCustomersOnlyField.vue";
import CouponFormMinBasketAmountField from "@/components/couponManager/forms/couponForm/fields/CouponFormMinBasketAmountField.vue";
import CouponFormRedemptionsLimitField from "@/components/couponManager/forms/couponForm/fields/CouponFormRedemptionsLimitField.vue";
import CouponFormDiscountLimitField from "@/components/couponManager/forms/couponForm/fields/CouponFormDiscountLimitField.vue";
import CouponFormDiscountValueField from "@/components/couponManager/forms/couponForm/fields/CouponFormDiscountValueField.vue";
import CouponFormPercentageValueField from "@/components/couponManager/forms/couponForm/fields/CouponFormPercentageValueField.vue";
import CouponExcludedItems from "@/components/couponManager/forms/CouponExcludedItems.vue";
import CouponFreeItems from "@/components/couponManager/forms/CouponFreeItems.vue";
import CouponFormExcludeAlcoholField from "@/components/couponManager/forms/couponForm/fields/CouponFormExcludeAlcoholField.vue";
import CouponFormExcludeTabaccoField from "@/components/couponManager/forms/couponForm/fields/CouponFormExcludeTabaccoField.vue";

import moment from "moment";
export default {
  name: "CouponDetails",
  components: {
    SimpleCard,
    SimpleChip,
    SimpleList,
    SimpleButton,
    InlineFormFieldEdit,
    StoreAutocompleteInput,
    CouponTypesSelectInput,
    CouponFormNameField,
    CouponFormStartDateField,
    CouponFormEndDateField,
    CouponFormCouponCodeField,
    CouponFormNewCustomersOnlyField,
    CouponFormMinBasketAmountField,
    CouponFormRedemptionsLimitField,
    CouponFormDiscountLimitField,
    CouponFormDiscountValueField,
    CouponFormPercentageValueField,
    CouponExcludedItems,
    CouponFreeItems,
    CouponFormExcludeAlcoholField,
    CouponFormExcludeTabaccoField,
  },
  props: {
    coupon: {
      type: Object,
    },
    editMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  setup(props) {
    const { coupon: form, editMode } = toRefs(props);
    const { mode } = storeToRefs(useCouponStore());
    const formValid = ref(false);
    const { isSuperAdmin } = storeToRefs(useAuthStore());

    const rules = ref({
      name: [RULE_REQUIRED],
      type: [RULE_REQUIRED],
      startDate: [RULE_VALID_DATE, RULE_FUTURE_DATE],
      endDate: [RULE_REQUIRED, RULE_VALID_DATE, RULE_FUTURE_DATE],
      minBasketAmount: [RULE_REQUIRED, RULE_MIN_NUMBER(0)],
      redemptionsLimit: [
        RULE_REQUIRED,
        RULE_MIN_NUMBER(1),
        RULE_MAX_NUMBER(500),
      ],
      couponCode: [RULE_REQUIRED, RULE_VALID_COUPON_CODE],
      percentageValue: [
        RULE_REQUIRED,
        RULE_MIN_NUMBER(1),
        RULE_MAX_NUMBER(100),
      ],
      discountLimit: [RULE_MAX_NUMBER(50)],
      discountValue: [RULE_REQUIRED, RULE_MIN_NUMBER(0)],
      specificBarcodes: [
        (v) =>
          form.value.itemRestrictions.specificBarcodes.length !== 0 ||
          "Barcode is required",
      ],
      stores: [RULE_REQUIRED, RULE_QUANTITY_GREATER_THAN_ONE],
    });

    onMounted(() => {
      mode.value = editMode.value ? "edit" : null;
    });

    onBeforeUnmount(() => {
      mode.value = null;
    });

    const getBarcodes = computed(() => {
      let barcodes = form.value.itemRestrictions.specificBarcodes;

      if (barcodes && barcodes.length > 0) {
        return barcodes.join(", ");
      } else {
        return "No barcodes available.";
      }
    });

    const couponValue = computed(() => {
      const types = {
        percent: form.value.rewardRestrictions.value + "% Off",
        set: "£" + form.value.rewardRestrictions.value,
        freedel: "Free Delivery",
        freeitem: `Free Item - Item name to be added`,
      };
      return types[form.value.type];
    });

    const couponQuantity = computed(() => {
      return String(form.value.amountToGenerate);
    });

    const timesUsed = computed(() => {
      return String(form.value.timesRedeemed);
    });

    const statusColorClass = computed(() => {
      const colors = {
        Draft: "cm-bg-orange-500",
        Upcoming: "cm-bg-orange-500",
        Deactivated: "cm-bg-red-600",
        Expired: "cm-bg-red-600",
        Live: "cm-bg-green-600",
        Unsaved: "cm-bg-white",
      };
      return colors[form.value.displayStatus] || colors.draft;
    });

    const statusTextColor = computed(() => {
      if (form.value.displayStatus === "Unsaved") {
        return "black";
      }
      return "white";
    });

    const statusVariant = computed(() => {
      if (form.value.displayStatus === "Unsaved") {
        return "outlined";
      }
      return null;
    });

    const storeName = computed(() => {
      if (form.value?.locationRestrictions?.stores.length) {
        return form.value?.locationRestrictions?.stores
          .map((store) => store.name)
          .join(",");
      }
      return "All Stores";
    });

    const discountType = computed(() => {
      const types = {
        percent: "Percentage Off",
        set: "Set Discount",
        freedel: "Free delivery",
        freeitem: "Free item",
      };
      return types[form.value.type];
    });

    const isDiscountTypePercent = computed(() => {
      return Boolean(form.value.type === "percent");
    });

    const isDiscountTypeSetDiscount = computed(() => {
      return Boolean(form.value.type === "set");
    });

    const isDiscountTypeFreeItem = computed(() => {
      return Boolean(form.value.type === "freeitem");
    });

    const showBulkExcludeFields = computed(() => {
      return (
        (mode.value === "create" && editMode.value) ||
        (form?.value?.categoryExclusions?.length &&
          mode.value === "edit" &&
          editMode.value)
      );
    });

    const formattedStartDate = computed({
      set(value) {
        form.value.startDate = moment(value).format("YYYY-MM-DD");
      },
      get() {
        return form.value.startDate;
      },
    });

    const formattedEndDate = computed({
      set(value) {
        form.value.endDate = moment(value).format("YYYY-MM-DD");
      },
      get() {
        return form.value.endDate;
      },
    });

    const copyCouponInfo = () => {

      const str = "i. Coupon Code: " + form.value.code + "\n" +
        "ii. " + (form.value.customerRestrictions.isForNewCustomers ? "New customers only" : "Exisiting customers") + ".\n" + 
        "iii. Select stores only.\n" + 
        "iv. Min spend £" + form.value.rewardRestrictions.minimumSpend + ".\n" + 
        "v. Max discount £" + form.value.rewardRestrictions.maxDiscount + ".\n" + 
        "vi. Excludes tobacco & vapes.\n" + 
        "vii. Excludes alcohol.\n" + 
        "viii. Minimum store spend may apply.\n" + 
        "ix. Discount Excludes Fee's & Charges.\n" + 
        "x. Apply code at Checkout.\n" + 
        "xi. Promotion is subject to change.\n" + 
        "xii. Expires " + moment(form.value.endDate).format("DD/MM/YY"); + "."

      navigator.clipboard.writeText(str)
        .then(() => {
          alert("Coupon info copied");
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
          alert('Could not copy text to clipboard. Please try again.');
        });
    };

    return {
      form,
      rules,
      formValid,
      discountType,
      couponQuantity,
      timesUsed,
      couponValue,
      statusColorClass,
      statusTextColor,
      statusVariant,
      storeName,
      showBulkExcludeFields,
      isDiscountTypePercent,
      isDiscountTypeSetDiscount,
      isDiscountTypeFreeItem,
      getBarcodes,
      isSuperAdmin,
      formattedStartDate,
      formattedEndDate,
      copyCouponInfo,
    };
  },
};
</script>
