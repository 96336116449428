import { defineStore } from "pinia";
import authApi from "@/utils/authApi.js";
import { getViteBusinessId } from "../../config.js";

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      businessId: null,
      accessToken: null,
      isSuperAdmin: 0,
    };
  },
  getters: {
    isLoggedIn(state) {
      return Boolean(state.accessToken);
    },
    authorizationToken(state) {
      return `Bearer ${state.accessToken}`;
    },
  },
  actions: {
    async getAccessToken() {
      try {
        const { access_token } = await authApi.getToken();
        return access_token;
      } catch (e) {
        throw new Error(e.message);
      }
    },
    async authenticate(accessToken, businessId, isSuperAdmin) {
      try {
        if (accessToken && businessId) {
          this.accessToken = accessToken;
          this.businessId = Number(businessId);
          this.isSuperAdmin = Number(isSuperAdmin);
          return;
        }

        this.accessToken = await this.getAccessToken();
        this.businessId = Number(getViteBusinessId());
      } catch (e) {
        throw new Error(e.message);
      }
    },
  },
});
