<template>
  <v-form
    data-cy="cypress-loyalty-rewards-form-step-one"
    validate-on="input"
    ref="formRef"
    v-model="formValid"
    @submit.prevent="handleSubmit"
    class="cm-flex cm-flex-col"
  >
    <simple-card
      color="white"
      elevation="3"
      class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
      closabled
      @close="handleCancel"
    >
      <template #header>
        <div
          class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary"
        >
          Copy & Replace Rewards
        </div>
      </template>
      <form>
        <store-autocomplete-input
          v-model="stores"
          :rules="rules.stores"
          :hide-details="formValid === null"
          :isSuperAdmin="isSuperAdmin"
          class="mb-7"
          data-cy="cypress-form-step-one-stores-field"
        />
      </form>
      <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">
        <template #leftButton>
          <simple-button
            class="cm-capitalize cm-text-base cm-font-semibold"
            color="#14569E"
            variant="secondary"
            size="large"
            text="Cancel"
            @click="handleCancel"
            data-cy="cypress-form-step-one-cancel-button"
          ></simple-button>
        </template>
        <template #rightButton>
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ props }">
              <simple-button
                v-bind="props"
                class="cm-capitalize cm-text-base cm-font-semibold"
                color="#14569E"
                size="large"
                text="Copy & Replace"
                data-cy="cypress-form-step-copy-button"
                :disabled="isDisabled"
              ></simple-button>
            </template>

            <simple-card
              color="white"
              elevation="3"
              class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
              closabled
              @close="dialog = false"
            >
              <div
                class="cm-text-center cm-font-bold cm-mb-5 cm-text-2xl cm-text-[#E92318]"
              >
                Warning
                <v-icon
                  class="mb-1"
                  icon="mdi-alert-outline"
                  size="small"
                ></v-icon>
              </div>
              <div
                class="cm-text-center cm-font-regular cm-mb-5 cm-text-lg cm-text-base"
              >
                This action will copy and replace the rewards for the
                selected store(s). Are you sure you want to continue?
              </div>

              <v-card-actions>
                <simple-button
                  class="cm-capitalize cm-text-base cm-font-semibold"
                  color="#E92318"
                  size="large"
                  text="Copy & Replace"
                  data-cy="cypress-form-step-copy-button"
                  :disabled="isDisabled"
                  @click="handleSubmit"
                ></simple-button>
              </v-card-actions>
            </simple-card>
          </v-dialog>
        </template>
      </bottom-navigation>
    </simple-card>
  </v-form>
</template>
<script>
import { ref, toRaw, computed } from "vue";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";
import { useLoyaltyRewardsNavigationStore } from "@/stores/LoyaltyRewards/loyaltyRewardsNavigation.ts";
import { useLoyaltyRewardsRewardStore } from "@/stores/LoyaltyRewards/reward.ts";
import { useLoyaltyRewardsStore } from "@/stores/LoyaltyRewards/store.ts";
import { useAuthStore } from "@/stores/auth.ts";
import {
  RULE_REQUIRED,
  RULE_QUANTITY_GREATER_THAN_ONE,
} from "@/constants/rules.js";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import StoreAutocompleteInput from "@/components/loyaltyRewards/forms/StoreAutocompleteInput.vue";
export default {
  name: "LoyaltyRewardsCopyFormView",
  components: {
    SimpleCard,
    SimpleButton,
    BottomNavigation,
    StoreAutocompleteInput,
  },
  setup() {
    const navigationLoyaltyRewardsStore = useLoyaltyRewardsNavigationStore();
    const loyaltyRewardsRewardStore = useLoyaltyRewardsRewardStore();
    const loyaltyRewardsStore = useLoyaltyRewardsStore();
    const { isSuperAdmin } = storeToRefs(useAuthStore());

    const form = ref({ ...loyaltyRewardsRewardStore.reward });
    const formValid = ref(false);
    const formRef = ref(null);
    const toast = useToast();
    const dialog = ref(false);

    const rules = ref({
      stores: [RULE_REQUIRED, RULE_QUANTITY_GREATER_THAN_ONE],
      type: [RULE_REQUIRED],
    });

    const stores = ref([]);

    const handleSubmit = async () => {
      try {
        const { valid } = await formRef.value.validate();
        if (!valid) {
          return;
        }

        dialog.value = false;

        loyaltyRewardsStore.destinationStores = stores.value.map(
          (store) => store.id
        );

        await loyaltyRewardsRewardStore.copyFromStoreToStore(
          loyaltyRewardsStore.store.id,
          { destinationStoreIds: loyaltyRewardsStore.destinationStores }
        );
        navigationLoyaltyRewardsStore.rewardCopiedSuccessfully = true;
        navigationLoyaltyRewardsStore.viewLoyaltyRewardsSuccess();
      } catch (e) {
        toast.error(e, {
          timeout: 2000,
        });
      }
    };

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    const handleCancel = () => {
      navigationLoyaltyRewardsStore.viewDashboard();
    };

    return {
      form,
      isDisabled,
      handleSubmit,
      handleCancel,
      formValid,
      formRef,
      rules,
      isSuperAdmin,
      stores,
      dialog,
    };
  },
};
</script>
