<template>
  <v-text-field
    variant="outlined"
    :model-value="value"
    @input="updateSetting"
    data-cy="cypress-white-label-manager-textfield-field"
    v-bind="$attrs"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  setup(_, { emit }) {
    const updateSetting = (event) => {
      emit("onValueUpdate", event.target.value);
    };

    return {
      updateSetting,
    };
  },
};
</script>
