import { defineStore } from "pinia";

export const useCopyProductsToMenuGroupsStore = defineStore(
  "copyProductsToMenuGroupsStore",
  {
    state: () => {
      return {
        view: "copy",
      };
    },
    getters: {},
    actions: {
      viewCopyProductsView() {
        this.view = "copy";
      },
      viewDeleteProductsView() {
        this.view = "removal";
      },
    },
  }
);
