import { defineStore } from "pinia";
import api from "../../utils/api.js";

export const useServices = defineStore({
  id: "services-store",
  state: () => {
    return {
      services: {},
    };
  },
  getters: {
    getServices: (state) => state.services,
  },
  actions: {
    updateServices(value) {
      this.services = value;
    },
    async updateServicesInfo(id, payload) {
      try {
        const response = api.retailersDashboard.retailers.update(id, payload);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getServicesInfo(id) {
      try {
        const response = await api.retailersDashboard.retailers.get(id);

        const { retailer } = response.data.value;
        this.updateServices(retailer.services);
      } catch (e) {
        throw new Error(e.message);
      }
    },
  },
});
