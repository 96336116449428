<template>
  <div>
    <simple-card
      color="white"
      elevation="3"
      class="cm-pa-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
    >
      <template #header>
        <div class="cm-text-center cm-font-bold md:cm-hidden">{{ title }}</div>
      </template>
      <a
        class="cm-block cm-text-center cm-underline cm-text-primary cm-mb-4 md:cm-hidden"
        href="#"
        @click.prevent="changeTab"
      >
        {{ toggleTitle }}
      </a>

      <div
        class="cm-justify-between cm-hidden md:cm-flex cm-mb-4 cm-items-center"
      >
        <div class="d-flex align-center justify-center mb-3 mb-lg-0">
          <a
            :class="`mr-3 ${
              tab !== 'latest' ? 'cm-underline cm-text-[#14569E]' : ''
            } cm-font-${tab === 'latest' ? 'bold' : 'regular'}`"
            data-cy="cypress-latest-coupons-button"
            href="#"
            @click.prevent="tab = 'latest'"
          >
            Latest Coupons
          </a>
          |
          <a
            :class="`ml-3 ${
              tab === 'latest' ? 'cm-underline cm-text-[#14569E]' : ''
            } cm-font-${tab !== 'latest' ? 'bold' : 'regular'}`"
            data-cy="cypress-top-coupons-button"
            href="#"
            @click.prevent="tab = 'top'"
            >Top Performing Coupons
          </a>
        </div>
        <div>
          <search-results-table-search />
        </div>
      </div>
      <search-results-table
        v-if="tab === 'top'"
        :options="topTableOptions"
        :showPagination="false"
        data-cy="cypress-top-coupons-table"
      />
      <search-results-table
        v-if="tab === 'latest'"
        :options="latestTableOptions"
        data-cy="cypress-latest-coupons-table"
      />
    </simple-card>
  </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import SearchResultsTable from "@/components/couponManager/tables/SearchResultsTable.vue";
import SearchResultsTableSearch from "@/components/couponManager/tables/SearchResultsTableSearch.vue";
import SimpleCard from "@/components/ui/SimpleCard.vue";
export default {
  name: "DashboardTableCard",
  components: {
    SearchResultsTable,
    SearchResultsTableSearch,
    SimpleCard,
  },
  setup() {
    const TOP_PERFORMING_LABEL = "Top 5 performing Coupons";
    const LATEST_LABEL = "Your Latest Coupons";
    const tab = ref("latest");
    const topTableOptions = ref({
      limit: 5,
      orderBy: "redeemed",
    });
    const latestTableOptions = ref({
      limit: 5,
    });

    const title = computed(() => {
      if (tab.value === "top") {
        return TOP_PERFORMING_LABEL;
      }
      return LATEST_LABEL;
    });

    const toggleTitle = computed(() => {
      if (tab.value === "latest") {
        return TOP_PERFORMING_LABEL;
      }
      return LATEST_LABEL;
    });

    const changeTab = () => {
      tab.value = tab.value === "top" ? "latest" : "top";
    };

    return {
      tab,
      title,
      toggleTitle,
      topTableOptions,
      latestTableOptions,
      changeTab,
    };
  },
};
</script>
