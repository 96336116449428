<template>
  <simple-card
    data-cy="cypress-coupon-review-view"
    color="white"
    elevation="3"
    class="cm-p-4 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible cm-h-full md:cm-h-auto"
    closabled
    @close="handleCancel"
  >
    <template #header>
      <div
        class="cm-text-center cm-text-2xl cm-text-primary cm-font-bold cm-mb-2"
      >
        Create New Coupon
      </div>
      <div class="cm-text-center cm-text-xl cm-font-bold cm-mb-2">
        Review & Publish
      </div>
    </template>
    <v-form lazy-validation v-bind="$attrs" v-model="formValid" ref="formRef">
      <coupon-details edit-mode :coupon="coupon" />
    </v-form>
    <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">
      <template #leftButton>
        <simple-button
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#14569E"
          size="large"
          text="Save Draft"
          :loading="saveDraftLoading && !publishLoading"
          @click="handleSaveDraft"
          :disabled="isDisabled"
          data-cy="cypress-coupon-review-save-draft-button"
        ></simple-button>
      </template>
      <template #rightButton>
        <simple-button
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#188D6F"
          size="large"
          text="Publish Live"
          :loading="publishLoading"
          @click="handlePublishLive"
          :disabled="isDisabled"
          data-cy="cypress-coupon-review-publish-live-button"
        ></simple-button>
      </template>
    </bottom-navigation>
  </simple-card>
</template>
<script>
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import CouponDetails from "@/components/couponManager/cards/CouponDetails.vue";
import { useToast } from "vue-toastification";
import { couponPayload } from "@/utils/payloadHelper.js";
export default {
  name: "CouponReviewView",
  components: {
    SimpleButton,
    SimpleCard,
    BottomNavigation,
    CouponDetails,
  },
  setup() {
    const formValid = ref(false);
    const formRef = ref(null);
    const saveDraftLoading = ref(false);
    const publishLoading = ref(false);
    const navigationStore = useNavigationStore();
    const couponStore = useCouponStore();
    const { coupon } = storeToRefs(couponStore);
    const toast = useToast();

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    const handleSaveDraft = async () => {
      try {
        const { valid } = await formRef.value.validate();
        if (!valid) {
          return;
        }

        saveDraftLoading.value = true;

        await couponStore.createCoupon(couponPayload(coupon.value));

        navigateToCouponDetails();
        toast.success("Coupon Created!");
      } catch (e) {
        saveDraftLoading.value = false;
        throw e;
      }
    };

    const navigateToCouponDetails = async () => {
      couponStore.mode = "edit";
      return navigationStore.viewCouponDetails();
    };

    const handlePublishLive = async () => {
      try {
        publishLoading.value = true;
        await handleSaveDraft();
        await couponStore.activateCoupon();

        toast.success("Coupon Activated!", {
          timeout: 2000,
        });
      } catch (e) {
        publishLoading.value = false;
        toast.error(e, {
          timeout: 2000,
        });
      }
    };

    const handleCancel = () => {
      return navigationStore.viewDashboard();
    };

    return {
      saveDraftLoading,
      formValid,
      formRef,
      isDisabled,
      coupon,
      handleSaveDraft,
      handlePublishLive,
      handleCancel,
      publishLoading,
    };
  },
};
</script>
