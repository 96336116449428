<template>
  <simple-card
    data-cy="cypress-details-view"
    color="white"
    elevation="3"
    class="cm-p-4 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible cm-h-full md:cm-h-auto"
    closabled
    @close="handleClose"
  >
    <template #header>
      <div
        data-cy="cypress-coupon-details-name"
        class="cm-text-center cm-text-2xl cm-text-primary cm-font-bold cm-mb-2"
      >
        {{ title }}
      </div>
    </template>

    <v-form lazy-validation v-bind="$attrs" v-model="formValid" ref="formRef">
      <coupon-details :editMode="isEditMode" v-if="coupon" :coupon="coupon" />
    </v-form>
    <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">
      <template #leftButton>
        <simple-button
          v-if="!isEditMode"
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#188D6F"
          size="large"
          text="Edit"
          data-cy="cypress-details-edit-button"
          prepend-icon="mdi-pencil"
          @click="handleEdit"
        ></simple-button>
        <simple-button
          v-else
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#14569E"
          size="large"
          text="Save Changes"
          :loading="saveChangesLoading"
          data-cy="cypress-details-save-changes-button"
          :disabled="isDisabled"
          prepend-icon="mdi-pencil"
          @click="handleSaveChanges"
        ></simple-button>
      </template>
      <template #rightButton>
        <simple-button
          v-if="!isEditMode"
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#14569E"
          size="large"
          data-cy="cypress-details-close-button"
          variant="secondary"
          text="Close"
          @click="handleClose"
        ></simple-button>
        <simple-button
          v-if="showPublishButton"
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#188D6F"
          size="large"
          :loading="publishLoading"
          text="Publish"
          data-cy="cypress-details-publish-button"
          @click="handlePublish"
        ></simple-button>
        <simple-button
          v-if="showUnpublishButton"
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#E42034"
          size="large"
          text="Unpublish"
          :loading="unpublishLoading"
          data-cy="cypress-details-unpublish-button"
          @click="handleUnpublish"
        ></simple-button>
      </template>
    </bottom-navigation>
  </simple-card>
</template>
<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import CouponDetails from "@/components/couponManager/cards/CouponDetails.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import { couponPayload } from "@/utils/payloadHelper.js";
export default {
  name: "CouponDetailsView",
  components: {
    CouponDetails,
    SimpleButton,
    SimpleCard,
    BottomNavigation,
  },
  setup() {
    const toast = useToast();
    const navigationStore = useNavigationStore();
    const couponStore = useCouponStore();
    const { coupon, mode } = storeToRefs(couponStore);
    const formValid = ref(false);
    const formRef = ref(null);
    const title = ref(null);
    const saveChangesLoading = ref(false);
    const publishLoading = ref(false);
    const unpublishLoading = ref(false);

    const isEditMode = computed(() => {
      return Boolean(mode.value === "edit");
    });

    const showPublishButton = computed(() => {
      return Boolean(mode.value === "edit" && coupon.value.status !== "live");
    });

    const showUnpublishButton = computed(() => {
      return Boolean(mode.value === "edit" && coupon.value.status === "live");
    });

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    onBeforeUnmount(() => {
      couponStore.$reset();
    });

    onMounted(async () => {
      try {
        await couponStore.getCoupon(couponStore.coupon.id);
        title.value = coupon.value.name;
      } catch (e) {
        toast.error(e, {
          timeout: 2000,
        });
      }
    });

    const handleClose = () => {
      return navigationStore.viewDashboard();
    };

    const handleEdit = () => {
      mode.value = "edit";
    };

    const handleSaveChanges = async () => {
      try {
        const { valid } = await formRef.value.validate();

        if (!valid) {
          return;
        }

        saveChangesLoading.value = true;

        let payload = couponPayload(coupon.value);
        delete payload.status;

        if (payload.startDate === null) {
          payload.startDate = "0000-00-00";
          payload.endDate = "0000-00-00";
        }

        await couponStore.updateCoupon(payload);

        saveChangesLoading.value = false;
        title.value = payload.name;
        toast.success("Coupon has been updated!", {
          timeout: 2000,
        });
        handleClose();
      } catch (e) {
        saveChangesLoading.value = false;
        toast.error(e.message);
      }
    };

    const handlePublish = async () => {
      try {
        publishLoading.value = true;
        await couponStore.activateCoupon();
        toast.success("Coupon is now live");
        publishLoading.value = false;
        handleClose();
      } catch (e) {
        publishLoading.value = false;
        toast.error(e.message);
      }
    };

    const handleUnpublish = async () => {
      try {
        unpublishLoading.value = true;
        await couponStore.deactivateCoupon();
        toast.success("Coupon is now deactivated");
        unpublishLoading.value = false;
        handleClose();
      } catch (e) {
        unpublishLoading.value = false;
        toast.error(e.message);
      }
    };

    return {
      saveChangesLoading,
      publishLoading,
      unpublishLoading,
      title,
      showPublishButton,
      showUnpublishButton,
      name,
      coupon,
      formValid,
      formRef,
      isEditMode,
      isDisabled,
      handleEdit,
      handleClose,
      handleSaveChanges,
      handlePublish,
      handleUnpublish,
    };
  },
};
</script>
