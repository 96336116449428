import { defineStore } from "pinia";
import api from "@/utils/api.js";

export const useLoyaltyRewardsRewardStore = defineStore("reward", {
  state: () => {
    return {
      mode: null,
      enableLoyaltyRewards: false,
      allStoresSelected: true,
      excludeAllTobaccoProducts: false,
      excludeAllAlcoholProducts: false,
      types: [],
      rewards: [],
      reward: {
        id: 1,
        type: {
          id: 1,
          name: "",
          description: "",
          configRequirements: [],
          image: [],
        },
        name: "",
        description: "",
        spendRequired: "",
        config: [],
        image: [],
        startsAt: "",
        expiresAt: "",
      },
    };
  },
  getters: {},
  actions: {
    async getRewards(storeId) {
      try {
        this.rewards = await api.loyaltyRewards.reward.getRewards(storeId);
      } catch {
        throw new Error("Unable to load rewards");
      }
    },
    async getReward(storeId, id) {
      try {
        this.reward = await api.loyaltyRewards.reward.getReward(storeId, id);
      } catch {
        throw new Error("Unable to view reward");
      }
    },
    async getTypes() {
      try {
        this.types = await api.loyaltyRewards.reward.getTypes();
      } catch {
        throw new Error("Unable to load types");
      }
    },
    async getExcludedBulkItems(storeId) {
      try {
        const { config } =
          await api.loyaltyRewards.reward.getLoyaltyRewardsProgram(storeId);

        this.excludeAllTobaccoProducts = config
          ? config?.progress?.excludeTobacco
          : null;
        this.excludeAllAlcoholProducts = config
          ? config?.progress?.excludeAlcohol
          : null;
      } catch {
        throw new Error("Unable to load excluded bulk items");
      }
    },
    async createReward(storeId, payload) {
      try {
        this.rewards.push(
          await api.loyaltyRewards.reward.create(storeId, payload)
        );
      } catch {
        throw new Error("Unable to create reward");
      }
    },
    async updateReward(storeId, payload) {
      try {
        this.reward = await api.loyaltyRewards.reward.update(
          storeId,
          this.reward.id,
          payload
        );
      } catch {
        throw new Error("Unable to update reward");
      }
    },
    async deleteReward(storeId, id) {
      try {
        await api.loyaltyRewards.reward.delete(storeId, id);

        this.rewards = this.rewards.filter((reward) => reward.id !== id);
      } catch {
        throw new Error("Unable to delete reward");
      }
    },
    async copyFromStoreToStore(storeId, payload) {
      try {
        const { data } = await api.loyaltyRewards.reward.copyFromStoreToStore(
          storeId,
          payload
        );
      } catch (e) {
        throw new Error(e);
      }
    },
    async getEnabledLoyalRewards(storeId) {
      try {
        const { enabled } =
          await api.loyaltyRewards.reward.getLoyaltyRewardsProgram(storeId);
        this.enableLoyaltyRewards = enabled;
      } catch {
        throw new Error("Unable to get enabled rewards");
      }
    },
    async enableLoyaltyRewardsAction(storeId, payload) {
      try {
        const { enabled } =
          await api.loyaltyRewards.reward.enableLoyaltyRewards(storeId, {
            enabled: payload,
          });
        this.enableLoyaltyRewards = enabled;
      } catch {
        throw new Error("Unable to enable rewards");
      }
    },
    async excludeBulkItems(storeId, payload) {
      try {
        await api.loyaltyRewards.reward.excludeBulkItems(storeId, {
          config: {
            progress: payload,
          },
        });
        // this.reward = data;
      } catch {
        throw new Error("Unable to exclude items");
      }
    },
    resetState() {
      this.mode = null;
      this.enableLoyaltyRewards = false;
      this.allStoresSelected = true;
      this.excludeAllTobaccoProducts = false;
      this.excludeAllAlcoholProducts = false;
      // this.types = [];
      this.rewards = [];
      this.reward = {
        id: 1,
        type: {
          id: 1,
          name: "",
          description: "",
          configRequirements: [],
          image: [],
        },
        name: "",
        description: "",
        spendRequired: "",
        config: [],
        image: [],
        startsAt: "",
        expiresAt: "",
      };
    }
  },
});
