import { defineStore } from "pinia";

export const useNavigationStore = defineStore("navigation", {
  state: () => {
    return {
      view: "dashboard",
    };
  },
  getters: {},
  actions: {
    viewDashboard() {
      this.view = "dashboard";
    },
    viewFulfilmentForm() {
      this.view = "fulfilment-form";
    },
  },
});
