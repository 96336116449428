<template>
  <simple-card
    class="cm-pa-2 cm-rounded-lg"
    elevation="3"
    data-cy="cypress-create-card"
  >
    <template #header>
      <simple-card-title title="Schedule an update for your store" class="mb-5" />
    </template>
    <simple-button
      data-cy="cypress-create-card-button"
      text="Schedule new update"
      class="cm-capitalize cm-text-base cm-font-semibold"
      color="#14569E"
      size="large"
      prepend-icon="mdi-plus"
      @click="handleClick"
    />
  </simple-card>
</template>
<script>
import { useScheduleNavigationStore } from "@/stores/ScheduleStoreUpdates/navigation.ts";
import { useScheduleStore } from "@/stores/ScheduleStoreUpdates/schedule.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleCardTitle from "@/components/ui/SimpleCardTitle.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
export default {
  name: "CreateCouponCard",
  components: {
    SimpleCard,
    SimpleButton,
    SimpleCardTitle,
  },
  setup() {
    const scheduleNavigationStore = useScheduleNavigationStore();
    const scheduleStore = useScheduleStore();

    const handleClick = () => {
      scheduleStore.$reset();
      scheduleNavigationStore.viewScheduleForm();
    };

    return {
      handleClick,
    };
  },
};
</script>
