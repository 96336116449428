<template>
  <div class="cm-mb-6">
    <loyalty-rewards-form-free-item-field
      @onProductAdded="handleProductAdded"
      label="Search For Your Free Item"
      placeholder="Add Name for free item to add to reward"
      v-bind="$attrs"
      data-cy="cypress-form-step-two-free-item-field"
    />
    <div class="md:-cm-m-2 mt-0">
      <div
        class="md:cm-inline-block md:cm-m-2 cm-mb-4"
        v-for="product in (editMode ? loyaltyRewardsReward.reward.config.itemIds : form.config.itemIds)"
        :key="product.id"
      >
        <closable-chip
          textColor="#188D6F"
          :text="
            typeof product === 'string' ? String(product) : String(product.name)
          "
          :image="product.image"
          class="cm-w-full"
          @closeChip="handleProductRemoval(product)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import LoyaltyRewardsFormFreeItemField from "@/components/loyaltyRewards/forms/loyaltyRewardsForm/fields/LoyaltyRewardsFormFreeItemField.vue";
import ClosableChip from "@/components/ui/ClosableChip.vue";
import { useLoyaltyRewardsRewardStore } from "@/stores/LoyaltyRewards/reward.ts";
export default {
  name: "LoyaltyRewardsFreeItems",
  components: {
    ClosableChip,
    LoyaltyRewardsFormFreeItemField,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const loyaltyRewardsReward = useLoyaltyRewardsRewardStore();
    const form = ref({ ...loyaltyRewardsReward.reward });

    onMounted(() => {
      if(!props.editMode) {
        form.value.config.itemIds = [];
      }
    });

    const handleProductAdded = (product) => {
      if (form.value.config.itemIds) {
        loyaltyRewardsReward.reward.config.itemIds = [product];
        form.value.config.itemIds = [product];
      } else {
        loyaltyRewardsReward.reward.config.itemIds = [product];
        form.value.config.itemIds = [product];
      }
    };

    const handleProductRemoval = (product) => {
      form.value.config.itemIds = form?.value?.config?.itemIds?.filter(
        (item) => item.id !== product.id
      );
      loyaltyRewardsReward.reward.config.itemIds = form?.value?.config?.itemIds;
    };
    return {
      form,
      handleProductAdded,
      handleProductRemoval,
      loyaltyRewardsReward,
    };
  },
};
</script>
