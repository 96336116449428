<template>
  <v-list lines="one">
    <v-list-item v-bind="$attrs">
      <template #title>
        <v-list-item-title class="font-weight-bold cm-text-sm">
          {{ title }}
        </v-list-item-title>
      </template>
      <template #subtitle>
        <v-list-item-subtitle
          :style="{ opacity: 1 }"
          class="font-weight-medium cm-text-base"
        >
          {{ subtitle }}
        </v-list-item-subtitle>
      </template>
      <slot />
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  name: "SimpleList",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: [String, Number],
      default: "",
    },
  },
  setup() {
    return {};
  },
};
</script>
