<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-card class="pa-3" elevation="3">
    <div class="d-block pa-3">
      <div class="cm-text-2xl font-weight-bold cm-text-black mb-1">
        Self Onboarding
      </div>
    </div>
    <v-row class="pa-3">
      <v-col cols="12" sm="3" class="pb-0 pb-sm-3">
        <v-select
          v-model="selectedStatus"
          density="compact"
          label="Status"
          :items="['Show All', 'Live', 'Complete', 'Incomplete']"
          variant="outlined"
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="9">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          placeholder="Search by name, email, address..."
          density="compact"
          label="Search"
          variant="outlined"
          hide-details
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table-server
      v-model:page="current_page"
      class="cm-relative"
      :headers="headers"
      :items="items"
      :items-length="meta.total"
      :loading="loading"
      :items-per-page="5"
      hide-default-footer
    >
      <template #item.status="{ item }">
        <v-icon :color="statusColor(item.selectable.status)">mdi-circle</v-icon>
      </template>
      <template #item.name="{ item }">
        <a
          href="#"
          @click.prevent="handleNextView(item)"
          class="cm-underline cm-lowercase cm-text-[#FD6719]"
        >
          <span class="cm-underline cm-normal-case">
            {{ item.selectable.name }}</span
          >
        </a>
      </template>
      <template #item.address.address1="{ item }">
        <span>{{ item.selectable.address.address1 }}</span>
      </template>
      <!-- TODO:re-add it once data is available -->
      <!-- <template #item.contact.name="{ item }">
        <span>{{ item.selectable.contact.name }}</span>
      </template> -->
      <template #item.contact.phone="{ item }">
        <span>{{ item.selectable.contact.phone }}</span>
      </template>
      <template #item.contact.email="{ item }">
        <div>
          <a
            class="cm-text-[#0D49E1]"
            :href="`mailto:${item.selectable.contact.email}`"
          >
            <span class="cm-underline cm-normal-case">
              {{ item.selectable.contact.email }}</span
            >
          </a>
        </div>
      </template>
      <template #item.crmLink="{ item }">
        <a
          v-if="item.selectable.crmLink"
          :href="item.selectable.crmLink"
          class="cm-no-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon class="ml-1" color="#FD6719"> mdi-web </v-icon>
        </a>
      </template>
      <template #bottom>
        <div
          class="text-center pt-2 cm-:absolute cm-left-0 cm-right-0 cm-bottom-0"
        >
          <v-pagination
            v-model="current_page"
            rounded="square"
            :length="meta.last_page"
            :total-visible="smAndDown ? 3 : 7"
          ></v-pagination>
        </div>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script>
import { watch, ref, onMounted, computed } from "vue";
import { VDataTableServer } from "vuetify/labs/components";
import { useRetailerStore } from "@/stores/RetailersDashboard/retailer.ts";
import { useRetailersNavigationStore } from "@/stores/RetailersDashboard/retailersNavigation.ts";
import { useDisplay } from "vuetify";

import debounce from "lodash.debounce";
export default {
  components: {
    VDataTableServer,
  },
  setup() {
    const retailerStore = useRetailerStore();
    const navigation = useRetailersNavigationStore();
    const { smAndDown } = useDisplay();
    const loading = ref(false);
    const current_page = ref(1);
    const meta = ref({
      current_page: 1,
      total: 0,
      pageCount: 0,
      per_page: 5,
    });
    const search = ref("");
    const selectedStatus = ref("Show All");
    const items = ref([]);

    const headers = computed(() => {
      return [
        {
          title: "Status",
          align: "start",
          sortable: false,
          key: "status",
        },
        { title: "Name", key: "name" },
        { title: "Location", key: "address.address1" },
        // TODO:re-add it once data is available
        // { title: "Contact", key: "contact.name" },
        { title: "Number", key: "contact.phone" },
        {
          title: "Email",
          key: "contact.email",
        },
        { title: "CRM Link", key: "crmLink" },
      ];
    });

    const statusColor = (status) => {
      if (status === "live") {
        return "#3BC855";
      } else if (status === "complete") {
        return "#FDBC50";
      } else {
        return "#FC5555";
      }
    };

    watch(
      [search, selectedStatus, current_page],
      debounce(async () => {
        try {
          loading.value = true;
          const response = await retailerStore.list(
            search.value === null ? "" : search.value,
            selectedStatus.value === "Show All"
              ? ""
              : selectedStatus.value.toLowerCase(),
            current_page.value + 1,
            meta.value.per_page
          );
          const { data, meta: pagination } = response;
          items.value = data;
          meta.value = pagination;

          loading.value = false;
        } catch (e) {
          console.log("Error fetching data", e);
        }
      }, 300),
      { immediate: true }
    );

    const handleNextView = (item) => {
      retailerStore.retailerId = item.selectable.id;
      navigation.viewRetailersDashboardDetails();
    };

    onMounted(async () => {
      try {
        const response = await retailerStore.list();
        const { data, meta: pagination } = await response;

        items.value = data;
        meta.value = pagination;
      } catch (e) {
        console.log("e", e);
      }
    });
    return {
      current_page,
      meta,
      headers,
      items,
      statusColor,
      search,
      selectedStatus,
      loading,
      smAndDown,
      handleNextView,
    };
  },
};
</script>
