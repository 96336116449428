import { rest } from "msw";

// Mocked handlers for the Loyalty Rewards API
export default [
  rest.get(
    "/api/loyalty-rewards/stores/:storeId/loyalty/tiers",
    (req, res, ctx) => {
      return res(
        ctx.json({
          data: [
            {
              id: 1,
              type: {
                id: 1,
                name: "Free Delivery",
                description: "One free delivery, not much to add here.",
                configRequirements: [],
                image: [""],
              },
              name: "A Free Delivery",
              description: "A Free Delivery!",
              spendRequired: "30.00",
              config: [],
              image: [],
              startsAt: "2023-01-01T09:00:00+00:00",
              expiresAt: "2033-12-31T23:59:59+00:00",
            },
            {
              id: 2,
              type: {
                id: 2,
                name: "Free Item/Product",
                description: "Free item added to an order.",
                configRequirements: {
                  itemIds: "itemIds:required",
                },
                image: [""],
              },
              name: "A Free Item",
              description: "A Free Item!",
              spendRequired: "50.00",
              config: {
                items: [
                  {
                    barcode: "5000143555271",
                    id: 34381,
                    image: {
                      mdpi_1x:
                        "https://www.snappyshopper.co.uk/uploads/images/products/mdpi_1x/hungrrr_amberleaftobacco50g_1525253319al30g.jpg",
                      originals:
                        "https://www.snappyshopper.co.uk/uploads/images/products/xxhdpi_3x/hungrrr_amberleaftobacco50g_1525253319al30g.jpg",
                      xhdpi_2x:
                        "https://www.snappyshopper.co.uk/uploads/images/products/xhdpi_2x/hungrrr_amberleaftobacco50g_1525253319al30g.jpg",
                      xxhdpi_3x:
                        "https://www.snappyshopper.co.uk/uploads/images/products/xxhdpi_3x/hungrrr_amberleaftobacco50g_1525253319al30g.jpg",
                    },
                    name: "Amber Leaf tobacco 50g",
                  },
                ],
              },
              image: [],
              startsAt: "2023-01-01T09:00:00+00:00",
              expiresAt: "2033-12-31T23:59:59+00:00",
            },
            {
              id: 3,
              type: {
                id: 3,
                name: "Money off",
                description: "Fixed amount discount.",
                configRequirements: {
                  amount: "amount:required",
                },
                image: [""],
              },
              name: "£10 Off",
              description: "£10 Off!",
              spendRequired: "100.00",
              config: {
                amount: "10.00",
              },
              image: [],
              startsAt: "2023-01-01T09:00:00+00:00",
              expiresAt: "2033-12-31T23:59:59+00:00",
            },
            {
              id: 4,
              type: {
                id: 4,
                name: "% discount",
                description:
                  "Enter discount percentage. Optional - maximum discount amount.",
                configRequirements: {
                  discount: "percent:required",
                  max: "amount",
                },
                image: [""],
              },
              name: "10% Discount up to £15",
              description: "20% Discount!",
              spendRequired: "150.00",
              config: {
                discount: 10,
                max: "15.00",
              },
              image: [],
              startsAt: "2023-01-01T09:00:00+00:00",
              expiresAt: "2033-12-31T23:59:59+00:00",
            },
            {
              id: 5,
              type: {
                id: 4,
                name: "% discount",
                description:
                  "Enter discount percentage. Optional - maximum discount amount.",
                configRequirements: {
                  discount: "percent:required",
                  max: "amount",
                },
                image: [""],
              },
              name: "20% Discount",
              description: "20% Discount no limit!",
              spendRequired: "250.00",
              config: {
                discount: 20,
              },
              image: [],
              startsAt: "2023-01-01T09:00:00+00:00",
              expiresAt: "2033-12-31T23:59:59+00:00",
            },
          ],
        })
      );
    }
  ),
  rest.get(
    "/api/loyalty-rewards/stores/:storeId/loyalty/tiers/:id",
    (req, res, ctx) => {
      return res(
        ctx.json({
          data: {
            id: 1,
            type: {
              id: 1,
              name: "Free Delivery",
              description: "One free delivery, not much to add here.",
              configRequirements: [],
              image: [""],
            },
            name: "A Free Delivery",
            description: "A Free Delivery!",
            spendRequired: "30.00",
            config: [],
            image: [],
            startsAt: "2023-01-01T09:00:00+00:00",
            expiresAt: "2033-12-31T23:59:59+00:00",
          },
        })
      );
    }
  ),
  rest.post(
    "/api/loyalty-rewards/stores/:storeId/loyalty/tiers",
    (req, res, ctx) => {
      const {
        data: {
          id,
          type,
          name,
          description,
          spendRequired,
          config,
          image,
          startsAt,
          expiresAt,
          // excludedAlcohol,
          // excludedTobacco,
        },
      } = req.body;

      if (
        !type ||
        !name ||
        !description ||
        !spendRequired ||
        !startsAt ||
        !expiresAt
      ) {
        return res(
          ctx.status(400),
          ctx.json({
            error: "Invalid request body",
          })
        );
      }

      return res(
        ctx.status(201),
        ctx.json({
          data: {
            id,
            type,
            name,
            description,
            spendRequired,
            config,
            image,
            startsAt,
            expiresAt,
            // excludedAlcohol,
            // excludedTobacco,
          },
        })
      );
    }
  ),
  rest.put(
    "/api/loyalty-rewards/stores/:storeId/loyalty/tiers/:id",
    (req, res, ctx) => {
      const {
        data: {
          id,
          type,
          name,
          description,
          spendRequired,
          config,
          image,
          startsAt,
          expiresAt,
          // excludedAlcohol,
          // excludedTobacco,
        },
      } = req.body;

      if (
        !type ||
        !name ||
        !description ||
        !spendRequired ||
        !startsAt ||
        !expiresAt
      ) {
        return res(
          ctx.status(400),
          ctx.json({
            error: "Invalid request body",
          })
        );
      }

      return res(
        ctx.status(200),
        ctx.json({
          data: {
            id,
            type,
            name,
            description,
            spendRequired,
            config,
            image,
            startsAt,
            expiresAt,
            // excludedAlcohol,
            // excludedTobacco,
          },
        })
      );
    }
  ),
  rest.delete(
    "/api/loyalty-rewards/stores/:storeId/loyalty/tiers/:id",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({ success: true }));
    }
  ),
  rest.get("/api/loyalty-rewards/types", (req, res, ctx) => {
    return res(
      ctx.json({
        data: [
          {
            id: 1,
            name: "Free Delivery",
            description: "One free delivery, not much to add here.",
            configRequirements: [],
            image: [""],
          },
          {
            id: 2,
            name: "Free Item/Product",
            description: "Free item added to an order.",
            configRequirements: {
              itemIds: "itemIds:required",
            },
            image: [""],
          },
          {
            id: 3,
            name: "Money off",
            description: "Fixed amount discount.",
            configRequirements: {
              amount: "amount:required",
            },
            image: [""],
          },
          {
            id: 4,
            name: "% discount",
            description:
              "Enter discount percentage. Optional - maximum discount amount.",
            configRequirements: {
              discount: "percent:required",
              max: "amount",
            },
            image: [""],
          },
        ],
      })
    );
  }),
  rest.post(
    "/api/loyalty-rewards/stores/:storeId/tiers/copy",
    (req, res, ctx) => {
      const { data } = req.body;

      if (!data || !data.destinationStoreId) {
        return res(
          ctx.status(400),
          ctx.json({
            error: "Invalid request body",
          })
        );
      }

      return res(
        ctx.status(200),
        ctx.json({
          data: {
            success: true,
          },
        })
      );
    }
  ),
  rest.get(
    "/api/loyalty-rewards/stores/:storeId/loyalty/program",
    (req, res, ctx) => {
      return res(
        ctx.json({
          data: {
            enabled: false,
            config: {
              progress: {
                excludeAlcohol: false,
                excludeTobacco: true,
              },
            },
          },
        })
      );
    }
  ),
  rest.post(
    "/api/loyalty-rewards/stores/:storeId/loyalty/program",
    (req, res, ctx) => {
      const { data } = req.body;

      if (!data) {
        return res(
          ctx.status(400),
          ctx.json({
            error: "Invalid request body",
          })
        );
      }

      return res(
        ctx.status(200),
        ctx.json({
          data: {
            success: true,
          },
        })
      );
    }
  ),
];
