<template>
  <div data-cy="cypress-dashboard-view">
    <v-form
      data-cy="cypress-loyalty-rewards-dashboard-form"
      validate-on="input"
      ref="formRef"
      v-model="formValid"
      class="cm-flex cm-flex-col"
    >
      <simple-card color="white" elevation="0" class="cm-pa-4 cm-rounded-0 cm-bg-white cm-mb-6">
        <div
          data-cy="cypress-coupon-details-name"
          class="cm-text-left md:cm-text-center cm-text-2xl cm-text-primary cm-font-bold cm-mb-5"
        >Rewards</div>

        <simple-card color="white" elevation="1" class="cm-pa-4 cm-rounded-0 cm-bg-white cm-mb-6">
          <v-row>
            <v-col cols="12">
              <div
                class="cm-text-center md:cm-text-left cm-text-xl cm-text-primary cm-font-bold cm-mb-0"
              >Select Store</div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <single-store-input
                v-model="store"
                :rules="rules.store"
                :hide-details="formValid === null"
                class="mb-7"
                data-cy="cypress-form-step-one-stores-field"
              />
            </v-col>
            <v-col cols="12" md="6">
              <simple-button
                size="large"
                color="#14569E"
                class="cm-capitalize cm-text-base cm-font-light cm-text-sm ma-0"
                text="Add Reward Tier"
                @click="handleCreateLoyaltyReward()"
                :disabled="isDisabled"
              />
            </v-col>
          </v-row>

          <v-row v-if="showMonthSelector" class="align-stretch" align="stretch">
            <v-col cols="12" md="6">
              <v-card elevation="2" class="pa-3 d-flex flex-column">
                <v-row class="mx-2">
                  <v-col cols="auto" class="pt-4">
                    <v-btn icon @click="changeMonth(-1)">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <!-- Adjusted to fill space but go full width on smaller screens -->
                    <v-select
                      v-model="selectedMonthName"
                      :items="months"
                      label="Month"
                      outlined
                      dense
                      solo
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="selectedYear"
                      :items="years"
                      label="Year"
                      outlined
                      dense
                      solo
                    ></v-select>
                  </v-col>
                  <v-col cols="auto" class="pt-4">
                    <v-btn icon @click="changeMonth(1)">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card elevation="2" class="d-flex fill-height justify-center">
                <div class="d-flex align-center justify-center" style="width: 100%">
                  <simple-checkbox
                    v-model="form.enableLoyaltyRewards"
                    color="#14569E"
                    label="Enable Rewards"
                    @update:modelValue="handleEnableLoyaltyRewards"
                    class="highlight-checkbox"
                  />
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="showRewardsList && !showEmptyRewardsList">
            <v-col :cols="12" :md="6" :lg="4" v-for="reward in filteredRewards" :key="reward.id">
              <loyalty-rewards-card
                :reward="reward"
                @remove-loyalty-reward="handleRemoveLoyaltyReward(reward.id)"
                @edit-loyalty-reward="handleEditLoyaltyReward(reward.id)"
              ></loyalty-rewards-card>
            </v-col>
          </v-row>

          <v-row v-if="showEmptyRewardsList && store" class="cm-text-center">
            <v-col cols="12">
              <div
                v-if="!loading"
                class="cm-text-center cm-font-bold cm-mb-2 cm-text-xl cm-text-primary"
              >No Rewards</div>
              <v-progress-circular class="cm-text-center cm-text-primary" v-else indeterminate></v-progress-circular>
            </v-col>
          </v-row>
          <v-row v-if="showBulkOptions && !showEmptyRewardsList">
            <v-col cols="12">
              <div
                class="cm-text-center cm-font-bold cm-mb-2 cm-text-xl cm-text-primary"
              >Exclude from Reward Progress</div>
            </v-col>
            <v-col cols="12" md="6">
              <simple-checkbox
                v-model="form.excludeAllTobaccoProducts"
                border
                color="#14569E"
                label="Excludes All Tobacco Products"
                @update:modelValue="handleExcludeAllTobaccoProducts"
              />
            </v-col>
            <v-col cols="12" md="6">
              <simple-checkbox
                v-model="form.excludeAllAlcoholProducts"
                class="mb-5"
                border
                color="#14569E"
                label="Excludes All Alcohol Products"
                @update:modelValue="handleExcludeAllAlcoholProducts"
              />
            </v-col>
          </v-row>
          <v-row v-if="showCopyToOtherStores && !showEmptyRewardsList">
            <v-col cols="12">
              <simple-button
                size="large"
                color="#14569E"
                class="cm-capitalize cm-text-base cm-font-semibold cm-text-lg"
                text="Copy to other stores"
                @click="handleCopyLoyaltyReward()"
                :disabled="isDisabled"
              />
            </v-col>
          </v-row>
        </simple-card>
      </simple-card>
    </v-form>
  </div>
</template>
<script>
import { ref, onMounted, computed, watch, onActivated } from "vue";
import { storeToRefs } from "pinia";
import { RULE_REQUIRED } from "@/constants/rules.js";
import { useToast } from "vue-toastification";
import { useLoyaltyRewardsNavigationStore } from "@/stores/LoyaltyRewards/loyaltyRewardsNavigation.ts";
import { useLoyaltyRewardsRewardStore } from "@/stores/LoyaltyRewards/reward.ts";
import { useLoyaltyRewardsStore } from "@/stores/LoyaltyRewards/store.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import SimpleCheckbox from "@/components/ui/SimpleCheckbox.vue";
import LoyaltyRewardsCard from "../cards/LoyaltyRewardsCard.vue";
import SingleStoreInput from "@/components/loyaltyRewards/forms/SingleStoreInput.vue";
export default {
  name: "DashboardView",
  components: {
    SimpleCard,
    SimpleButton,
    SimpleCheckbox,
    LoyaltyRewardsCard,
    SingleStoreInput,
  },
  setup() {
    const navigationLoyaltyRewardsStore = useLoyaltyRewardsNavigationStore();
    const loyaltyRewardsRewardStore = useLoyaltyRewardsRewardStore();
    const loyaltyRewardsStore = useLoyaltyRewardsStore();

    const {
      excludeAllTobaccoProducts,
      excludeAllAlcoholProducts,
      enableLoyaltyRewards,
    } = storeToRefs(useLoyaltyRewardsRewardStore());
    const toast = useToast();

    const rules = ref({
      store: [RULE_REQUIRED],
    });

    const rewards = ref([]);
    const store = ref(null);
    const formValid = ref(true);
    const formRef = ref(null);
    const form = ref({
      excludeAllTobaccoProducts: excludeAllTobaccoProducts.value,
      excludeAllAlcoholProducts: excludeAllAlcoholProducts.value,
      enableLoyaltyRewards: enableLoyaltyRewards.value,
    });
    const showBulkOptions = ref(false);
    const showEnableLoyaltyRewards = ref(false);
    const showRewardsList = ref(false);
    const showCopyToOtherStores = ref(false);
    const loading = ref(false);

    watch(
      () => store.value,
      async (value) => {
        loading.value = true;
        loyaltyRewardsStore.store = value;
        if (value) {
          await loyaltyRewardsRewardStore.getEnabledLoyalRewards(
            store.value.id
          );
          await loyaltyRewardsRewardStore.getExcludedBulkItems(store.value.id);
          await loyaltyRewardsRewardStore.getRewards(store.value.id);

          form.value = {
            excludeAllTobaccoProducts: excludeAllTobaccoProducts.value,
            excludeAllAlcoholProducts: excludeAllAlcoholProducts.value,
            enableLoyaltyRewards: enableLoyaltyRewards.value,
          };

          rewards.value = loyaltyRewardsRewardStore.rewards;
          showBulkOptions.value = true;
          showEnableLoyaltyRewards.value = true;
          showRewardsList.value = true;
          showCopyToOtherStores.value = enableLoyaltyRewards.value;
          loading.value = false;

          loading.value = false;
        }
      }
    );

    const handleExcludeAllTobaccoProducts = async (value) => {
      try {
        await loyaltyRewardsRewardStore.excludeBulkItems(
          loyaltyRewardsStore?.store?.id,
          {
            excludeTobacco: value,
          }
        );
        toast.success(`${value ? "Exclude" : "Include"} All Tobacco Products`, {
          timeout: 2000,
        });
      } catch (error) {
        toast.error(typeof error === "string" ? error : error.message, {
          timeout: 2000,
        });
      }
    };

    const handleExcludeAllAlcoholProducts = async (value) => {
      try {
        await loyaltyRewardsRewardStore.excludeBulkItems(
          loyaltyRewardsStore?.store?.id,
          {
            excludeAlcohol: value,
          }
        );

        toast.success(`${value ? "Exclude" : "Include"} All Alcohol Products`, {
          timeout: 2000,
        });
      } catch (error) {
        toast.error(typeof error === "string" ? error : error.message, {
          timeout: 2000,
        });
      }
    };

    const handleEnableLoyaltyRewards = async (value) => {
      try {
        await loyaltyRewardsRewardStore.enableLoyaltyRewardsAction(
          loyaltyRewardsStore?.store?.id,
          value
        );
        if (loyaltyRewardsRewardStore.enableLoyaltyRewards) {
          showCopyToOtherStores.value = true;
        }

        toast.success(`Reward ${value ? "Enabled" : "Disabled"}!`, {
          timeout: 2000,
        });
      } catch (error) {
        toast.error(typeof error === "string" ? error : error.message, {
          timeout: 2000,
        });
      }
    };

    onMounted(async () => {
      try {
        await loyaltyRewardsRewardStore.getTypes();
        loading.value = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        loading.value = false;
      }
    });

    onActivated(async () => {
      if (store.value) {
        await loyaltyRewardsRewardStore.getRewards(store.value.id);
        rewards.value = loyaltyRewardsRewardStore.rewards;
      }
    });

    const types = computed(() => {
      return loyaltyRewardsRewardStore?.types;
    });

    const rewardTypeFormatted = (typeId) => {
      const type = types?.value?.find((type) => type?.id === typeId);
      return type?.name;
    };

    const dateFormatted = (startsAt, expiresAt) => {
      if (!startsAt || !expiresAt) {
        return "";
      }
      const startsAtDate = new Date(startsAt);
      const expiresAtDate = new Date(expiresAt);
      return `${startsAtDate
        .toLocaleDateString("en-GB")
        .split("/")
        .join("-")} to ${expiresAtDate
        .toLocaleDateString("en-GB")
        .split("/")
        .join("-")}`;
    };

    const isDisabled = computed(() => {
      return Boolean(
        typeof formValid.value === "boolean" && formValid.value === false
      );
    });

    const showEmptyRewardsList = computed(() => {
      return Boolean(
        (typeof formValid?.value === "boolean" &&
          formValid?.value === true &&
          rewards?.value?.length === 0) ||
          filteredRewards.value.length === 0
      );
    });

    watch(
      () => isDisabled.value,
      (newValue) => {
        if (newValue) {
          showBulkOptions.value = false;
          showEnableLoyaltyRewards.value = false;
          showRewardsList.value = false;
        } else {
          showBulkOptions.value = true;
          showEnableLoyaltyRewards.value = true;
          showRewardsList.value = true;
        }
      }
    );

    const handleRemoveLoyaltyReward = (id) => {
      try {
        rewards.value.splice(
          rewards.value.findIndex((reward) => reward.id === id),
          1
        );

        loyaltyRewardsRewardStore.deleteReward(
          loyaltyRewardsStore?.store?.id,
          id
        );

        toast.success(`Reward Removed!`, {
          timeout: 2000,
        });
      } catch (error) {
        toast.error(error, {
          timeout: 2000,
        });
      }
    };

    const handleEditLoyaltyReward = (id) => {
      loyaltyRewardsRewardStore.reward.id = id;
      navigationLoyaltyRewardsStore.viewLoyaltyRewardsEditFormStepOne();
    };

    const isRewardEditable = async (reward) => {
      const today = new Date();
      const start = new Date(reward.startsAt);
      return (
        start.getFullYear() > today.getFullYear() ||
        (start.getFullYear() === today.getFullYear() &&
          start.getMonth() >= today.getMonth())
      );
    };

    const handleCreateLoyaltyReward = async () => {
      const { valid } = await formRef.value.validate();
      if (!valid) {
        return;
      }
      navigationLoyaltyRewardsStore.viewLoyaltyRewardsCreateFormStepOne();
    };

    const handleCopyLoyaltyReward = () => {
      navigationLoyaltyRewardsStore.viewLoyaltyRewardsCopyForm();
    };

    const selectedMonth = ref(new Date().getMonth());
    const selectedYear = ref(new Date().getFullYear());

    const months = ref([
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]);
    const currentYear = new Date().getFullYear();
    const years = ref(
      Array.from({ length: 11 }, (_, i) => currentYear - 5 + i)
    ); // 5 years back, current year, and 5 years forward

    const filteredRewards = computed(() => {
      const selectedStartDate = new Date(
        selectedYear.value,
        selectedMonth.value,
        1
      );
      const selectedEndDate = new Date(
        selectedYear.value,
        selectedMonth.value + 1,
        0
      );

      return rewards.value.filter((reward) => {
        const rewardStart = new Date(reward.startsAt);
        const rewardEnd = new Date(reward.expiresAt || reward.startsAt); // Use startsAt if expiresAt is not defined

        return rewardStart <= selectedEndDate && rewardEnd >= selectedStartDate;
      });
    });

    const changeMonth = (delta) => {
      let newMonth = selectedMonth.value + delta;
      let newYear = selectedYear.value;
      if (newMonth > 11) {
        newMonth = 0;
        newYear++;
      } else if (newMonth < 0) {
        newMonth = 11;
        newYear--;
      }
      selectedMonth.value = newMonth;
      selectedYear.value = newYear;
    };
    const selectedMonthName = computed({
      get: () => months.value[selectedMonth.value],
      set: (name) => {
        selectedMonth.value = months.value.indexOf(name);
      },
    });
    const showMonthSelector = computed(() => {
      return store.value && store.value.id != null;
    });
    return {
      selectedMonth,
      selectedMonthName,
      selectedYear,
      showMonthSelector,
      months,
      years,
      filteredRewards,
      changeMonth,
      ...navigationLoyaltyRewardsStore,
      handleCreateLoyaltyReward,
      handleCopyLoyaltyReward,
      handleRemoveLoyaltyReward,
      handleEditLoyaltyReward,
      rewards,
      rules,
      store,
      isDisabled,
      formValid,
      formRef,
      form,
      showBulkOptions,
      showEnableLoyaltyRewards,
      showCopyToOtherStores,
      showRewardsList,
      rewardTypeFormatted,
      dateFormatted,
      handleExcludeAllTobaccoProducts,
      handleExcludeAllAlcoholProducts,
      handleEnableLoyaltyRewards,
      showEmptyRewardsList,
      loyaltyRewardsRewardStore,
      loading,
    };
  },
};
</script>
