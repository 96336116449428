import { defineStore } from "pinia";

export const useSearchStore = defineStore("search", {
  state: () => {
    return {
      searchLoading: false,
      query: {
        title: null,
        name: null,
        startingDate: null,
        type: null,
        storeId: null,
      },
      orderBy: {
        label: "Date",
        value: "id",
      },
    };
  },
  getters: {},
  actions: {},
});
