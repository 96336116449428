<template>
  <v-autocomplete
    v-bind="$attrs"
    tabindex="0"
    :items="items"
    :label="label"
    :disabled="disabled"
    variant="outlined"
    :color="activeColor"
    :style="{ color: color }"
    autocomplete="off"
    append-inner-icon="mdi-magnify"
    hide-no-data
    item-title="name"
  >
    <template v-slot:prepend-inner>
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="24"
      ></v-progress-circular>
    </template>
    <template v-slot:chip="{ props, item, index }">
      <v-chip
        v-if="
          allStoresSelected && storeLength >= 5 && index === 0 && !isSuperAdmin
        "
        v-bind="props"
        text="All Stores Selected"
        :closable="false"
      ></v-chip>
      <v-chip
        v-else-if="!allStoresSelected"
        v-bind="props"
        :prepend-avatar="item.raw.image?.mdpi_1x"
        :text="item.raw.name"
      ></v-chip>
      <v-chip
        v-else-if="
          allStoresSelected && storeLength >= 5 && index === 0 && isSuperAdmin
        "
        v-bind="props"
        text="Stores Selected"
        :closable="false"
      ></v-chip>
    </template>
    <template v-slot:item="{ props, item }">
      <v-list-item
        v-bind="props"
        :prepend-avatar="item?.raw?.image?.mdpi_1x"
        :title="item?.raw?.name"
      >
        <template v-slot:prepend>
          <v-img
            v-if="item?.raw?.image?.mdpi_1x"
            class="ma-2"
            :width="40"
            aspect-ratio="1"
            cover
            :src="item?.raw?.image?.mdpi_1x"
          ></v-img>
        </template>
        <template v-slot:title>
          <span>
            <strong>{{ item?.raw?.name }}</strong></span
          >
        </template>
      </v-list-item>
      <v-divider color="info" :thickness="2"></v-divider>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  name: "AutocompleteInput",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    activeColor: {
      type: String,
      default: "#14569E",
    },
    color: {
      type: String,
      default: "#000000",
    },
    loading: {
      type: Boolean,
    },
    storeLength: {
      type: Number,
      default: 0,
    },
    allStoresSelected: {
      type: Boolean,
      default: false,
    },
    isSuperAdmin: {
      type: Number,
      default: 0,
    },
  },
  setup() {},
};
</script>
<style>
.v-autocomplete--active-menu .v-field .v-field__append-inner > .v-icon {
  transform: none !important;
}
</style>
