<template>
  <simple-card color="white" elevation="3" class="cm-pa-4 cm-rounded-0 cm-bg-white cm-mb-6 cm-mt-6">
    <simple-card-title class="mb-5" color="black" :title="reward.name" />

    <p>
      <span class="cm-text-[#14569E] cm-font-medium">Type:</span>
      {{ reward.type.name }}
    </p>
    <p class="truncate-text">
      <span class="cm-text-[#14569E] cm-font-medium">Description:</span>
      <span>{{ reward.description }}</span>
    </p>
    <p v-if="reward.type.id === 3 || reward.type.id === 4">
      <span class="cm-text-[#14569E] cm-font-medium">Discount:</span>
      {{ reward.type.id !== 4 ? '£' : '' }}{{ reward.config.amount || reward.config.discount }}{{ reward.type.id === 4 ? '%' : '' }}
    </p>
    <p v-if="reward.type.id === 2">
      <span class="cm-text-[#14569E] cm-font-medium">Free Product:</span>
      {{ reward.config.itemIds.map(item => item.name).join(', ') }}
    </p>
    <p>
      <span class="cm-text-[#14569E] cm-font-medium">Amount Required to Spend:</span>
      £{{ reward.spendRequired }}
    </p>
    <p>
      <span class="cm-text-[#14569E] cm-font-medium">Valid Dates:</span>
      {{ formatDate(reward.startsAt) }} to {{ formatDate(reward.expiresAt) }}
    </p>
    <p v-if="reward.excludeAlcohol || reward.excludeTobacco">
      <span class="cm-text-[#14569E] cm-font-medium">Exclusions:</span>
      {{ getExclusions }}
    </p>
    <v-row class="pa-0 mt-5 mb-n5" :style="bottomButtonsStyles">
      <v-col cols="6" class="pa-0">
        <v-btn
          size="large"
          class="w-100 cm-bg-red-600 text-white cm-rounded-0"
          :style="{ opacity: isExpired ? 0.5 : 1 }"
          style="border-radius: 0; border-bottom-left-radius: 12px"
          variant="flat"
          @click="dialogRemove = !isExpired"
        >Remove</v-btn>

        <v-dialog v-model="dialogRemove" width="500">
          <simple-card
            color="white"
            elevation="3"
            class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
            closabled
            @close="dialogRemove = false"
          >
            <div class="cm-text-center cm-font-bold cm-mb-5 cm-text-2xl cm-text-[#E92318]">
              Warning
              <v-icon class="mb-1" icon="mdi-alert-outline" size="small"></v-icon>
            </div>
            <div class="cm-text-center cm-font-regular cm-mb-5 cm-text-lg cm-text-base">
              This action will
              <strong>delete</strong> the existing the reward for the
              selected store. Are you sure you want to continue?
            </div>

            <v-card-actions>
              <simple-button
                class="cm-capitalize cm-text-base cm-font-semibold"
                color="#E92318"
                size="large"
                text="Remove Reward"
                data-cy="remove-loyalty-reward"
                @click="handleRemoveLoyaltyReward()"
                :disabled="isExpired"
              ></simple-button>
            </v-card-actions>
          </simple-card>
        </v-dialog>
      </v-col>
      <v-col cols="6" class="pa-0">
        <v-dialog v-model="dialogEdit" width="500">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              size="large"
              color="#14569E"
              class="w-100 cm-bg-red-600 text-white cm-rounded-0"
              style="border-radius: 0; border-bottom-right-radius: 12px"
              variant="flat"
            >Edit</v-btn>
          </template>
          <simple-card
            color="white"
            elevation="3"
            class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
            closabled
            @close="dialogEdit = false"
          >
            <div class="cm-text-center cm-font-bold cm-mb-5 cm-text-2xl cm-text-[#E92318]">
              Warning
              <v-icon class="mb-1" icon="mdi-alert-outline" size="small"></v-icon>
            </div>
            <div class="cm-text-center cm-font-regular cm-mb-5 cm-text-lg cm-text-base">
              This action will
              <strong>edit & replace</strong> the existing reward for the
              selected store. Are you sure you want to continue?
            </div>

            <v-card-actions>
              <simple-button
                class="cm-capitalize cm-text-base cm-font-semibold"
                color="#E92318"
                size="large"
                text="Edit Reward"
                data-cy="edit-loyalty-reward"
                @click="handleEditLoyaltyReward(id)"
              ></simple-button>
            </v-card-actions>
          </simple-card>
        </v-dialog>
      </v-col>
    </v-row>
  </simple-card>
</template>
<style scoped>
.truncate-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
</style>
<script>
import { ref , computed} from "vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleCardTitle from "@/components/ui/SimpleCardTitle.vue";
export default {
  name: "LoyaltyRewardsCard",
  emits: ["remove-loyalty-reward", "edit-loyalty-reward"],
  components: {
    SimpleButton,
    SimpleCard,
    SimpleCardTitle,
  },
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dialogRemove = ref(false);
    const dialogEdit = ref(false);
    const bottomButtonsStyles = {
      width: "calc(100% + 35px)",
      position: "relative",
      left: "-5px",
      bottom: "-2px",
    };

    const handleRemoveLoyaltyReward = () => {
      dialogRemove.value = false;
      emit("remove-loyalty-reward");
    };
    const handleEditLoyaltyReward = (id) => {
      dialogEdit.value = false;
      emit("edit-loyalty-reward", id);
    };

    const currentDate = new Date();
    const isExpired = computed(() => {
      const endDate = new Date(props.reward.expiresAt);
      return currentDate > endDate;
    });

  const formatDate = (date) =>{
    return new Date(date).toLocaleDateString();
  }
    const getExclusions = computed(() => {
      let exclusions = [];
      if (props.reward.excludeAlcohol) exclusions.push("Alcohol");
      if (props.reward.excludeTobacco) exclusions.push("Tobacco");
      return exclusions.join(", ");
    });
    return {
      formatDate,
      isExpired,
      bottomButtonsStyles,
      getExclusions,
      handleRemoveLoyaltyReward,
      handleEditLoyaltyReward,
      dialogRemove,
      dialogEdit,
    };
  },
};
</script>
