<template>
  <simple-card
    color="white"
    elevation="3"
    class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
    closabled
    @close="handleCancel"
  >
    <template #header>
      <div
        class="cm-text-center cm-font-bold cm-mb-2 cm-text-2xl cm-text-primary"
      >
        Reward {{ status }}
      </div>
    </template>

    <simple-card class="cm-p-4 cm-mb-6">
      <div class="cm-text-center cm-font-bold cm-text-2xl cm-text-[#188D6F]">
        You are all good to go!
      </div>
      <div class="cm-mt-4 d-flex align-center justify-center">
        <graphic-success-reward />
      </div>
      <div
        v-if="navigationLoyaltyRewardsStore.rewardCopiedSuccessfully"
        class="cm-text-center cm-font-bold cm-text-2xl cm-text-base"
      >
        <div v-for="store in storeList" :key="store.id">
          {{ store.name }}
        </div>
      </div>
      <div v-else class="cm-text-center cm-font-bold cm-text-2xl cm-text-base">
        {{ storeName }}
      </div>
    </simple-card>

    <simple-button
      class="cm-capitalize cm-text-base cm-font-semibold"
      color="#14569E"
      variant="primary"
      size="large"
      text="Back to reward homepage"
      @click="handleCancel"
      data-cy="cypress-form-step-one-back-button"
    ></simple-button>
  </simple-card>
</template>
<script>
import { computed } from "vue";
import { useLoyaltyRewardsNavigationStore } from "@/stores/LoyaltyRewards/loyaltyRewardsNavigation.ts";
import { useLoyaltyRewardsStore } from "@/stores/LoyaltyRewards/store.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import GraphicSuccessReward from '@/components/loyaltyRewards/GraphicSuccessReward.vue';
export default {
  name: "LoyaltyRewardsSuccessView",
  components: {
    SimpleCard,
    SimpleButton,
    GraphicSuccessReward,
  },
  setup() {
    const navigationLoyaltyRewardsStore = useLoyaltyRewardsNavigationStore();
    const loyaltyRewardsStore = useLoyaltyRewardsStore();

    const storeName = computed(() => {
      return loyaltyRewardsStore?.store?.name;
    });

    const storeList = computed(() => {
      return loyaltyRewardsStore?.destinationStores;
    });

    const status = computed(() => {
      if (navigationLoyaltyRewardsStore.rewardEditedSuccessfully) {
        return "Updated";
      } else if (navigationLoyaltyRewardsStore.rewardCopiedSuccessfully) {
        return "Copied & Replaced";
      } else {
        return "Created";
      }
    });

    const handleCancel = () => {
      navigationLoyaltyRewardsStore.viewDashboard();
      navigationLoyaltyRewardsStore.rewardEditedSuccessfully = false;
      navigationLoyaltyRewardsStore.rewardCopiedSuccessfully = false;
    };

    return {
      handleCancel,
      storeName,
      status,
      navigationLoyaltyRewardsStore,
      storeList,
    };
  },
};
</script>
