<template>
  <div class="cm-flex cm-flex-col" style="height: calc(100vh - 110px)">
    <simple-card
      color="white"
      elevation="3"
      class="cm-p-2 cm-rounded-lg cm-white cm-mb-6 cm-overflow-visible"
      closabled
      @close="handleCancel"
    >
      <template #header>
        <div class="cm-text-center cm-font-bold cm-mb-2">
          Search your current coupons with any of the below fields
        </div>
      </template>
      <form>
        <simple-text-field
          density="compact"
          label="Coupon Name"
          placeholder="Coupon Name"
          v-model="form.name"
        />
        <simple-text-field
          density="compact"
          label="Code"
          placeholder="Coupon Code"
          v-model="form.code"
        />
        <coupon-types-select-input v-model="form.type" />
        <store-autocomplete-input v-model="form.storeId" />
      </form>
    </simple-card>

    <div class="cm-grow cm-flex cm-items-end">
      <simple-button
        text="Create New Coupon"
        class="capitalize text-base font-semibold"
        color="#14569E"
        variant="secondary"
        size="large"
        prepend-icon="mdi-plus"
        @click="handleClick"
      />
    </div>

    <bottom-navigation elevation="0" class="cm-fixed md:cm-static md:cm-p-0">
      <template #leftButton>
        <simple-button
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#14569E"
          size="large"
          text="Search"
          prepend-icon="mdi-magnify"
          @click="handleSearch"
        ></simple-button>
      </template>
      <template #rightButton>
        <simple-button
          class="cm-capitalize cm-text-base cm-font-semibold"
          color="#14569E"
          size="large"
          variant="secondary"
          text="Back"
          @click="viewDashboard"
        ></simple-button>
      </template>
    </bottom-navigation>
  </div>
</template>
<script>
import { reactive, toRaw } from "vue";
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
import { useSearchStore } from "@/stores/CouponManager/search.ts";
import SimpleCard from "@/components/ui/SimpleCard.vue";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import SimpleTextField from "@/components/ui/SimpleTextField.vue";
import StoreAutocompleteInput from "@/components/couponManager/forms/StoreAutocompleteInput.vue";
import BottomNavigation from "@/components/ui/BottomNavigation.vue";
import CouponTypesSelectInput from "@/components/couponManager/forms/CouponTypesSelectInput.vue";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
export default {
  name: "SearchView",
  components: {
    SimpleCard,
    SimpleTextField,
    SimpleButton,
    BottomNavigation,
    StoreAutocompleteInput,
    CouponTypesSelectInput,
  },
  setup() {
    const navigationStore = useNavigationStore();
    const searchStore = useSearchStore();
    const form = reactive({ ...searchStore.query });
    const couponStore = useCouponStore();

    const handleSearch = () => {
      searchStore.query = { ...toRaw(form) };
      navigationStore.viewSearchResults();
    };

    const handleClick = () => {
      couponStore.$reset();
      return navigationStore.viewCouponFormStepOne();
    };

    const handleCancel = () => {
      return navigationStore.viewDashboard();
    };

    return {
      form,
      viewDashboard: navigationStore.viewDashboard,
      viewSearchResults: navigationStore.viewSearchResults,
      handleSearch,
      handleCancel,
      handleClick,
    };
  },
};
</script>
