import { useAuthStore } from "@/stores/auth.ts";
import { useFetch } from "./useFetch";
import { useToast } from "vue-toastification";
import { useBusinessStore } from "@/stores/business.ts";
import { storeToRefs } from "pinia";

import { getViteApiBaseUrl, getViteMocking } from "../../config.js";

export const useApi = (endpoint, options = {}) => {
  const authStore = useAuthStore();
  const businessStore = useBusinessStore();
  const toast = useToast();
  const { baseApiUrl, isEmbedded } = storeToRefs(businessStore);

  let fullEndpoint;
  if (isEmbedded.value) {
    fullEndpoint = baseApiUrl.value + endpoint;

    if (options.notadmin) {
      fullEndpoint = fullEndpoint.replace('admin-', '')
    }

    if (options.shorturl) {
      fullEndpoint = fullEndpoint.replace('/snappy-admin/v1', '')
    }
  } else {
    let apiBaseUrl = getViteApiBaseUrl();

    if (options.notadmin) {
      apiBaseUrl = apiBaseUrl.replace('admin-', '')
    }

    if (options.shorturl) {
      apiBaseUrl = apiBaseUrl.replace('/snappy-admin/v1', '')
    }

    fullEndpoint = `${apiBaseUrl}/${endpoint}`;

    if (getViteMocking() === "true") {
      fullEndpoint = `api/${endpoint}`;
    }
  }

  let contentType = {
    "Content-Type": "application/json",
  };

  if (options?.headers && options.headers["Content-Type"] === undefined) {
    contentType = null;
  }

  return useFetch(fullEndpoint, {
    method: "GET",
    ...options,
    headers: {
      ...contentType,
      Authorization: authStore.authorizationToken,
    },
  }).catch((error) => {
    if (error.errors && Object.keys(error.errors).length) {
      Object.values(error.errors).forEach((item) => {
        toast.error(item[0]);
      });
    }
    throw error;
  });
};
