<template>
  <white-label-manager />
</template>

<script>
import WhiteLabelManager from "@/components/whiteLabelManager/Index.vue";
export default {
  components: {
    WhiteLabelManager,
  },
};
</script>
