<template>
  <div data-cy="cypress-dashboard-view">
    <v-form
      @submit.prevent="handleFormSubmit"
      v-model="formValid"
      data-cy="cypress-loyalty-rewards-dashboard-form"
      validate-on="input"
      ref="formRef"
      class="cm-flex cm-flex-col"
    >
      <simple-card
        color="white"
        elevation="0"
        class="cm-pa-4 cm-rounded-0 cm-bg-white cm-mb-6"
      >
        <div
          data-cy="cypress-coupon-details-name"
          class="cm-text-left md:cm-text-center cm-text-2xl cm-text-primary cm-font-bold cm-mb-5"
        >
          White Label Settings
        </div>
      </simple-card>
      <v-expansion-panels class="mb-4">
        <v-expansion-panel
          v-for="(group, index) in form"
          :key="index"
          :title="group.groupName"
          data-cy="cypress-white-label-manager-expansion-panel"
        >
          <v-expansion-panel-text
            eager
            data-cy="cypress-white-label-manager-expansion-panel-content"
          >
            <div v-for="(setting, index2) in group.settings" :key="index2">
              <white-label-manager-form-fields
                :setting="setting"
                :form-rules="formRules"
                @onSettingsUpdate="handleSettingUpdate"
              />
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <simple-button
        class="cm-capitalize cm-text-base cm-font-semibold"
        color="#188D6F"
        size="large"
        text="Update"
        data-cy="cypress-white-label-manager-form-submit-button"
        type="submit"
        :disabled="formValid === false"
      ></simple-button>
    </v-form>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useToast } from "vue-toastification";
import api from "@/utils/api.js";
import SimpleButton from "@/components/ui/SimpleButton.vue";
import WhiteLabelManagerFormFields from "@/components/whiteLabelManager/form/FormFields.vue";
import { useAuthStore } from "@/stores/auth.ts";
export default {
  components: {
    SimpleButton,
    WhiteLabelManagerFormFields,
  },
  props: {
    settingType: {
      type: String,
      default() {
        return "text";
        // style, feature, text
      },
    },
  },
  setup(props) {
    const toast = useToast();
    const { businessId } = storeToRefs(useAuthStore());
    const formRef = ref(null);
    const form = ref([]);
    const menu = ref(false);
    const formValid = ref(false);
    const formRules = ref({
      required: (value) => {
        if (value) return true;

        return "You must enter a value";
      },
      color: (value) => {
        const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

        if (value && hexColorRegex.test(value)) return true;

        return "You must enter a valid hex color";
      },
      maxFileSize: (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size <= 2000000 ||
          "Image size must be less than 2 MB"
        );
      },
    });

    onMounted(async () => {
      try {
        const { data } = await api.whiteLabelManager.settings.get(
          businessId.value,
          props.settingType
        );

        data.forEach(group => {
          group.settings.forEach(setting => {
            const { value, metaType, name } = setting;
            // Get the total count for each platform type
            const counts = {};
            ['ios', 'android', 'web'].forEach(platform => {
              const platformValue = value[platform];
              if (platformValue) {
                counts[platformValue] = (counts[platformValue] || 0) + 1;
              }
            });

            // Get the most popular value and put it as the default
            if (Object.keys(counts).length !== 0 && !value.default) {
              value.default = "";

              const mostPopularValue = Object.keys(counts).reduce((a, b) => counts[a] > counts[b] ? a : b, null);
              if (mostPopularValue) {
                value.default = mostPopularValue;
              }
            }

            // Change image is include S3 in url to pass validation 
            if (setting.metaType == "image") {
              setting.value.default = setting.value.default.replace("https://admin-api.s3.eu-west-2.amazonaws.com/", "s3:");

              ['ios', 'android', 'web'].forEach(platform => {
                const platformValue = value[platform];
                if (platformValue) {
                  setting.value[platform] = setting.value[platform].replace("https://admin-api.s3.eu-west-2.amazonaws.com/", "s3:");
                }
              });
            }


            // Remove any fields that are the same as the default
            ['ios', 'android', 'web'].forEach(platform => {
              if (value[platform]) {
                if (value[platform] === value.default) {
                  value[platform] = "";
                  setting[platform + '_show'] = false;
                } else if (value[platform]) {
                  setting[platform + '_show'] = true;
                } else {
                  setting[platform + '_show'] = false;
                }
              }
            });

          });
        });

        form.value = data;
      } catch {
        toast.error("Unable to get white label settings");
      }
    });

    const handleFormSubmit = async () => {
      try {
        await formRef.value.validate();

        if (!formRef.value.isValid) {
          return;
        }

        const settings = form.value
          .reduce((acc, curr) => acc.concat(curr.settings), [])
          .map((setting) => {
            if (!setting.value.default) {
              setting.value.default = findMostPopularValue(setting.value);
            }

            const { machineName, value } = setting;

            return {
              machineName,
              value,
            };
          });

        formValid.value = false;

        await api.whiteLabelManager.settings.update(businessId.value, {
          settings,
        });

        formValid.value = true;
        toast.success("White label settings have been updated");
      } catch {
        formValid.value = true;
        toast.error("Unable to update white label settings");
      }
    };

    const handleSettingUpdate = ({ machineName, value }) => {
      form.value = form.value.map((form) => {
        return {
          ...form,
          settings: form.settings.map((setting) => {
            if (setting.machineName === machineName) {
              return {
                ...setting,
                value: {
                  ...value,
                },
              };
            }
            return setting;
          }),
        };
      });
    };

    const findMostPopularValue = (settings) => {
      const frequency = Object.values(settings).reduce((acc, value) => {
        const firstValue = value.split('|')[0];
        acc[firstValue] = (acc[firstValue] || 0) + 1;
        return acc;
      }, {});

      return Object.keys(frequency).reduce((a, b) => frequency[a] > frequency[b] ? a : b);
    };

    return {
      form,
      formRef,
      formValid,
      formRules,
      menu,
      handleFormSubmit,
      handleSettingUpdate,
    };
  },
};
</script>
