<template>
  <v-data-table-server
    class="cm-relative search-results-table"
    :headers="headers"
    :items="items"
    :loading="loadingColor"
    :items-per-page="pagination.perPage"
    must-sort
    @update:options="handleOptionsChange"
    hide-default-footer
  >
    <template v-slot:item="{ item }">
      <search-results-table-row :item="item.raw" :key="item.value" />
    </template>
    <template v-slot:bottom v-if="showPagination">
      <div
        class="cm-text-center cm-pt-2 cm:absolute cm-left-0 cm-right-0 cm-bottom-0"
      >
        <div class="cm-flex cm-justify-center cm-gap-x-4">
          <v-btn
            :disabled="previousPaginationDisabled"
            @click="handlePreviousPaginationClick"
            >Previous</v-btn
          >
          <v-btn
            :disabled="nextPaginationDisabled"
            @click="handleNextPaginationClick"
            >Next</v-btn
          >
        </div>
      </div>
    </template>
    <template v-slot:bottom v-else></template>
  </v-data-table-server>
</template>
<script>
import { computed, toRefs, ref, toRaw, watch } from "vue";
import { storeToRefs } from "pinia";
import { useCouponStore } from "@/stores/CouponManager/coupon.ts";
import { useSearchStore } from "@/stores/CouponManager/search.ts";
import SearchResultsTableRow from "@/components/couponManager/tables/SearchResultsTableRow.vue";
import { useToast } from "vue-toastification";
import { useDisplay } from "vuetify";
export default {
  name: "SearchResultsTable",
  components: {
    SearchResultsTableRow,
  },
  props: {
    options: Object,
    showPagination: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  setup(props) {
    const searchStore = useSearchStore();
    const { query } = storeToRefs(searchStore);
    const toast = useToast();
    const { smAndDown } = useDisplay();

    const searchQueies = computed(() => {
      const keysQuery = ["name", "nameOrCode", "code", "type", "storeId"];
      const response = {};
      keysQuery.forEach((key) => {
        const rawQuery = searchStore.query;
        if (rawQuery[key]) {
          response[key] = rawQuery[key];
        }
      });
      return response;
    });

    const options = ref({
      orderDirection: "desc",
      orderBy: "id",
      ...toRefs(props.options),
    });

    watch(
      query,
      (v) => {
        searchCoupons();
      },
      { deep: true }
    );

    const couponStore = useCouponStore();
    const loading = ref(false);
    const initialPagination = {
      page: 0,
      perPage: 5,
      hasMore: true,
      hasPrev: false,
    };
    const pagination = ref({ ...initialPagination });
    const items = ref([]);

    const headers = computed(() => {
      return [
        {
          title: "Status",
          align: "start",
          sortable: false,
          key: "status",
          width: 50,
        },
        { title: "Name", key: "name", width: 150 },
        { title: "Code", key: "code", width: 100 },
        { title: "Used", key: "redeemed", width: 100 },
        { title: "Total Sales", key: "totalSales", width: 100 },
        {
          title: "New Customers",
          key: "total_new_customer_orders",
          width: 130,
        },
        { title: "Total Discount", key: "totalDiscount", width: 120 },
      ];
    });

    const loadingColor = computed(() => {
      return loading.value ? "#13569e" : false;
    });

    const searchCoupons = async () => {
      try {
        searchStore.searchLoading = true;
        loading.value = true;
        const { coupons, meta } = await couponStore.searchCoupons({
          ...searchQueies.value,
          limit: pagination.value.perPage,
          ...options.value,
          skip: pagination.value.page,
        });
        pagination.value.hasMore = meta.hasMore;
        searchStore.searchLoading = false;
        items.value = coupons;
        loading.value = false;
      } catch (e) {
        searchStore.searchLoading = false;
        toast.error(e, {
          timeout: 2000,
        });
      }
    };

    const handleOptionsChange = (event) => {
      loading.value = true;
      const sortBy = toRaw(event.sortBy);
      const orderBy = sortBy[0]?.key;
      const orderDirection = sortBy[0]?.order;

      pagination.value = { ...initialPagination };

      if (orderBy && orderDirection) {
        options.value = {
          ...options.value,
          orderDirection,
          orderBy,
        };
      }

      searchCoupons();
    };

    const previousPaginationDisabled = computed(() => {
      return Boolean(pagination.value.page === 0);
    });

    const nextPaginationDisabled = computed(() => {
      return Boolean(!pagination.value.hasMore);
    });

    const handlePreviousPaginationClick = () => {
      pagination.value.page--;
      searchCoupons();
    };

    const handleNextPaginationClick = () => {
      pagination.value.page++;
      searchCoupons();
    };

    return {
      previousPaginationDisabled,
      nextPaginationDisabled,
      loadingColor,
      headers,
      pagination,
      items,
      loading,
      handleOptionsChange,
      searchCoupons,
      smAndDown,
      handlePreviousPaginationClick,
      handleNextPaginationClick,
    };
  },
};
</script>
<style>
.search-results-table thead > tr th {
  height: auto !important;
  padding: 8px !important;
}
.search-results-table thead > tr th .v-data-table-header__content {
  font-size: 12px;
  font-weight: 600;
}
.search-results-table .v-progress-linear__indeterminate .short {
  height: 3px;
}
</style>
