<template>
  <v-breadcrumbs divider="/" class="cm-justify-center">
    <template v-for="(item, index) in items" :key="index">
      <v-breadcrumbs-item :active="item.active" active-class="cm-font-bold">
        {{ item.label }}
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider v-if="index < items.length - 1">
        <v-icon icon="mdi-chevron-right"></v-icon>
      </v-breadcrumbs-divider>
    </template>
  </v-breadcrumbs>
</template>
<script>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useNavigationStore } from "@/stores/CouponManager/navigation.ts";
export default {
  name: "CouponFormBreadcrumbs",
  setup() {
    const navigationStore = useNavigationStore();
    const { view } = storeToRefs(navigationStore);

    const items = computed(() => {
      return [
        {
          view: "coupon-form-step-one",
          label: "Coupon Details",
          active: view.value === "coupon-form-step-one",
        },
        {
          view: "coupon-form-step-two",
          label: "Discount Information",
          active: view.value === "coupon-form-step-two",
        },
        // TODO: re-enable in phase 2
        // {
        //   view: "coupon-form-step-three",
        //   label: "Exemptions",
        //   active: view.value === "coupon-form-step-three",
        // },
      ];
    });

    return { items };
  },
};
</script>
