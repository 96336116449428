import { defineStore } from "pinia";

export const useBusinessStore = defineStore("business", {
  state: () => {
    return {
      baseApiUrl: null,
      isEmbedded: false,
      userId: 1,
    };
  },
});
