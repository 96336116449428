<template>
  <div class="cm-border-b cm-pb-6 cm-mb-6">
    <menu-group-search v-bind="$attrs" label="Source Menu Group" />
  </div>
</template>

<script>
import MenuGroupSearch from "@/components/copyProductsToMenuGroups/forms/MenuGroupSearch.vue";

export default {
  components: {
    MenuGroupSearch,
  },
};
</script>
