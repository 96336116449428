<template>
  <simple-text-field
    density="compact"
    label="Coupon Code "
    placeholder="Example XXXX-JAN-23"
    icon="mdi-information-outline"
    icon-color="#14569E"
    :clearable="false"
    :uppercase="true"
    v-bind="$attrs"
  />
</template>
<script>
import SimpleTextField from "@/components/ui/SimpleTextField.vue";
export default {
  name: "CouponFormCouponCodeField",
  components: {
    SimpleTextField,
  },
};
</script>
